const emailValidator = {
  minLength: 5,
  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
  required: true,
}
const firstnameValidator = {
  minLength: 3,
  maxLength: 25,
  required: true,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}
const lastnameValidator = {
  minLength: 3,
  maxLength: 25,
  required: true,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}
const fullnameValidator = {
  minLength: 3,
  maxLength: 25,
  required: true,
}

const passwordValidator = {
  minLength: 8,
  maxLength: 30,
  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/,
  required: true,
}

const companyValidator = {
  required: true,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}

const phoneValidator = {
  // minLength: 8,
  pattern: /^(\+|\d)+$/,
  required: true,
}
const countryValidator = {
  required: true,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}
const pagesValidator = {
  required: true,

}
const richTextValidator = {
  required: true,
}
const workplaceNameValidator = {
  required: true,
  maxLength: 20,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}
const websiteValidator = {
  required: 'Website link is required',
  pattern: {
    value: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-z]{2,})+$/,
  },
}
const workplaceDescriptionValidator = {
  required: true,
  maxLength: 300,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}
const categoryValidator = {
  required: true,
}
const roleValidetor = {
  required: true,
}
const workSpaceValidetor = {
  required: true,
}
const aiPromptValidator = {
  required: true,
  maxLength: 300,
  validate: (value) =>
    value.trim().length > 0 || 'Workplace name cannot be empty or spaces only',
}
const aiToneValidator = {
  required: true,
}
const emailResolver = {
  minLength: 'Email is to Short',
  pattern: 'Email is not valid',
  required: 'Enter valid email',
}

const firstnameResolver = {
  minLength: 'Name is to Short',
  maxLength: 'Name is to Long',
  required: 'Enter first name',
}
const lastnameResolver = {
  minLength: 'Name is to Short',
  maxLength: 'Name is to Long',
  required: 'Enter last name',
}
const fullnameResolver = {
  minLength: 'Name is to Short',
  maxLength: 'Name is to Long',
  required: 'Enter name',
}
const passwordResolver = {
  minLength: 'Password length must be atleast 8 characters',
  maxLength: 'Password length must be 30 characters',
  pattern: 'Required 1 (small,capital & special character)',
  required: 'Enter password',
}

const companyResolver = {
  required: 'Enter company name',
}
const phoneResolver = {
  pattern: 'Invalid phone number',
  // minLength:'Invalid phone number',
  required: 'Enter phone number',
}
const countryResolver = {
  required: 'select a country',
}
const pagesResolver = {
  required: 'select atleast 1 page',
}
const richTextResolver = {
  required: 'Write your content',
}
const categoryResolver = {
  required: 'select a category',
}
const workplaceNameResolver = {
  required: 'Enter workspace name',
  maxLength: "can't write more than 20 words",
}
const websiteResolver = {
  required: 'Enter website link',
  pattern: 'Invalid website link. Format: www.example.com',
}
const workplaceDescriptionResolver = {
  required: 'Enter description',
  maxLength: "can't write more than 300 words",
}
const aiPromptResolver = {
  required: 'Enter prompt',
  maxLength: "can't write more than 300 words",
}
const roleResolver = {
  pattern: 'Invalid role',
  required: 'Enter role',
}

export {
  emailValidator,
  firstnameValidator,
  lastnameValidator,
  fullnameValidator,
  passwordValidator,
  companyValidator,
  phoneValidator,
  countryValidator,
  pagesValidator,
  emailResolver,
  firstnameResolver,
  lastnameResolver,
  fullnameResolver,
  passwordResolver,
  companyResolver,
  phoneResolver,
  countryResolver,
  pagesResolver,
  workplaceNameResolver,
  workplaceNameValidator,
  websiteResolver,
  websiteValidator,
  workplaceDescriptionValidator,
  workplaceDescriptionResolver,
  categoryValidator,
  categoryResolver,
  roleValidetor,
  roleResolver,
  richTextResolver,
  richTextValidator,
  aiPromptResolver,
  aiPromptValidator,
}
