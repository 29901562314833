import { Box, Button, Card, Typography } from '@mui/material'
import React, { useContext } from 'react'
import allPlatformsStyles, {
  allPlatformsHeaderText,
  card_Main,
  connectBtn,
  connectedBtn,
  description,
  footerText,
  name,
} from './allPlatformsStyle'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import google from '../../../../Assets/Images/google.png'
import fb from '../../../../Assets/Images/facebook.png'
import linkedin from '../../../../Assets/Images/linkedin.png'
import x from '../../../../Assets/Images/x.png'
import PlatformMenu from './platformMenu'
import UserContext from '../../../../Context/userContext'
import { useTheme } from '@emotion/react'
const AllPlatForms = () => {
  const { activeSpace } = useContext(UserContext)
  const nameArray = activeSpace?.platforms?.map((val) => val.name) ?? []

  const platformData = [
    {
      platformIcon: google,
      icon: <MoreHorizOutlinedIcon />,
      title: 'Google',
      description:
        'Integrating Google boosts productivity and enhances user experience on our platform.',
      useComany: '200+ Integrations',
      buttonStatus: 'Connect',
    },
    {
      platformIcon: fb,
      icon: <MoreHorizOutlinedIcon />,
      title: 'Facebook',
      description:
        'Integrating facebook boosts productivity & enhances user experience on our platform.',
      useComany: '10K+ Integrations',
      buttonStatus: 'Connect',
    },
    // {
    //   platformIcon: linkedin,
    //   icon: <MoreHorizOutlinedIcon />,
    //   title: 'LinkedIn',
    //   description:
    //     'Integrating LinkedIn boosts productivity & enhances user experience on our platform.',
    //   useComany: '2K+ Use',
    //   buttonStatus: 'Connect',
    // },
    // {
    //   platformIcon: x,
    //   icon: <MoreHorizOutlinedIcon />,
    //   title: 'Twitter',
    //   description:
    //     'Integrating Twitter boosts productivity & enhances user experience on our platform.',
    //   useComany: '200+ Use',
    //   buttonStatus: 'Connect',
    // },
  ]
  const theme = useTheme()
  return (
    <Box sx={allPlatformsStyles.parentDiv}>
      <Box sx={allPlatformsStyles.platformHeader}>
        <Typography sx={allPlatformsHeaderText(theme)}>
          All Platforms
        </Typography>
      </Box>
      {platformData.map((value, index) => {
        const isConnected = nameArray.some(
          (name) => name.toLowerCase() === value.title.toLowerCase()
        )
        return (
          <Card key={index} sx={card_Main(theme)}>
            <Box sx={allPlatformsStyles.iconBox}>
              <img
                src={value.platformIcon}
                height={'48px'}
                width={'48px'}
                alt='platform-logo'
              />
              {isConnected ? (
                <PlatformMenu icon={value.icon} index={index} />
              ) : null}
            </Box>
            <Typography sx={name(theme)}>{value.title}</Typography>
            <Typography sx={description(theme)}>{value.description}</Typography>
            <Box sx={allPlatformsStyles.cardFooter}>
              <Typography sx={footerText(theme)}>{value.useComany}</Typography>
              {isConnected ? (
                <Button variant='contained' sx={connectedBtn(theme)}>
                  {'Connected'}
                </Button>
              ) : (
                <Button variant='outlined' sx={connectBtn(theme)}>
                  {'Connect'}
                </Button>
              )}
            </Box>
          </Card>
        )
      })}
    </Box>
  )
}

export default AllPlatForms
