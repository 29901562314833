const activeSectionStyle = {
  button_main_Box: {
    display: 'flex',
    justifyContent: { xs: 'space-between', sm: '' },
    gap: '8px',
    background: '',
  },
  menu_Style: {
    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#F8F9FF',
    paddingY: '6px',
    paddingX: '10px',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
  },
  unarchive_Space_Text: {
    borderRadius: '4px',
    padding: '6px',
    boxSizing: 'border-box',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#DADAFF' },
  },
  text_Style: {
    background: '',
    color: 'black',
    fontWeight: '600',
    fontSize: { xs: '12px', sm: '14px' },
  },
  textGroup_Style: {
    background: '',
    color: 'black',
    fontWeight: '600',
    fontSize: { xs: '12px', sm: '14px' },
  },
  MapContainerStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    background: '',
    boxSizing: 'border-box',
  },

  img_Box: {
    display: 'flex',
    background: '',
    alignItems: 'center',
    gap: '10px',
  },

  pagination_Box: {
    width: '96%',
    background: '',
    display: 'flex',
    justifyContent: 'center',
  },
  pagination_Style: {
    '& .MuiPaginationItem-root': {
      color: 'blue',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#DADAFF',
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: '#DADAFF',
    },
  },
}
export default activeSectionStyle
export const main_Container = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  backgroundColor: theme?.palette?.customWhite.main,
  padding: '20px',
  boxSizing: 'border-box',
  height: '100%',
})
export const second_Box = (theme) => ({
  backgroundColor: theme?.palette?.customWhite.main,
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
})
export const filterBox = (theme) => ({
  display: {
    xs: 'flex',
  },
  justifyContent: 'space-between',
  backgroundColor: theme?.palette?.customWhite.main,
  boxSizing: 'border-box',
  width: {
    xs: '100%',
  },
  gap: '10px',
})

export const custom_Button = (activeButton, theme) => ({
  backgroundColor: activeButton ? theme.palette.primary.dark : '',
  textTransform: 'capitalize',
  display: 'flex',
  boxSizing: 'border-box',
  paddingX: { xs: '4px', sm: '10px' },
  paddingY: { xs: '4px', sm: '4px' },
  gap: { xs: '0px', sm: '10px' },
  alignItems: 'center',
  borderRadius: '8px',
  '&:hover': { backgroundColor: theme.palette.primary.dark },
})
export const text_Style = (theme) => ({
  fontWeight: theme?.typography.h2.fontWeight,
  color: theme?.palette.customWhite.main,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
})
export const icon_Style = (theme) => ({
  color: theme?.palette.customWhite.main,
  fontSize: 'large',
  display: { xs: 'none', sm: 'flex' },
  height: '20px',
  widh: '20px',
})
export const icon_Group_Style = (theme) => ({
  color: theme?.palette.black.main,
  display: { xs: 'none', sm: 'flex' },
  height: '20px',
  widh: '20px',
})

export const postViewModalStyle = {
  modal_Style: {
    width: '100%',
    height: '50px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },

  date_Text: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '24px',
    display: 'flex',
    gap: '4px',
    color: theme.palette.black.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
  }),

  header_Text: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '24px',
    textDecoration: 'underline',
    color: theme.palette.black.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
  }),
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    background: '',
  },
  image_Box: (theme) => ({
    width: '100%',
    padding: '12px',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.sky.light}`,
    borderRadius: '16px',
  }),
  desc_TextStyle: (theme) => ({
    color: theme.palette.black.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    width: '100%',
    lineHeight: '20px',
    textAlign: 'start',
  }),
  swiper_Style: {
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '',
  },
  swiperSlide_Style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  backgroundImage_Box: {
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  footer_Box: {
    boxSizing: 'border-box',
    paddingX: '4px',
    paddingY: '6px',
    width: '100%',
    display: 'flex',
    gap: '20px',
  },
  customBox: {
    display: 'flex',
    gap: '3px',
  },
  reactionCount_Style: (theme) => ({
    color: theme.palette.black.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    lineHeight: '20px',
    textAlign: 'center',
  }),
  text_Style: (theme) => ({
    color: theme.palette.black.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '20px',
    textAlign: 'center',
  }),
}
export const tableDialogStyle = {
  dialog_Style: {
    borderRadius: '16px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: {
      sm: 'var(--Components-Static-Icons-Icon-Height-LG, 20px) 50px',
    },
  },
  header_Text: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '24px',
    color: theme.palette.black.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  }),
  dialogAction: { padding: '0px', gap: '10px', mt: '20px' },
  text_Style: (theme) => ({
    color: theme.palette.black.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: '20px',
    textAlign: 'center',
  }),
  cancelButton: (theme) => ({
    fontFamily: theme?.typography.h4.fontFamily,
    fontWeight: theme?.typography.h4.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '4px 12px',
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: theme?.palette.customWhite.main,
    borderRadius: 'var(--Components-Button-Border-Radius, 8px)',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme?.palette.customWhite.main,
    },
  }),
  deleteButton: (theme) => ({
    fontFamily: theme?.typography.h4.fontFamily,
    fontWeight: theme?.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '4px 12px',
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: theme?.palette.customWhite.main,
    borderRadius: 'var(--Components-Button-Border-Radius, 8px)',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme?.palette.customWhite.main,
    },
  }),
}
