import makeHeader from "../../Headers";

export function timeAgo(dateString) {
  const now = new Date();
  const date = new Date(dateString);
  const diffInMs = now - date;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Approximation
  const years = Math.floor(months / 12); // Approximation

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}

export const instagramProfilesListApi = async (userAuth, spaceId) => {
  // console.log('userAuth',userAuth, "space",spaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/list_profiles?workspaceId=${spaceId}`;

    const response = await fetch(apiUrl, makeHeader("GET", idToken));
    const responseData = await response.json();

    return responseData; // Return the data here
  } catch (error) {
    console.error("Error in instagramProfilesListApi:", error);
    throw error;
  }
};

export const instagramCreatePostApi = async (
  userAuth,
  pageId,
  instaId,
  id,
  formData,
  mediaType
) => {
  // console.log('userAuth',userAuth, "pageId",pageId,'instaId',instaId,'spaceId',id,'body',formData);
  console.log("body", formData);

  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/post_media?workspaceId=${id}&pageId=${pageId}&igUserId=${instaId}&mediaType=${mediaType}`;

    const response = await fetch(apiUrl, makeHeader("POST", idToken, formData));
    const responseData = await response.json();

    return responseData; // Return the data here
  } catch (error) {
    console.error("Error in instagramCreatePostApi:", error);
    throw error;
  }
};

export const instagramGetAllPostsApi = async (userAuth, id, igUserId) => {
  console.log("workspaceId", id, "igUserId", igUserId);

  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/read_media?workspaceId=${id}&igUserId=${igUserId}`;

    const response = await fetch(apiUrl, makeHeader("GET", idToken));
    const responseData = await response.json();

    return responseData; // Return the data here
  } catch (error) {
    console.error("Error in instagramGetAllPostsApi:", error);
    throw error;
  }
};
