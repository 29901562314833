import React from 'react'
import { Box, Typography } from '@mui/material'
import google from '../../../Assets/Images/google.png'
import instagram from '../../../Assets/Images/instagram.png'
import facebook from '../../../Assets/Images/facebook.png'
import linkedin from '../../../Assets/Images/linkedin.png'
import tiktok from '../../../Assets/Images/tiktok.png'
import x from '../../../Assets/Images/x.png'
import pic1 from '../../../Assets/Images/avatar1.png'
import pic2 from '../../../Assets/Images/avatar2.png'
import pic3 from '../../../Assets/Images/avatar3.png'
import cursor from '../../../Assets/Images/cursor.png'
import Features from './features'
import { horizontalFeaturesStyle } from '../landingPageStyle'

function HorizontalFeatures() {
  return (
    <Box sx={horizontalFeaturesStyle.mainbox}>
      <Box sx={horizontalFeaturesStyle.box2}>
        <Box sx={horizontalFeaturesStyle.card}>
          <Box sx={horizontalFeaturesStyle.cardp1}>
            <Typography sx={horizontalFeaturesStyle.heading}>
              Platform integration
            </Typography>

            <Typography sx={horizontalFeaturesStyle.text}>
              Seamlessly connect all your existing apps.
            </Typography>
          </Box>
          <Box sx={horizontalFeaturesStyle.card1p2}>
            {/* First row */}
            <Box sx={horizontalFeaturesStyle.row}>
              <Box sx={horizontalFeaturesStyle.icons}>
                <img src={google} alt='google' width='54px' height='54px' />
              </Box>

              <Box
                sx={{ ...horizontalFeaturesStyle.icons, marginLeft: '10px' }}
              >
                <img
                  src={instagram}
                  alt='instagram'
                  width='54px'
                  height='54px'
                />
              </Box>

              <Box sx={horizontalFeaturesStyle.icons}>
                <img src={facebook} alt='facebook' width='54px' height='54px' />
              </Box>
            </Box>

            {/* Second row with left margin on the first item */}
            <Box sx={horizontalFeaturesStyle.row}>
              <Box
                sx={{ ...horizontalFeaturesStyle.icons, marginLeft: '60px' }}
              >
                <img src={linkedin} alt='linkedin' width='54px' height='54px' />
              </Box>
              <Box sx={horizontalFeaturesStyle.icons}>
                <img src={tiktok} alt='tiktok' width='54px' height='54px' />
              </Box>
              <Box
                sx={{
                  ...horizontalFeaturesStyle.icons,
                  marginLeft: '-30px', // Manually add the gap here
                  position: 'relative', // Make the position relative to move it halfway out
                  left: '30px', // Adjust it by 30px (half of the width) to move it out while keeping the gap
                }}
              >
                <img src={x} alt='x' width='54px' height='54px' />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={horizontalFeaturesStyle.card}>
          <Box sx={horizontalFeaturesStyle.cardp1}>
            <Typography sx={horizontalFeaturesStyle.heading}>
              Collaborate real-time
            </Typography>

            <Typography sx={horizontalFeaturesStyle.text}>
              Seamlessly connect all your existing apps.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <Box
              sx={{
                ...horizontalFeaturesStyle.card2p2,
                zIndex: 4, // Make sure this box stays on top
                background: '#FF003D',
              }}
            >
              <Box
                sx={{
                  ...horizontalFeaturesStyle.card2pic,
                  background: `url(${pic1}) no-repeat center center`, // Add no-repeat and center properties
                }}
              ></Box>
            </Box>

            <Box
              sx={{
                ...horizontalFeaturesStyle.card2p2,
                left: '50px', // Adjust the horizontal positioning (right of the previous box)
                zIndex: 3,
                background: '#05FF00',
              }}
            >
              <Box
                sx={{
                  ...horizontalFeaturesStyle.card2pic,
                  background: `url(${pic2}) no-repeat center center`, // Add no-repeat and center properties
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                ...horizontalFeaturesStyle.card2p2,

                left: '100px', // Right side of the second box
                zIndex: 2,
                background: '#241BFF',
              }}
            >
              <Box
                sx={{
                  ...horizontalFeaturesStyle.card2pic,
                  background: `url(${pic3}) no-repeat center center`, // Add no-repeat and center properties
                }}
              ></Box>
            </Box>

            <Box
              sx={{
                ...horizontalFeaturesStyle.card2p2,

                left: '150px', // Right side of the third box
                zIndex: 1,
                background: '#ECEBFF',
              }}
            >
              <Box sx={horizontalFeaturesStyle.card2pic}></Box>
            </Box>

            {/* Cursor image positioned over the last circle */}
            <Box
              component='img'
              src={cursor}
              alt='Cursor'
              sx={{ ...horizontalFeaturesStyle.pointer }}
            />

            <Box sx={horizontalFeaturesStyle.name}>
              <Typography sx={horizontalFeaturesStyle.nametext}>
                Eliah
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box></Box>

      <Features />
    </Box>
  )
}

export default HorizontalFeatures
