import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import AiPostModalHeader from "./aiPostModalHeader";
import AiPromptField from "./aiPromptField";
import AiTonesPack from "./aiTonesPack";
import AiPostPurpose from "./aiPostPurpose";
import GenerateMedia from "./AiGenerateMedia";
import { useForm } from "react-hook-form";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTheme } from "@emotion/react";
import ResponseAiMedia from "./AiGenerateMedia/responseAiMedia";
import { Loader } from "@infostacks/ui-components";
import { CustaiIconomSvgIcon } from "../../PasswordStartAdornment/index";
import { handleGenerateContent } from "../../../API/AiAPIs/index";
import UserContext from "../../../Context/userContext";

const AiPostModal = ({ handleInsert }) => {
  const { setAiModalOpen, userAuth } = useContext(UserContext);
  const [media, setMedia] = useState(null);
  const [tone, setTone] = useState("Friendly");
  const [purpose, setPurpose] = useState("Friendly");
  const [isGenerated, setIsGenerated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textPrompt, setTextPrompt] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [generatedImage, setGeneratedImage] = useState("");
  const [generateMediaButton, setGenerateMediaButtton] = useState(false);

  const theme = useTheme();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      data.tone = tone;
      data.purpose = purpose;
      data.userInput = textPrompt;
      if (generateMediaButton) data.generateType = "both";
      else data.generateType = "text";
      const resp = await handleGenerateContent(userAuth, data);
      console.log("resp", resp);
      if (resp.status === 200) {
        setGeneratedContent(resp?.content);
        if (resp.logos) {
          setGeneratedImage(resp?.logos[0]?.b64_json);
        }
        setIsGenerated(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box
      sx={{
        height: "731px",
        width: "476px",
        background: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "24px",
        boxSizing: "border-box",
        padding: "20px",
        gap: "0px",
      }}
    >
      <AiPostModalHeader setAiModalOpen={setAiModalOpen} />
      {!isGenerated && !loading ? (
        <>
          <Typography
            sx={{
              width: "100%",
              mt: "20px",
              fontSize: theme?.typography.h5.fontSize,
              fontWeight: theme?.typography.h5.fontWeight,
              fontFamily: theme?.typography.h2.fontFamily,
              fontStyle: "normal",
              lineHeight: "20px",
            }}
          >
            What do you want to write about?
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <AiPromptField
              textPrompt={textPrompt}
              setTextPrompt={setTextPrompt}
            />
            <AiTonesPack register={register} setTone={setTone} tone={tone} />
            <AiPostPurpose
              register={register}
              setPurpose={setPurpose}
              purpose={purpose}
            />
            <GenerateMedia
              setMedia={setMedia}
              media={media}
              generateMediaButton={generateMediaButton}
              setGenerateMediaButtton={setGenerateMediaButtton}
            />
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                background: "",
              }}
            >
              <span
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "",
                  background: "",
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme?.typography.h5.fontSize,
                    fontWeight: theme?.typography.h5.fontWeight,
                    fontFamily: theme?.typography.h2.fontFamily,
                    fontStyle: "normal",
                    lineHeight: "20px",
                  }}
                >
                  <span style={{ color: "red" }}>10K</span>
                  <span style={{ color: "green" }}>/1M</span>
                </Typography>
                <ErrorOutlineIcon
                  sx={{ color: "blue", height: "18px", width: "18px" }}
                />
              </span>
              <Button
                type="submit"
                disabled={textPrompt.length === 0}
                startIcon={<CustaiIconomSvgIcon />}
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  mt: "20px",
                  boxSizing: "border-box",
                  padding:
                    "var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)",
                  fontSize: theme?.typography.menuItems.fontSize,
                  fontWeight: theme?.typography.h3.fontWeight,
                  fontFamily: theme?.typography.h2.fontFamily,
                  background: "linear-gradient(90deg, #4950FF 0%, #6268FD 52%)",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "0.075px",
                  color: "white",
                  ":hover": {
                    background:
                      "linear-gradient(90deg, #4950FF 0%, #6268FD 52%)",
                  },
                }}
              >
                Generate
              </Button>
            </Box>
          </form>
        </>
      ) : loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "665px",
            width: "100%",
          }}
        >
          <Loader thickness={7} size={30} />
        </Box>
      ) : (
        <ResponseAiMedia
          setIsGenerated={setIsGenerated}
          initialPrompt={textPrompt}
          handleInsert={handleInsert}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          setTextPrompt={setTextPrompt}
          generatedContent={generatedContent}
          generatedImage={generatedImage}
        />
      )}
    </Box>
  );
};

export default AiPostModal;
