import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { Box, Button } from "@mui/material";
import { useTheme } from "@emotion/react";

const ReuseableCalender = React.forwardRef(({ confirmDate }, ref) => {
  const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
  console.log("selectedDate when calendar appear", selectedDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleApplyClick = () => {
    const formattedDate = selectedDate.second(0).millisecond(0).toISOString();
    console.log("Selected date & Time:", formattedDate);
    confirmDate(formattedDate, false);
  };
  const theme = useTheme();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        calendarWeekNumberHeaderLabel: "d",
        cancelButtonLabel: "",
        okButtonLabel: "",
        dateRangePickerToolbarTitle: "ddddd",
      }}
    >
      <Box
        sx={{
          height: "420px",
          width: "340px",
          background: "white",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "8px",
        }}
      >
        <StaticDateTimePicker
          slotProps={{
            toolbar: { hidden: true },
            actionBar: { actions: ["cancel", "accept"] },
            tabs: { hidden: false },
          }}
          defaultValue={dayjs(new Date())}
          onChange={handleDateChange}
          ampmInClock
          disablePast
        />
        <style>{`
            .MuiDialogActions-root {
              display: none; 
            },
          `}</style>
        <Button
          sx={{
            width: "100%",
            height: "38px",
            textTransform: "capitalize",
            borderRadius: "8px",
            fontWeight: theme.typography.menualText.fontWeight,
            color: "white",
            backgroundColor: theme.palette.primary.dark,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              color: "white",
            },
          }}
          onClick={handleApplyClick}
        >
          Apply
        </Button>
      </Box>
    </LocalizationProvider>
  );
});
ReuseableCalender.displayName = "ReuseableCalender";
export default ReuseableCalender;
