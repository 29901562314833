import { Circle, MoreVertRounded } from "@mui/icons-material";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { instagramPost } from "../../instagramStyle";
import { useTheme } from "@emotion/react";
import CustomMenu from "../../../../../ReusableComponents/customMenu";
import { formatDateString } from "../../../../../../API";
import { timeAgo } from "../../../../../../API/PlatformsAPIs/InstagramApi";

const Postcard = ({ val, anchorEl, open, handleClick, handleClose }) => {
  const items = ["Edit", "Go to post", "Copy link", "Cancel", "Delete"];
  const text = "Delete";
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${val.thumbnailUrl})`,
          objectFit: "cover",
          backgroundPosition: "center",
          ...instagramPost.main,
        }}
      >
        <Box sx={instagramPost.cardDetail}>
          <Box sx={instagramPost.detailBox}>
            <Typography sx={instagramPost.postCaption}>
              {val.caption}
            </Typography>
            <MoreVertRounded
              sx={{ color: "#FFF", cursor: "pointer", fontSize: "20px" }}
              onClick={handleClick}
            />
          </Box>

          <Box sx={instagramPost.postDetail}>
            <Typography sx={instagramPost.postTypo}>
              {timeAgo(val.createdAt)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                background: "",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Circle sx={{ fontSize: "6px" }} />
              <Typography sx={instagramPost.postTypo}>
                Likes {val.engagements.likesCount}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                background: "",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Circle sx={{ fontSize: "6px" }} />
              <Typography sx={instagramPost.postTypo}>
                Comments {val.engagements.commentsCount}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            padding:
              "var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px)",
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "16px",
            background: "#FFF",
            boxShadow: "0px 6px 10px 0px rgba(0,0,0,0.03)",
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              boxSizing: "border-box",
              lineHeight: "20px",
              letterSpacing: "0.07px",
              fontSize: theme.typography?.menuItems?.fontSize || "",
              fontWeight: theme.typography?.menuItems?.fontWeight || "normal",
              fontFamily: theme.typography?.menuItems?.fontFamily,
              color: item === text ? "red" : theme.palette?.black?.main,
              "&:hover": {
                backgroundColor: theme.palette?.gray?.hover || "#f5f5f5",
                borderRadius:
                  "var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)",
              },
            }}
            onClick={handleClose}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Postcard;
