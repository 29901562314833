const ROUTES = {
  public: ['/', '/signin', '/signup', '/welcome', '/invitation',],
  private: [
    '/dashboard',
    '/dashboard/workspaces',
    '/dashboard/roles',
    '/dashboard/create-role',
    '/dashboard/edit-role/:roleId',
    '/dashboard/manage-role',
    '/dashboard/userprofile',
    '/dashboard/platforms',
    '/dashboard/archived-spaces',
    '/dashboard/platforms/google',
    '/dashboard/facebook',
    '/dashboard/facebook/create-post',
    '/dashboard/editprofile',
    '/choose-plan',
    '/create-new-workspace',
    'edit-workspace',
    '/social-connections',
    '/subscription-successfull',
  ],
}
export default ROUTES
