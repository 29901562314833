import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const CustomSnackbar = ({ status }) => {
  console.log('status', status)
  const [open, setOpen] = React.useState(true)

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={handleClose}
          severity={
            status === 'already accepted'
              ? 'success'
              : status === 'already rejected'
              ? 'error'
              : status === 'expired'
              ? 'info'
              : status === 'accepted'
              ? 'success'
              : status === 'rejected'
              ? 'error'
              : status === 'User already has the same role in this workspace'
              ? 'error'
              : status === 'Invalid request data'
              ? 'error'
              : status === 'Insufficient credits available for sharing'
              ? 'error'
              : status?.includes(
                  'Cannot revert more than the remaining credits.'
                )
              ? 'error'
              : 'success'
          }
          variant='filled'
          sx={{ width: '100%' }}
        >
          {status === 'already accepted'
            ? 'You already accepted the invitation'
            : status === 'already rejected'
            ? 'You already rejected the invitation'
            : status === 'expired'
            ? 'Invitation has been expired'
            : status === 'accepted'
            ? 'Invitation has been accepted successfully'
            : status === 'rejected'
            ? 'Invitation has been rejected successfully'
            : status}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomSnackbar
