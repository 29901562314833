import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { generateBtn, mediaBtn, postModel } from "../../instagramStyle";
import { useTheme } from "@emotion/react";
import UserContext from "../../../../../../Context/userContext";
import { useDropzone } from "react-dropzone";
import ImageEditModalPintura from "./ImageSection/imageEditModal";
import ManualPostHeader from "./manualPostHeader";
import CaptionEmojiAndOptions from "./CaptionEmojiAndOptions";
import ImageSection from "./ImageSection";
import ManualPostFooter from "./ManualPostFooter";
import { instagramCreatePostApi } from "../../../../../../API/PlatformsAPIs/InstagramApi";
import { useParams } from "react-router-dom";
import ReuseableCalender from "../../../../../ReusableComponents/reuseableCalender";

const ManualPostForm = ({
  handleClose,
  loading,
  setLoading,
  media,
  setMedia,
  caption,
  setCaption,
}) => {
  const { pageId, igUserId, id } = useParams();
  // console.log('pageId',pageId,'instaId',instaId,'spaceId',id)
  const theme = useTheme();
  const { userAuth, setAiModalOpen } = useContext(UserContext);
  const [mediaType, setMediaType] = useState("post");
  const [imageSrc, setImageSrc] = useState(null);
  const [editImageIndex, setEditimageIndex] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Publish");
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeModal, setTimeModal] = useState(false);
  const [ScheduleTime, setScheduleTime] = useState("");
  console.log("selectedOption", selectedOption);

  const handleForm = async () => {
    setLoading(true);
    const formData = new FormData();
    console.log("media", media);
    // Add caption if not a "Story" or empty
    if (mediaType !== "story" && caption.length > 0) {
      formData.append("caption", caption);
    }
    // Append each media file to the FormData object
    media.forEach((mediaItem, index) => {
      formData.append(`files`, mediaItem.file);
    });
    // Log form data content
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      if (selectedOption === "Publish") {
        formData.append("postStatus", "posted");
        const resp = await instagramCreatePostApi(
          userAuth,
          pageId,
          igUserId,
          id,
          formData,
          mediaType
        );
        console.log("resp", resp);
      }
      if (selectedOption === "Schedule") {
        console.log("Ready for Schedule");
      }
      if (selectedOption === "Draft") {
        console.log("Ready for Draft");
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      console.log("acceptedFiles", acceptedFiles);
      const newMedia = acceptedFiles.map((file) => {
        const objectUrl = URL.createObjectURL(file);
        return { file, url: objectUrl };
      });

      setMedia((prevMedia) => {
        const currentMedia = Array.isArray(prevMedia) ? prevMedia : [];
        const combinedMedia = [...currentMedia, ...newMedia];
        return combinedMedia.slice(0, 8);
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 8,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "video/mp4": [],
    },
  });

  const handleClosebtn = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionSelect = (option) => {
    if (option === "Publish") {
      handleClosebtn();
      setSelectedOption(option);
    }
    if (option === "Schedule") {
      handleClosebtn();
      setSelectedOption(option);
      setTimeModal(true);
    }
    if (option === "Draft") {
      handleClosebtn();
      setSelectedOption(option);
    }
  };

  const confirmDate = (dateTime, state) => {
    console.log("dateTime", dateTime, "state", state);

    setScheduleTime(dateTime);
    setTimeModal(false);
  };

  return (
    <>
      {editModal ? (
        <ImageEditModalPintura
          editModal={editModal}
          setEditModal={setEditModal}
          setMedia={setMedia}
          editImageIndex={editImageIndex}
          imageSrc={imageSrc}
        />
      ) : (
        <form style={postModel.postForm}>
          <ManualPostHeader handleClose={handleClose} loading={loading} />
          <Divider sx={{ width: "100%" }} />
          {media.length === 0 ? (
            <Box sx={postModel.uploadBox}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96"
                height="77"
                viewBox="0 0 96 77"
                fill="none"
              >
                <path
                  d="M16.0328 24.0904H16.3279C19.082 23.8936 21.1476 21.533 21.0492 18.7789C20.8525 16.0248 18.4918 13.9592 15.7377 14.0576C12.9836 14.1559 10.9181 16.6149 11.0164 19.369C11.1148 22.0248 13.3771 24.0904 16.0328 24.0904ZM13.6722 17.0084C14.164 16.4182 14.9509 16.0248 15.7377 16.0248H15.9345C17.6066 16.0248 18.9836 17.4018 18.9836 19.074C18.9836 20.7461 17.6066 22.1231 15.9345 22.1231C14.2623 22.1231 12.8853 20.7461 12.8853 19.074C12.8853 18.2871 13.1804 17.5986 13.6722 17.0084Z"
                  fill="black"
                />
                <path
                  d="M83.3114 18.5818L57.0492 17.1064L56.8524 14.1555C56.5574 8.54898 51.7377 4.22111 46.0328 4.51619L12.6885 6.38504C7.08194 6.68012 2.75407 11.5982 3.04915 17.2047L4.918 50.6473V51.4342C5.60653 56.549 9.9344 60.385 15.0492 60.385H15.6393L36.9836 59.2047V59.7949C36.6885 65.4014 40.918 70.3195 46.6229 70.6146L80.0656 72.5818H80.6557C86.0656 72.5818 90.5901 68.3523 90.8852 62.9424L92.8524 29.4998C93.2459 23.7949 88.918 18.9752 83.3114 18.5818ZM7.08194 11.1064C8.55735 9.43422 10.6229 8.45062 12.7869 8.35226L46.2295 6.4834C50.7541 6.18832 54.6885 9.72931 54.9836 14.2539L55.1803 17.008L49.9672 16.7129C44.3606 16.4178 39.4426 20.6473 39.1475 26.3523L38.5574 35.6965L29.2131 46.2211C29.0164 46.5162 28.6229 46.6146 28.2295 46.7129C27.836 46.7129 27.541 46.6146 27.2459 46.3195L19.5737 39.4342C18.1967 38.1555 16.1311 38.3523 14.8524 39.7293L6.88522 48.6801L5.11472 17.2047C4.918 14.9424 5.70489 12.7785 7.08194 11.1064ZM15.6393 58.3195C11.4098 58.5162 7.6721 55.5654 6.98358 51.3359L16.2295 41.008C16.4262 40.7129 16.8196 40.6146 17.2131 40.5162C17.6065 40.5162 17.9016 40.6146 18.1967 40.9096L25.8688 47.7949C26.5573 48.385 27.4426 48.6801 28.3278 48.6801C29.2131 48.6801 30 48.1883 30.5901 47.5982L38.2623 38.9424L37.1803 57.2375L15.6393 58.3195ZM90.8852 29.3031L88.918 62.7457C88.6229 67.2703 84.6885 70.8113 80.1639 70.5162L46.7213 68.549C42.1967 68.2539 38.6557 64.3195 38.9508 59.7949L40.918 26.3523C41.2131 22.0244 44.7541 18.5818 49.1803 18.5818H49.6721L83.1147 20.549C87.7377 20.8441 91.1803 24.7785 90.8852 29.3031Z"
                  fill="black"
                />
                <path
                  d="M76.918 41.4019L60.2951 30.4838C58.2295 29.1068 55.4754 29.697 54.1967 31.7625C53.8033 32.451 53.5082 33.1396 53.5082 33.9265L52.3279 53.697C52.2295 56.156 54 58.2215 56.459 58.4183H56.7541C57.4426 58.4183 58.1312 58.2215 58.7213 57.9265L76.4262 49.074C78.5902 47.992 79.4754 45.3363 78.3934 43.1724C78 42.4838 77.5082 41.8937 76.918 41.4019ZM75.541 47.3035L57.8361 56.156C57.4426 56.3527 57.0492 56.4511 56.5574 56.4511C56.1639 56.4511 55.6721 56.2543 55.3771 56.0576C54.6885 55.5658 54.1967 54.7789 54.2951 53.8937L55.4754 34.1232C55.5738 33.2379 56.0656 32.451 56.8525 32.0576C57.6394 31.6642 58.5246 31.7625 59.3115 32.156L75.7377 43.074C76.918 43.8609 77.2131 45.3363 76.4262 46.4183C76.2295 46.8117 75.9344 47.1068 75.541 47.3035Z"
                  fill="black"
                />
              </svg>
              <Typography
                sx={{
                  color: theme.palette.black.main,
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "30px",
                  fontFamily: "Libre Franklin",
                }}
              >
                Upload Media Here
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  height: "fit-content",
                  width: "fit-content",
                  mt: "13px",
                }}
              >
                <Button
                  onClick={() => setAiModalOpen(true)}
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="20"
                      viewBox="0 0 26 20"
                      fill="none"
                    >
                      <path
                        d="M7.55682 10.6246C6.98281 10.406 6.98281 9.59398 7.55682 9.37541C11.1205 8.01843 13.935 5.20397 15.292 1.64027C15.5106 1.06626 16.3226 1.06626 16.5412 1.64027C17.8982 5.20397 20.7127 8.01843 24.2764 9.37541C24.8504 9.59398 24.8504 10.406 24.2764 10.6246C20.7127 11.9816 17.8982 14.796 16.5412 18.3597C16.3226 18.9337 15.5106 18.9337 15.292 18.3597C13.935 14.796 11.1205 11.9816 7.55682 10.6246Z"
                        fill="white"
                      />
                      <path
                        d="M3.96328 4.37486C3.77194 4.30201 3.77194 4.03133 3.96328 3.95847C5.15118 3.50614 6.08934 2.56799 6.54168 1.38009C6.61453 1.18875 6.88521 1.18875 6.95807 1.38009C7.4104 2.56799 8.34857 3.50614 9.53647 3.95847C9.72781 4.03133 9.72781 4.30201 9.53647 4.37486C8.34856 4.82719 7.4104 5.76534 6.95807 6.95324C6.88521 7.14458 6.61453 7.14458 6.54168 6.95324C6.08934 5.76534 5.15118 4.82719 3.96328 4.37486Z"
                        fill="white"
                      />
                      <path
                        d="M0.698493 14.8177C0.483238 14.7358 0.483238 14.4312 0.698493 14.3493C2.03488 13.8404 3.09032 12.785 3.59919 11.4486C3.68116 11.2333 3.98567 11.2333 4.06763 11.4486C4.57651 12.785 5.63194 13.8404 6.96833 14.3493C7.18359 14.4312 7.18359 14.7358 6.96833 14.8177C5.63194 15.3266 4.57651 16.382 4.06763 17.7184C3.98567 17.9336 3.68116 17.9336 3.59919 17.7184C3.09032 16.382 2.03488 15.3266 0.698493 14.8177Z"
                        fill="white"
                      />
                    </svg>
                  }
                  sx={generateBtn}
                >
                  Generate
                </Button>
                <Button {...getRootProps()} sx={mediaBtn}>
                  Select Media
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <CaptionEmojiAndOptions
                setAiModalOpen={setAiModalOpen}
                mediaType={mediaType}
                caption={caption}
                setCaption={setCaption}
                loading={loading}
              />
              <ImageSection
                getRootProps={getRootProps}
                media={media}
                setMedia={setMedia}
                setImageSrc={setImageSrc}
                setEditimageIndex={setEditimageIndex}
                setEditModal={setEditModal}
                mediaType={mediaType}
                setMediaType={setMediaType}
                loading={loading}
              />
              <ManualPostFooter
                loading={loading}
                handleOptionSelect={handleOptionSelect}
                selectedOption={selectedOption}
                anchorEl={anchorEl}
                handleClosebtn={handleClosebtn}
                handleClick={handleClick}
                handleForm={handleForm}
                ScheduleTime={ScheduleTime}
                setTimeModal={setTimeModal}
              />
            </>
          )}
        </form>
      )}
      {timeModal && (
        <Modal
          open={timeModal}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setTimeModal(false);
            }
          }}
          sx={{
            background: "",
            margin: "auto",
            height: "fit-content",
            width: "fit-content",
            borderRadius: "8px",
          }}
        >
          <ReuseableCalender
            timeModal={timeModal}
            setTimeModal={setTimeModal}
            confirmDate={confirmDate}
          />
        </Modal>
      )}
    </>
  );
};

export default ManualPostForm;
