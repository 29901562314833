import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Pagination } from "swiper/modules";

const ImageSwiperSection = ({
  media,
  setMedia,
  setImageSrc,
  setEditimageIndex,
  setEditModal,
}) => {
  const [swiper, setSwiper] = useState(null);

  const handleDelete = (index) => {
    setMedia((prevMedia) => prevMedia.filter((_, i) => i !== index));
  };
  const handleEdit = (file, ind) => {
    console.log("edit file", file, "index", ind);
    setImageSrc(file);
    setEditimageIndex(ind);
    setEditModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "fit-content",
        gap: "13px",
        background: "",
        justifyContent: "center",
      }}
    >
      <Swiper
        spaceBetween={30}
        navigation={false}
        onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
        slidesPerView={"auto"}
        grabCursor={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Pagination]}
        className="fbSwiper"
        style={{
          width: "100%",
          height: "100%",
          background: "",
        }}
      >
        {media.map((file, ind) => (
          <SwiperSlide key={ind} style={{ width: "auto" }}>
            <Box
              sx={{
                position: "relative",
                height: "200px",
                maxWidth: "350",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover .media-actions": { display: "flex" },
              }}
            >
              {file.file.type.startsWith("video/") ? (
                <video
                  controls
                  autoPlay={true}
                  loop={false}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <source src={file.url} type={file.file.type} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={file.url}
                  alt="Selected Media"
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                />
              )}

              {/* Action buttons visible on hover */}
              <Box
                className="media-actions"
                sx={{
                  display: "none",
                  position: "absolute",
                  zIndex: 1,
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  borderRadius: "10px",
                }}
              >
                {!file.file.type.startsWith("video/") && (
                  <IconButton
                    onClick={() => handleEdit(file?.url, ind)}
                    sx={{
                      height: "30px",
                      width: "30px",
                      backgroundColor: "lightgray",
                      "&:hover": { backgroundColor: "lightgray" },
                    }}
                  >
                    <EditOutlined sx={{ height: "20px", width: "20px" }} />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => handleDelete(ind)}
                  sx={{
                    height: "30px",
                    width: "30px",
                    backgroundColor: "lightgray",
                    "&:hover": { backgroundColor: "lightgray" },
                  }}
                >
                  <DeleteOutline sx={{ height: "20px", width: "20px" }} />
                </IconButton>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ImageSwiperSection;
