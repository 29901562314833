import { Box, Modal } from '@mui/material';
import { PinturaEditor } from '@pqina/react-pintura';
import React from 'react'
import {
    createDefaultImageReader,
    createDefaultImageWriter,
    createDefaultShapePreprocessor,
    setPlugins,
    plugin_crop,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_filter,
    plugin_filter_defaults,
    plugin_annotate,
    markup_editor_defaults,
  } from "@pqina/pintura";
  import {
    LocaleCore,
    LocaleCrop,
    LocaleFinetune,
    LocaleFilter,
    LocaleAnnotate,
    LocaleMarkupEditor,
  } from "@pqina/pintura/locale/en_GB";
  import "@pqina/pintura/pintura.css";

 // Set plugins for the editor
setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = {
  utils: ["crop", "finetune", "filter", "annotate"],
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...LocaleCore,
    ...LocaleCrop,
    ...LocaleFinetune,
    ...LocaleFilter,
    ...LocaleAnnotate,
    ...LocaleMarkupEditor,
  },
};


const ImageEditModalPintura = ({editModal,setEditModal,setMedia,editImageIndex,imageSrc}) => {
    
  return (
    <Modal
    open={editModal}
    onClose={()=>setEditModal(false)}
    sx={{height: '731px',width:'680px',borderRadius: '24px',mx:'auto',my:'auto',background:'white'}}
    >
      <Box
    sx={{
      height: '100%',
      width:'100%',
      overflow: "hidden", // Prevent overflow
      // position: "relative",
      background: 'white', // Ensure background is visible
      borderRadius: '24px',
      // mt:'auto'
    }}
  >
    <PinturaEditor
      {...editorDefaults}
      src={imageSrc}
      style={{ width: '100%', height: '100%' }} // Ensure editor fills its container
      onLoad={(res) => console.log("load image", res)}
      onProcess={({ dest }) => {
        // Create an object URL for the edited image
        const editedImageURL = URL.createObjectURL(dest);
      
        // Update the media array at the specific index
        setMedia(prevMedia => 
          prevMedia.map((item, i) => (i === editImageIndex ? { ...item, url: editedImageURL } : item))
        );
      
        setEditModal(false); // Close the editor modal
      }}
    />
  </Box>
    </Modal>
  )
}

export default ImageEditModalPintura