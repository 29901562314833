export const sidebarMainBox = (drawer, theme) => ({
  minWidth: {
    xs: `${'70px'}`,
    lg: `${drawer ? '280px' : '70px'}`,
  },
  maxWidth: {
    xs: `${'70px'}`,
    lg: `${drawer ? '320px' : '70px'}`,
  },
  height: '100vh',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme?.palette?.customWhite.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  paddingX: '15px',
  overflowX: 'hidden',
  overflowY: 'auto',
  boxShadow: {
    md: "drawer ? '0px 3px 7px -3px rgba(0, 0, 0, 0.20), 0px 6px 28px -2px rgba(50, 50, 93, 0.15)' : ''",
  },
  borderRight: `1px solid #E5E5E5`,
})

export const sidebarHeader = () => ({
  width: '100%',
  marginTop: '40px',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  cursor: 'pointer',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
  },
})

export const headerLogo = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const headerTypography = (drawer, theme) => ({
  lineHeight: '1',
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme?.palette.black.main,
  width: 'auto',
  visibility: drawer ? 'visible' : 'hidden',
  display: {
    xs: 'none',
    lg: 'flex',
  },
  '&:hover': {
    color: theme?.palette.black.main,
  },
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const headerCloseLogo = (drawer) => ({
  justifyContent: 'center',
  alignItems: 'center',
  ml: 'auto',
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const dropDownSpaceBox = (drawer, spaceState) => ({
  background: 'white',
  position: 'absolute',
  borderRadius: '8px',
  width: drawer ? '89%' : '',
  marginTop: `${drawer ? '90px' : '90px'}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '10px',
  boxShadow: `${
    spaceState
      ? '0px 3px 7px -3px rgba(0, 0, 0, 0.20), 0px 6px 28px -2px rgba(50, 50, 93, 0.15)'
      : ''
  }`,
})
export const spacesMainBox = (theme) => ({
  backgroundColor: theme?.palette?.sky.light,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  cursor: 'pointer',
  mt: '15px',
  color: theme?.palette.black.main,
  boxSizing: 'border-box',
  transition: 'color 0.3s ease-in-out, visibility 0.3s ease-in-out',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
    color: theme?.palette.black.main,
  },
})

export const spaceLogoBox = () => ({
  height: '26px',
  width: '26px',
  background: '',
  objectFit: 'cover',
  borderRadius: '26px',
})

export const spaceName = (drawer, theme) => ({
  background: '',
  lineHeight: '1',
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  width: 'auto',
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const spaceExpandLogo = (drawer) => ({
  height: '26px',
  width: '26px',
  ml: 'auto',
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
})

export const spaceMenu = (drawer) => ({
  width: '247px',
  background: '',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
})

export const collapse = () => ({
  background: '',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  paddingX: '10px',
  width: '100%',
})
export const spaceMenuBtnsBox = (drawer) => ({
  width: '233px',
  mt: '5px',
  background: '',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  visibility: `${drawer ? 'visible' : 'visible'}`,
})

export const allSpaceBtn = (activeButton, theme) => ({
  background: activeButton === 'all' ? theme.palette.sky.light : '',
  border:
    activeButton === 'all'
      ? `1px solid ${theme.palette.sky.light}`
      : `1px solid ${theme.palette.sky.main}`,
  height: '36px',
  width: '87px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  fontSize: theme?.typography.h5.fontSize,
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  padding: '0px',
  boxSizing: 'border-box',
  '&:hover': {
    color: theme?.palette.black.main,
    backgroundColor: theme?.palette?.sky.light,
    border: `1px solid ${theme.palette.sky.light}`,
  },
})

export const favourtBtn = (activeButton, theme) => ({
  background: activeButton === 'favourite' ? theme?.palette?.sky.light : '',
  border:
    activeButton === 'favourite'
      ? `1px solid ${theme.palette.sky.light}`
      : `1px solid ${theme.palette.sky.main}`,
  height: '36px',
  width: '87px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  fontSize: theme?.typography.h5.fontSize,
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  padding: '0px',
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    color: theme?.palette.black.main,
    border: `1px solid ${theme.palette.sky.light}`,
  },
})
export const addRoundedIcon = (theme) => ({
  background: '',
  height: '36px',
  width: '40px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.sky.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '0px',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    border: `1px solid ${theme.palette.sky.light}`,
  },
})

export const spaceMenuMapBox = (theme, val) => ({
  width: '100%',
  borderRadius: '8px',
  background:
    val?._id === window.sessionStorage.getItem('spaceId')
      ? theme?.palette?.sky.light
      : '',
  padding: '6px',
  boxSizing: 'border-box',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color:
    val?._id === window.sessionStorage.getItem('spaceId')
      ? theme?.palette.black.main
      : theme?.palette.black.light,
  cursor: 'pointer',
  border: `1px solid ${theme.palette.sky.main}`,
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    color: theme?.palette.black.main,
    border: `1px solid ${theme.palette.sky.light}`,
    transition: 'background-color 0.1s ease-in-out',
  },
})

export const spaceMenuMapImage = (drawer) => ({
  width: drawer ? '26px' : '26px',
  height: drawer ? '26px' : '26px',
  borderRadius: '26px',
})

export const spaceMenuMapSpaceName = (drawer, theme) => ({
  lineHeight: '1',
  fontSize: theme?.typography.h5.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  width: 'auto',
  visibility: `${drawer ? 'visible' : 'visible'}`,
})

export const upperDivider = () => ({
  width: '100%',
  mt: '15px',
})

export const sidebarAllWorkspaces = (theme, activeSidebarOption) => ({
  width: '100%',
  backgroundColor:
    activeSidebarOption === '/dashboard' ? theme?.palette?.sky.light : '',
  marginTop: '15px',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  color: theme?.palette.black.main,
  cursor: 'pointer',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
    color: theme?.palette.black.main,
  },
})

export const sidebarHome = (theme, activeSidebarOption) => ({
  width: '100%',
  backgroundColor:
    activeSidebarOption === '/dashboard/home' ? theme?.palette?.sky.light : '',
  marginTop: '15px',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  color: theme?.palette.black.main,
  cursor: 'pointer',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
    color: theme?.palette.black.main,
  },
})

export const HomeLogo = () => ({
  background: '',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const homeTypography = (drawer, theme) => ({
  background: '',
  lineHeight: '1',
  fontSize: theme?.typography.h4.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  width: 'auto',
  visibility: drawer ? 'visible' : 'hidden',
  display: { xs: 'none', lg: 'flex' },
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const sidebarArchivedSpaces = (drawer, activeSidebarOption, theme) => ({
  width: '100%',
  backgroundColor:
    activeSidebarOption === '/dashboard/archived-spaces' ? '#DADAFF' : '',
  marginTop: '15px',
  display: 'flex',
  alignItems: 'center',
  color: theme?.palette.black.light,
  padding: '6px',
  borderRadius: '8px',
  cursor: 'pointer',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    background: '#DADAFF',
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
    color: theme?.palette.black.main,
  },
})

export const ArchivedSpacesLogo = () => ({
  background: '',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const ArchivedSpacesTypography = (drawer, theme) => ({
  background: '',
  lineHeight: '1',
  fontSize: theme?.typography.h4.fontSize,
  fontFamily: theme?.typography.h3.fontFamily,
  fontWeight: theme?.typography.h3.fontWeight,
  width: 'auto',
  visibility: drawer ? 'visible' : 'hidden',
  display: { xs: 'none', lg: 'flex' },
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const socialPlatFormsMainBox = (drawer) => ({
  width: '100%',
  marginTop: '10px',
  background: 'yellow',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  flexDirection: 'column',
  gap: '24px',
})

export const platformsBox = (theme, allSpaces) => ({
  backgroundColor: theme?.palette?.customWhite.main,
  width: '100%',
  display: allSpaces.length > 0 ? 'flex' : 'none',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  cursor: 'pointer',
  mt: '15px',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
  },
})

export const contentBox = (theme, expand) => ({
  backgroundColor: theme?.palette?.customWhite.light,
  width: '100%',

  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  cursor: 'pointer',
  // mt: '15px',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
  },
})

export const platformLogo = () => ({
  height: '26px',
  width: '26px',
  background: '',
})

// export const platformsTypography = (drawer, theme) => ({
//   background: '',
//   lineHeight: '1',
//   fontSize: theme?.typography.h4.fontSize,
//   fontFamily: theme?.typography.h4.fontFamily,
//   fontWeight: theme?.typography.h2.fontWeight,
//   color: theme?.palette.black.light,
//   width: 'auto',
//   visibility: `${drawer ? 'visible' : 'hidden'}`,
//   display: { xs: 'none', lg: 'flex' },
//   '&:hover': {
//     color: theme?.palette.black.main,
//   },
// })
// export const platformsExpandLogo = (drawer) => ({
//   height: '26px',
//   width: '26px',
//   ml: 'auto',
//   visibility: `${drawer ? 'visible' : 'hidden'}`,
//   display: { xs: 'none', lg: 'flex' },
// })

export const platformMenu = (drawer) => ({
  width: '247px',
  background: '',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
})

export const platformMenuMap = (theme, activeSidebarOption, name) => ({
  mt: '5px',
  width: '217px',
  backgroundColor: activeSidebarOption.includes(name)
    ? theme?.palette?.sky.light
    : '',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '6px',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.sky.main}`,
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    transition: 'background-color 0.1s ease-in-out',
    border: `1px solid ${theme.palette.sky.light}`,
  },
})
export const menuLogoBox = () => ({
  background: '',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
export const menuLogo = (drawer) => ({
  width: drawer ? '26px' : '26px',
  height: drawer ? '26px' : '26px',
  visibility: `${drawer ? 'visible' : 'visible'}`,
})

export const menuTypography = (drawer, theme) => ({
  background: '',
  lineHeight: '1',
  fontSize: theme?.typography.h5.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  color: theme?.palette.black.light,
  width: 'auto',
  textTransform: 'capitalize',
  visibility: `${drawer ? 'visible' : 'visible'}`,
  '&:hover': {
    color: theme?.palette.black.main,
  },
})

export const seeAllBtn = (drawer, theme) => ({
  background: '',
  textTransform: 'capitalize',
  mt: '5px',
  mb: '5px',
  fontSize: theme?.typography.h5.fontSize,
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  padding: '0px',
  paddingX: '4px',
  boxSizing: 'border-box',
  visibility: `${drawer ? 'visible' : 'visible'}`,
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    color: theme?.palette.black.main,
  },
})

export const dividerLower = () => ({
  width: '100%',
  marginTop: 'auto',
})

export const notificationBox = (theme) => ({
  width: '100%',
  marginTop: '15px',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  borderRadius: '8px',
  cursor: 'pointer',
  boxSizing: 'border-box',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 11px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
  },
})

export const notificationLogo = () => ({
  background: '',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const notificationTypography = (drawer, theme) => ({
  background: '',
  lineHeight: '1',
  fontSize: theme?.typography.h4.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  color: theme?.palette.black.light,
  width: 'auto',
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
  '&:hover': {
    color: theme?.palette.black.main,
  },
})

export const progressMainBox = (drawer, currentUser) => ({
  display: currentUser?.Subscription ? 'flex' : 'none',
  flexDirection: 'column',
  backgroundColor: '',
  width: '100%',
  padding: 'var(--Numbers-Number-4, 8px) var(--Numbers-Number-8, 0px)',
  cursor: 'pointer',
  boxSizing: 'border-box',
  gap: 'var(--Numbers-Number-4, 8px)',
  marginTop: 'auto',
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const calenderBox = (drawer) => ({
  background: '',
  alignItems: 'center',
  gap: '12px',
  textTransform: 'capitalize',
  width: '100%',
  height: '15px',
  justifyContent: 'flex-start',
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
})
export const linerProgressStyle = (drawer) => ({
  width: '100%',
  height: '6px',
  borderRadius: '16px',
  backgroundColor: '#E0E0E0',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#241BFF',
  },
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
})

export const creditsTypography = (drawer, theme) => ({
  background: '',
  lineHeight: '1',
  fontSize: theme?.typography.paragraph.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  color: theme?.palette.black.main,
  visibility: `${drawer ? 'visible' : 'hidden'}`,
  display: { xs: 'none', lg: 'flex' },
})

export const sidebarFooter = (theme, drawer, currentUser) => ({
  backgroundColor: theme?.palette?.sky.light,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  my: '15px',
  boxSizing: 'border-box',
  padding: drawer
    ? ' var(--Numbers-Number-9, 18px) var(--Numbers-Number-8, 6px)'
    : '18px 6px',
  borderRadius: ' var(--Numbers-Number-8, 16px)',
  cursor: 'pointer',
  gap: 'var(--Components-Badge-Spacing-Y-LG, 8px)',
  position: 'relative',
  marginTop: currentUser?.Subscription ? '15px' : 'auto',
})
export const badgeStyle = (theme) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})

export const footerLogoBox = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const footerTypography = (drawer, theme) => ({
  lineHeight: '27px',
  fontSize: theme?.typography.h3.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  color: theme?.palette.black.main,
  width: 'auto',
  fontStyle: 'normal',
  display: { xs: 'none', lg: 'flex' },
  visibility: 'visible',
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})

export const footerLogoImageStyle = (drawer) => ({
  height: '26px',
  width: '26px',
  borderRadius: '50%',
  objectFit: 'cover',
  transition: 'transform 3s ease-in-out',
  // transform: drawer ? 'translateX(0)' : 'translateX(-100px)',
})

export const footerUserNameBox = () => ({
  height: '26px',
  width: '26px',
  borderRadius: '50%',
  background: 'purple',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: '16px',
})

export const footerMoreButton = (drawer) => ({
  ml: 'auto',
  display: { xs: 'none', lg: 'flex' },
  visibility: `${drawer ? 'visible' : 'hidden'}`,
})
export const main_AccordianBox = (
  hasSpaces,
  canAddPlatform,
  integratedPlatform
) => ({
  width: '100%',
  mt: '15px',
  display:
    (hasSpaces && canAddPlatform) || (hasSpaces && integratedPlatform)
      ? 'flex'
      : 'none',
  background: '',
})

export const accordian_Style = () => ({
  background: '',
  width: '100%',
  padding: '0px',
  boxSizing: 'border-box',
  borderRadius: '6px',
  boxShadow: 'none',
  overflow: 'hidden',
})
export const accordianSummry_Style = (expand, theme) => ({
  backgroundColor: expand ? theme.palette.sky.light : '',
  boxSizing: 'border-box',
  padding: '0px',
  paddingX: '6px',
  height: '40px',
  minHeight: '40px',
  maxHeight: '40px',
  color: theme?.palette.black.main,
  width: '100%',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  ':hover': {
    background: theme.palette.sky.light,
    color: theme?.palette.black.main,
  },
})
export const expandMoreOutlined_Style = () => ({
  display: 'flex',
  background: '',
  height: '26px',
  width: '26px',
  ml: 'auto',
})
export const accordian_Details_Style = () => ({
  boxSizing: 'border-box',
  paddingRight: '0px',
  paddingY: '10px',
  paddingLeft: '20px',
  overflow: 'hidden',
  gap: '5px',
  display: 'flex',
  flexDirection: 'column',
})
export const accordian_Details_Map = (activeSidebarOption, val, theme) => ({
  cursor: 'pointer',
  width: '100%',
  background: activeSidebarOption.includes(val.name)
    ? theme.palette.sky.light
    : '',
  borderRadius: '6px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingX: '6px',
  gap: '15px',
  ':hover': { background: theme.palette.sky.light },
})
export const platform_img_Style = () => ({
  height: '26px',
  width: '26px',
  borderRadius: '50%',
  objectFit: 'cover',
})
export const platform_name_Style = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  textTransform: 'capitalize',
})
export const platform_TextStyles = (drawer, theme) => ({
  display: 'flex',
  background: '',
  ml: '12px',
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  opacity: drawer ? 1 : 0,
  transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
  transition: drawer
    ? 'opacity 0.4s ease, transform 0.4s ease'
    : 'opacity 0.4s ease',
  transitionDelay: drawer ? '0.4s' : '0s',
})
