import React, { useCallback, useEffect } from "react";
import ToggleCustomButton from "./toggleButton";
import { Box } from "@mui/material";
import ReferenceMedia from "./referenceMedia";
import ImageSection from "./imageSection";

const GenerateMedia = ({
  setMedia,
  media,
  generateMediaButton,
  setGenerateMediaButtton,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const aiMedia = acceptedFiles[0];
        const objectUrl = URL.createObjectURL(aiMedia);
        setMedia({ file: aiMedia, url: objectUrl });
      }
    },
    [setMedia]
  );

  const handleDelete = () => {
    setMedia(null);
  };

  useEffect(() => {
    if (generateMediaButton) {
      setMedia(media);
    } else {
      setMedia(null);
    }
  }, [generateMediaButton]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        background: "",
        mt: "5px",
      }}
    >
      <ToggleCustomButton
        generateMediaButton={generateMediaButton}
        setGenerateMediaButtton={setGenerateMediaButtton}
      />
      <ImageSection onDrop={onDrop} generateMediaButton={generateMediaButton} />
      <ReferenceMedia
        generateMediaButton={generateMediaButton}
        handleDelete={handleDelete}
        media={media}
      />
    </Box>
  );
};

export default GenerateMedia;
