import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from './firebaseConfig'

export const uploadImagesToFirebase = async (
  files,
  userId,
  folderName,
  fileNamePrefix
) => {
  console.log('files', files)
  try {
    const downloadURLs = []
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      const fileName = `${fileNamePrefix}_${index + 1}`
      const downloadURL = await uploadImageToFirebase(
        file,
        userId,
        folderName,
        fileName
      )
      downloadURLs.push(downloadURL)
    }
    return downloadURLs
  } catch (error) {
    console.error('Error uploading images to Firebase Storage:', error)
    throw error
  }
}

export const uploadImageToFirebase = async (
  file,
  userId,
  folderName,
  fileName
) => {
  try {
    const storageRef = ref(storage, `${userId}/${folderName}/${fileName}`)
    if (storageRef) {
      try {
        await uploadBytes(storageRef, file)
        console.log('File uploaded successfully')
        const downloadURL = await getDownloadURL(storageRef)
        return downloadURL
      } catch (error) {
        console.log('error', error)
      }
    }
  } catch (error) {
    console.error('Error uploading image to Firebase Storage:', error.message)
    throw error
  }
}
