import {
  Box,
  FormHelperText,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { CustomFilterButton } from '../ReusableComponents/customButtons'
import { AiWhiteIcon } from '../PasswordStartAdornment'
import { useState } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import logoOneImage from './../../Assets/Images/rectangleA.png'
import logoTwoImage from './../../Assets/Images/rectangleB.png'
import logoThreeImage from './../../Assets/Images/rectangleC.png'
import logoFourImage from './../../Assets/Images/rectangleD.png'
import imageOne from './../../Assets/Images/appLogo.webp'
import imageTwo from './../../Assets/Images/rectangleD.png'
import imageThree from './../../Assets/Images/welcom.png'
import imageFour from './../../Assets/Images/share.png'
import one from './../../Assets/Images/facebook.png'
import two from './../../Assets/Images/linkedin.png'
import three from './../../Assets/Images/google.png'
import four from './../../Assets/Images/instagram.png'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import newWorkspaceFormStyle, {
  aiModelStyle,
  commonTextFieldStyle,
  footerText,
  formStyle,
  promptText,
  showImage_Box,
} from './newWorkspaceFormStyle'
import { Loader } from '@infostacks/ui-components'
import {
  aiPromptResolver,
  aiPromptValidator,
} from '../../Validators/authValidator'
import { useForm } from 'react-hook-form'

const AiLogoModel = ({ open, onClose, selectedImage }) => {
  const [showImages, setShowImages] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [isGenerate, setIsGenerate] = useState(false)

  const theme = useTheme()
  const logoArray = [
    logoOneImage,
    logoTwoImage,
    logoThreeImage,
    logoFourImage,
    imageOne,
    imageTwo,
    imageThree,
    imageFour,
    one,
    two,
    three,
    four,
  ]
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = (formData) => {
    console.log('formData', formData)
  }

  const handleGenerateClick = () => {
    if (inputValue.trim()) {
      setLoading(true)
      setTimeout(() => {
        setShowImages(true)
        setImageIndex((prevIndex) =>
          prevIndex + 4 < logoArray.length ? prevIndex + 4 : 0
        )
        setLoading(false)
        setIsGenerate(true)
      }, 2000)
    }
  }

  const handleImageClick = (image) => {
    console.log('image', image)
    selectedImage(image)
    onClose()
  }

  const currentImages = logoArray.slice(imageIndex, imageIndex + 4)

  return (
    <Modal open={open} sx={aiModelStyle.parent_Box}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={formStyle(showImages, theme)}
      >
        <Box sx={aiModelStyle.prompt_Box}>
          <Typography sx={promptText(theme)}>Write Prompt</Typography>
          <IconButton onClick={onClose} sx={{ height: '11px', width: '11px' }}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <div style={aiModelStyle.textfield_Div}>
          <TextField
            sx={commonTextFieldStyle(errors.prompt)}
            fullWidth
            placeholder='Ex. Logo for Digital Agency'
            autoComplete='off'
            onInput={(e) => {
              setInputValue(e.target.value)
            }}
            {...register('prompt', aiPromptValidator)}
          />
          <FormHelperText sx={newWorkspaceFormStyle.helperText}>
            {errors.prompt && touchedFields.prompt
              ? aiPromptResolver[errors.prompt.type]
              : ''}
          </FormHelperText>
        </div>
        {loading ? (
          <Box sx={aiModelStyle.loader_Box}>
            <Loader thickness={7} size={30} />
          </Box>
        ) : (
          showImages && (
            <Box sx={showImage_Box(showImages)}>
              {currentImages.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    ...aiModelStyle.background_img_Box,
                    backgroundImage: `url(${image})`,
                  }}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </Box>
          )
        )}
        <Box sx={aiModelStyle.footer_Box}>
          <span style={aiModelStyle.span_Style}>
            <Typography sx={footerText(theme)}>
              <span style={{ color: 'red' }}>10K</span>
              <span style={{ color: 'green' }}>/1M</span>
            </Typography>
            <ErrorOutlineIcon
              sx={{ color: 'blue', height: '18px', width: '18px' }}
            />
          </span>
          <CustomFilterButton
            icon={<AiWhiteIcon />}
            buttonText={isGenerate ? 'Regenerate' : 'Generate'}
            onClick={handleGenerateClick}
            type='submit'
          />
        </Box>
      </form>
    </Modal>
  )
}

export default AiLogoModel
