import { CloseRounded } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { AiSvgIcon } from "../../PasswordStartAdornment/index";

const AiPostModalHeader = ({ setAiModalOpen }) => {
  const theme = useTheme();
  const closeAiModal = () => {
    setAiModalOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "",
        background: "",
        alignItems: "center",
        width: "100%",
        gap: "4px",
      }}
    >
      <AiSvgIcon />
      <Typography
        sx={{
          fontSize: theme?.typography.mediumText.fontSize,
          fontWeight: theme?.typography.h2.fontWeight,
          fontFamily: theme?.typography.h2.fontFamily,
          color: theme?.palette.primary.dark,
          fontStyle: "normal",
          lineHeight: "30px",
        }}
      >
        AI Assistant
      </Typography>
      <IconButton onClick={closeAiModal} sx={{ ml: "auto" }}>
        <CloseRounded sx={{ color: "gray" }} />
      </IconButton>
    </Box>
  );
};

export default AiPostModalHeader;
