import { Box, TextField } from "@mui/material";
import React from "react";

const AiPromptField = ({ textPrompt, setTextPrompt }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        background: "",
        borderRadius: "10px",
        border: "1px solid #E5E7EB",
        gap: "10px",
        boxSizing: "border-box",
        paddingX: "10px",
        mt: "5px",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          background: "",
        }}
      >
        <TextField
          value={textPrompt}
          onChange={(e) => setTextPrompt(e.target.value)}
          variant="standard"
          placeholder="Type your prompt . . ."
          fullWidth
          sx={{
            background: "#FAFBFC",
            width: "100%",
            mx: "auto",
            overflowY: "auto",
            borderRadius: "10px",
            "& textarea": {
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
          }}
          InputProps={{
            rows: 4,
            multiline: true,
            inputComponent: "textarea",
            disableUnderline: true,
          }}
        />
      </div>
    </Box>
  );
};

export default AiPromptField;
