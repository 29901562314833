import { Box, Typography } from '@mui/material'
import React from 'react'
import rectangleside from '../../Assets/Images/rectangleside.png'
import rectanglesideA from '../../Assets/Images/rectanglesideA.png'
import { ReusableButton } from '../ReusableComponents/customButtons'
import Vector from '../../Assets/Images/vecto.png'
import Clogo from '../../Assets/Images/cLogo.png'
import Clogo1 from '../../Assets/Images/cLogo1.png'
import Clogo2 from '../../Assets/Images/cLogo2.png'
import Clogo3 from '../../Assets/Images/cLogo3.png'
import Clogo4 from '../../Assets/Images/cLogo4.png'
import { introSectionStyles } from './landingPageStyle'

const IntroSection = () => {
  return (
    <Box sx={introSectionStyles.container}>
      <Box sx={introSectionStyles.contentWrapper}>
        <Box sx={introSectionStyles.logoRow}>
          <img src={rectangleside} alt='rectangle' />
          <Typography
            sx={{
              color: ' var(--Text-Text-75, #494949)',
              textAlign: 'center',
              fontFamily: 'Libre Franklin',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
            }}
          >
            Loved by 10+ big and small companies around the world
          </Typography>
          <img src={rectanglesideA} alt='rectangle' />
        </Box>
        <Box sx={introSectionStyles.logosWrapper}>
          {/* Gradient overlays */}
          <Box sx={introSectionStyles.gradientLeft} />
          <Box sx={introSectionStyles.gradientRight} />

          {/* Logos */}
          <img src={Clogo1} alt='pic' height='36px' />
          <img src={Clogo} alt='pic' height='36px' />
          <img src={Clogo1} alt='pic' height='36px' />
          <img src={Clogo2} alt='pic' height='36px' />
          <img src={Clogo3} alt='pic' height='36px' />
          <img src={Clogo4} alt='pic' height='36px' />
        </Box>
      </Box>

      <Box sx={introSectionStyles.buttonWrapper}>
        <ReusableButton
          icon={
            <img
              src={Vector}
              alt='Add'
              style={{ width: '24px', height: '24px' }}
            />
          }
          buttonText={'INTRODUCTION BOLT'}
        />

        <Typography sx={introSectionStyles.introText}>
          <b>We know what’s going on. </b>
          <Typography component='span' sx={introSectionStyles.subText}>
            You need top-notch content to stand out in the competitive world of
            social media, but creating engaging posts backed by in-depth
            analysis and research can be time-consuming and challenging.
          </Typography>

          <Typography sx={introSectionStyles.introText}>
            <b>
              That’s when{' '}
              <span style={introSectionStyles.highlightedText}>AILatics</span>{' '}
              comes in.
            </b>
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}

export default IntroSection
