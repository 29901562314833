import React from 'react'

import Post from './TrendingPost'
import { Box } from '@mui/material'
import { homePage } from './homePageStyle'
import { useTheme } from '@emotion/react'

function home() {
  const theme = useTheme()
  return (
    <Box sx={homePage.main(theme)}>
      <Post />
    </Box>
  )
}

export default home
