import React from 'react'
import { Box, Typography } from '@mui/material'
import applogo from '../../Assets/Images/applogo.png'
import { FooterStyle } from './landingPageStyle'

function Footer() {
  return (
    <Box sx={FooterStyle.container}>
      <Box sx={FooterStyle.header}>
        <Box sx={FooterStyle.logoContainer}>
          <Box sx={FooterStyle.logoBox}>
            <img src={applogo} alt='logo' style={FooterStyle.logoImage} />
          </Box>
          <Typography sx={FooterStyle.appName}>AILatics</Typography>
        </Box>
        <Box sx={FooterStyle.linkContainer}>
          <Typography sx={FooterStyle.link}>How it Works</Typography>
          <Typography sx={FooterStyle.link}>About</Typography>
          <Typography sx={FooterStyle.link}>Pricing</Typography>
          <Typography sx={FooterStyle.link}>Contacts</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
