const createMemberFormStyle = {
  model_Style: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '',
  },
  parent_Box: {
    backgroundColor: 'white',
    borderRadius: '32px',
    boxSizing: 'border-box',
    padding: '40px',
  },
  form_Style: {
    width: '480px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    backgroundColor: '',
  },
  title_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  allFieldsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    flexDirection: 'column',
    backgroundColor: '',
  },
  fieldParentBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    background: '',
  },
  helperText: {
    position: 'absolute',
    bottom: '-16px',
    color: 'red',
    width: '480px',
    background: '',
    textAlign: 'end',
    lineHeight: 'normal',
  },
  error_Box: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    border: '1px solid red',
    borderRadius: '14px',
    padding: '8px',
    boxSizing: 'border-box',
    height: '44px',
    width: '100%',
    background: '#FFEDEB',
  },
  close_Box: {
    display: 'flex',
    height: 'fit-content',
    width: '100%',
    background: '',
    justifyContent: 'space-between',
    alignItems: '',
  },
}
export default createMemberFormStyle
export const labelTextfield = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h5.fontFamily,
  color: theme.palette.black.main,
  width: 'auto',
  marginRight: 'auto',
  lineHeight: '20px',
  background: '',
})
export const commonTextFieldStyle = (error) => ({
  background: '',
  display: 'flex',

  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C2C2C2',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C2C2C2',
    },
    '& .MuiOutlinedInput-input': {
      height: '20px',

      padding: '12px 16px',
      color: '#000',
      fontSize: '16px',
    },
  },
})
export const ContinueButton = (theme) => ({
  width: '100%',
  height: '44px',
  boxSizing: 'border-box',
  padding: '10px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  borderRadius: '14px',
  backgroundColor: theme.palette.primary.dark,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
})
export const heading_Text = (theme) => ({
  fontSize: theme.typography.large.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  color: theme.palette.black.main,
  lineHeight: 'normal',
})

export const desc_Text = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  color: theme.palette.black.light,
  lineHeight: '20px',
})
export const forgetPassword_Typography = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  lineHeight: '20px',
  color: theme.palette.black.main,
  width: 'fit-content',
  textTransform: 'capitalize',
  cursor: 'pointer',
  textAlign: 'start',
  background: '',
  ml: 'auto',
  textDecoration: 'underLine',
})
export const apiErrorText = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  lineHeight: '20px',
  color: 'red',
})
