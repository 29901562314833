import { Button, Typography } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTheme } from "@emotion/react";

export const CustomButton = ({
  onClick,
  background,
  buttonText,
  hoverBackgroundColor,
  padding,
}) => {
  const theme = useTheme();
  return (
    <Button
      startIcon={<AddRounded style={{ height: "20px", width: "20px" }} />}
      onClick={onClick}
      sx={{
        textTransform: "capitalize",
        boxSizing: "border-box",
        height: "36px",
        padding:
          padding ||
          "var(--Components-Button-Gap-Between-SM, 8px) var(--Components-Button-Spacing-X-SM, 16px)",
        color: theme?.palette.customWhite.main,
        alignItems: "center",
        //gap: 'var(--Utilities-Spacing-2, 4px)',
        borderRadius: "8px",
        fontSize: {
          xs: theme.typography.paragraph.fontSize,
          sm: theme.typography.h5.fontSize,
        },
        fontWeight: theme?.typography.h2.fontWeight,
        fontFamily: theme?.typography.h4.fontFamily,
        lineHeight: "20px",
        backgroundColor: background || theme.palette.primary.dark,
        "&:hover": {
          backgroundColor: hoverBackgroundColor || theme.palette.primary.dark,
        },
      }}
    >
      {buttonText || "New Workspace"}
    </Button>
  );
};

export const CustomFilterButton = ({
  onClick,
  background,
  hoverBackgroundColor,
  padding,
  fontWeight,
  icon,
  buttonText,
  disabled,
  type,
  endIcon,
  color,
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type}
      endIcon={endIcon}
      sx={{
        height: "36px",
        textTransform: "capitalize",
        display: "flex",
        boxSizing: "border-box",
        padding:
          padding ||
          "var(--Components-Button-Gap-Between-SM, 8px) var(--Components-Button-Spacing-X-SM, 16px)",
        // gap: { xs: "10px", sm: "10px" },
        alignItems: "center",
        borderRadius: "8px",
        backgroundColor: background || theme.palette.primary.dark,
        "&:hover": {
          backgroundColor: hoverBackgroundColor || theme.palette.primary.dark,
        },
        color: color || theme?.palette.customWhite.main,
        fontFamily: theme?.typography.h4.fontFamily,
        fontWeight: fontWeight || theme?.typography.menuItems.fontWeight,
        lineHeight: "20px",
        fontSize: {
          xs: theme.typography.paragraph.fontSize,
          sm: theme.typography.h5.fontSize,
        },
      }}
    >
      {buttonText || "Filter"}
      {/* {icon && (
        <span style={{ display: "flex", alignItems: "center" }}>{icon}</span>
      )} */}
    </Button>
  );
};

export const ReusableButton = ({
  onClick,
  buttonText,
  icon,
  backgroundColor,
  paddingX,
  paddingY,
  gap,
  color,
  marginLeft,
}) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: backgroundColor || theme?.palette.gray.main,
        textTransform: "capitalize",
        display: "flex",
        boxSizing: "border-box",
        paddingX: paddingX || "10px",
        paddingY: paddingY || "4px",
        gap: gap || "10px",
        alignItems: "center",
        borderRadius: "8px",
        color: color || "black",
        marginLeft: marginLeft || "",
        "&:hover": { backgroundColor: backgroundColor || "#DADAFF" },
      }}
    >
      {icon && <GroupsIcon sx={{ color: "black", fontSize: "large" }} />}
      <Typography
        sx={{
          color: theme.palette.black.main,
          lineHeight: "27px",
          fontSize: theme.typography.h3.fontSize,
          fontWeight: theme?.typography.h2.fontWeight,
          fontFamily: theme?.typography.h4.fontFamily,
        }}
      >
        {buttonText || "Pages"}
      </Typography>
    </Button>
  );
};

export const PostableButtons = ({
  variant = "outlined",
  color = "#000000",
  hoverColor = "#DBEAFE",
  hoverTextColor = "#FFFFFF",
  onClick,
  children,
  isActive,
}) => {
  const buttonStyles = {
    paddingX: "10px",
    paddingY: variant === "contained" ? "10px" : "6px",
    boxSizing: "border-box",
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "999px",
    backgroundColor: isActive ? hoverColor : "",
    color: isActive ? hoverTextColor : color,
    border: isActive ? `1px solid ${hoverTextColor}` : `1px solid ${color}`,
    "&:hover": {
      backgroundColor: variant === "contained" ? hoverColor : hoverColor,
      border: variant === "outlined" ? "1px solid transparent" : "none",
      color: hoverTextColor,
      boxShadow: variant === "outlined" ? `0 0 0 1px ${hoverColor}` : "none",
    },
  };

  return (
    <Button variant={variant} onClick={onClick} sx={buttonStyles}>
      {children}
    </Button>
  );
};

export const SigninAndSignupButton = ({
  onClick,
  variant = "contained",
  buttonText = "Get Started",
  height,
  fontSize,
  paddingX,
  paddingY,
  background,
  borderRadius,
  color,
  border,
}) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      variant={variant}
      sx={{
        fontSize: fontSize || {
          xs: theme.typography.paragraph.fontSize,
          sm: theme.typography.h4.fontSize,
        },
        height: height || { xs: "35px", sm: "45px" },
        paddingX: paddingX || "20px",
        paddingY: paddingY || "12px",
        borderRadius: borderRadius || "8px",
        textTransform: "capitalize",
        fontWeight: theme.typography.h2.fontWeight,
        border: border || `1px solid ${theme.palette.primary.dark}`,
        color: color || theme.palette.customWhite.main,
        backgroundColor: background || theme.palette.primary.dark,
        // '&:hover': {
        //   // backgroundColor: hoverBackgroundColor || theme.palette.primary.dark,
        //   border: `1px solid ${theme.palette.primary.dark}`,
        // },
      }}
    >
      {buttonText}
    </Button>
  );
};
