import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";

import {
  CustomButton,
  CustomFilterButton,
} from "../../../../../ReusableComponents/customButtons";
import {
  headerstyle,
  instagramStyle,
  searchButton,
} from "../../instagramStyle";
import CustomMenu from "../../../../../ReusableComponents/customMenu";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const Header = ({ setInstaModal, filterBtns, setFilterBtns }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    {
      label: "Posted",
      onClick: () => handleOptionSelect("Posted"),
      color: "#115E59",
    },
    {
      label: "Scheduled",
      onClick: () => handleOptionSelect("Scheduled"),
      color: "#1E40AF",
    },
    {
      label: "Drafted",
      onClick: () => handleOptionSelect("Drafted"),
      color: "#6B7280",
    },
    {
      label: "Deleted",
      onClick: () => handleOptionSelect("Deleted"),
      color: "#991B1B",
    },
    {
      label: "Failed",
      onClick: () => handleOptionSelect("Failed"),
      color: "#991B1B",
    },
  ];

  const handleClosebtn = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setFilterBtns(option);
    handleClosebtn();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box sx={instagramStyle.instagramHeader}>
        <Typography sx={headerstyle.main}>Instagram</Typography>
        <Box sx={headerstyle.headerButtons}>
          <TextField
            variant="outlined"
            placeholder="Search posts"
            size="small"
            sx={headerstyle.searchbar}
            InputProps={{
              style: {
                boxSizing: "border-box",
                paddingRight: 0,
                padding: "0 0px",
                marginRight: "-1px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" sx={searchButton}>
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <CustomFilterButton
            buttonText={filterBtns}
            onClick={handleClick}
            endIcon={
              <KeyboardArrowDownOutlined
                sx={{ fontSize: "20px", marginLeft: -0.5 }}
              />
            }
            background={
              filterBtns === "Posted"
                ? "#CCFBF1"
                : filterBtns === "Scheduled"
                ? "#DBEAFE"
                : filterBtns === "Drafted"
                ? "#6B7280"
                : filterBtns === "Deleted"
                ? "#FEE2E2"
                : filterBtns === "Failed"
                ? "#FEE2E2"
                : null
            }
            hoverBackgroundColor={
              filterBtns === "Posted"
                ? "#CCFBF1"
                : filterBtns === "Scheduled"
                ? "#DBEAFE"
                : filterBtns === "Drafted"
                ? "#6B7280"
                : filterBtns === "Deleted"
                ? "#FEE2E2"
                : filterBtns === "Failed"
                ? "#FEE2E2"
                : null
            }
            color={
              filterBtns === "Posted"
                ? "#115E59"
                : filterBtns === "Scheduled"
                ? "#1E40AF"
                : filterBtns === "Drafted"
                ? "#D1D5DB"
                : filterBtns === "Deleted"
                ? "#991B1B"
                : filterBtns === "Failed"
                ? "#991B1B"
                : null
            }
          />
          <CustomButton
            buttonText={"Create Post"}
            onClick={() => setInstaModal(true)}
          />
        </Box>
      </Box>
      <CustomMenu
        menuItems={menuItems}
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClosebtn}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </>
  );
};

export default Header;
