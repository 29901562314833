import makeHeader from '../Headers'

export const handleGenerateLogo = async (userAuth, body) => {
  console.log('body', body)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/openai/generate_logo`
    const response = await fetch(apiUrl, makeHeader('POST', idToken, body))
    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error('Error in handleGenerateLogo', error)
    throw error
  }
}
export const handleGenerateContent = async (userAuth, body) => {
  console.log('body', body)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/openai/generate_content`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleGenerateContent', error)
    throw error
  }
}
