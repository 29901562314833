import { ExpandMoreOutlined, GridView } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTheme } from '@emotion/react'
import UserContext from '../../Context/userContext'
import {
  accordian_Details_Map,
  accordian_Details_Style,
  accordian_Style,
  accordianSummry_Style,
  expandMoreOutlined_Style,
  main_AccordianBox,
  platform_img_Style,
  platform_name_Style,
  platform_TextStyles,
  sidebarOptionStyles,
} from './sidebarStyle'

const PlatformsAccordion = ({
  allSpaces,
  navigation,
  sessId,
  platforms,
  activeSpace,
  drawer,
}) => {
  const { setActiveSidebarOption, activeSidebarOption } =
    useContext(UserContext)
  const handlePlatformOption = (e, ind) => {
    if (e === 'google') {
      setActiveSidebarOption('/dashboard/google')
      navigation('/dashboard/google')
    }
    if (e === 'facebook') {
      setActiveSidebarOption(`/dashboard/facebook/${sessId}`)
      navigation(`/dashboard/facebook/${sessId}`)
    }
    if (e === 'instagram') {
      setActiveSidebarOption(`/dashboard/instagram-profiles/${sessId}`)
      navigation(`/dashboard/instagram-profiles/${sessId}`)
    }
    setActiveSidebarOption(e)
  }
  const [expand, setExpand] = useState(false)

  const theme = useTheme()

  const hasSpaces = allSpaces.length > 0
  const integratedPlatform = activeSpace[0]?.platforms?.length > 0
  const canAddPlatform =
    activeSpace[0]?.Permissions?.workSpace?.['Add Platform'] === true
  if (canAddPlatform === false) {
    platforms = platforms?.filter((v) =>
      activeSpace[0]?.platforms?.some((e) => e.name === v.name)
    )
  }
  return (
    <Box sx={main_AccordianBox(hasSpaces, canAddPlatform, integratedPlatform)}>
      <Accordion disableGutters sx={accordian_Style}>
        <AccordionSummary
          onClick={() => setExpand(true)}
          sx={accordianSummry_Style(expand, theme)}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          <GridView sx={{ height: '26px', width: '26px' }} />
          <Typography sx={platform_TextStyles(drawer, theme)}>
            Platforms
          </Typography>
          <ExpandMoreOutlined sx={expandMoreOutlined_Style} />
        </AccordionSummary>

        <AccordionDetails sx={accordian_Details_Style}>
          {platforms.map((val, ind) => (
            <Box
              key={ind}
              onClick={() => handlePlatformOption(val.name)}
              sx={accordian_Details_Map(activeSidebarOption, val, theme)}
            >
              <img src={val.src} alt='' style={platform_img_Style()} />
              <Typography sx={platform_name_Style(theme)}>
                {val.name}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
export default PlatformsAccordion
