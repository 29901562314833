import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Typography,
  Skeleton,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Instaicon from '../../../../Assets/Images/instagram.png'
import Fbicon from '../../../../Assets/Images/facebook.png'
import backgroundImg from '../../../../Assets/Images/scard.png'
import states from '../../../../Assets/Images/states.png'
import like from '../../../../Assets/Images/like1.png'
import share from '../../../../Assets/Images/share1.png'
import message from '../../../../Assets/Images/message.png'
import AiSearch from '../AiSearch'
import { trendingPostStyle } from '../homePageStyle'
import { useTheme } from '@emotion/react'
// Post data array
const postData = [
  {
    id: 1,
    message: 'Ever felt frustrated with a website or app?',
    followers: '540k',
    trendInfo: '500k people follow this trend.',
    socialIcons: [
      { src: Instaicon, alt: 'Instagram' },
      { src: Fbicon, alt: 'Facebook' },
    ],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
  {
    id: 2,
    message: 'Ever felt frustrated with a website or app?',
    followers: '540k',
    trendInfo: '500k people follow this trend.',
    socialIcons: [
      { src: Instaicon, alt: 'Instagram' },
      { src: Fbicon, alt: 'Facebook' },
    ],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
  {
    id: 3,
    message: 'Ever felt frustrated with a website or app?',
    followers: '540k',
    trendInfo: '500k people follow this trend.',
    socialIcons: [
      { src: Instaicon, alt: 'Instagram' },
      { src: Fbicon, alt: 'Facebook' },
    ],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
  {
    id: 4,
    message: 'Ever felt frustrated with a website or app?',
    followers: '540k',
    trendInfo: '500k people follow this trend.',
    socialIcons: [
      { src: Instaicon, alt: 'Instagram' },
      { src: Fbicon, alt: 'Facebook' },
    ],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
]
const PostList = () => {
  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [activePostId, setActivePostId] = useState(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const handleMouseEnter = (event, postId) => {
    setAnchorEl(event.currentTarget)
    setActivePostId(postId)
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
    setActivePostId(null)
  }
  const theme = useTheme()

  return (
    <Box>
      <AiSearch />
      <Divider sx={trendingPostStyle.divider_Style} />
      <Box sx={trendingPostStyle.main}>
        {loading
          ? Array.from(new Array(3)).map((_, index) => (
              <Box key={index} sx={trendingPostStyle.postCard}>
                <Skeleton variant='rectangular' width='40%' height={30} />
                <Skeleton variant='rectangular' width='80%' height={20} />
                <Skeleton variant='text' width='60%' height={40} />
                <Skeleton variant='circular' width={40} height={40} />
              </Box>
            ))
          : postData.map((post) => (
              <Box
                key={post.id}
                sx={{
                  ...trendingPostStyle.post,
                  background: `linear-gradient(180deg, #000 -14.42%, rgba(0, 0, 0, 0.00) 40.95%, #000 100.86%), url(${backgroundImg}) lightgray 50% / cover no-repeat`,
                }}
              >
                <Box sx={trendingPostStyle.postContent}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {post.socialIcons.map((icon, index) => (
                      <Box key={index} sx={trendingPostStyle.socialIcons}>
                        <img
                          src={icon.src}
                          alt={icon.alt}
                          style={{ width: '16px', height: '16px' }}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box
                    onMouseEnter={(event) => handleMouseEnter(event, post.id)}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      '&:hover.menuitems': {
                        display: 'flex',
                      },
                    }}
                  >
                    <Box key={post.id} sx={trendingPostStyle.state}>
                      <img
                        src={states}
                        width='16px'
                        height='16px'
                        alt='states'
                      />
                      <Typography sx={trendingPostStyle.followerData(theme)}>
                        {post.followers}
                      </Typography>
                    </Box>{' '}
                    <Menu
                      autoFocus={false}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && activePostId === post.id}
                      onClose={handleMouseLeave}
                      className='menuitems'
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: trendingPostStyle.menuStyle,
                      }}
                    >
                      {post.followerList.map((follower, index) => (
                        <MenuItem key={index}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {React.isValidElement(follower.icon) ? (
                              <Box sx={{ marginRight: '8px' }}>
                                {follower.icon}
                              </Box>
                            ) : (
                              <img
                                src={follower.icon}
                                alt={`${follower.name} icon`}
                                style={trendingPostStyle.menuItemsImg}
                              />
                            )}
                            <Typography sx={trendingPostStyle.menuitems(theme)}>
                              {follower.name}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>{' '}
                </Box>
                <Box sx={{ width: '216px' }}>
                  <Typography sx={trendingPostStyle.detail(theme)}>
                    {post.trendInfo}
                  </Typography>
                  <Typography sx={trendingPostStyle.description(theme)}>
                    {post.message}
                  </Typography>
                </Box>
                <MoreVertIcon sx={trendingPostStyle.vertIcon} />
              </Box>
            ))}
      </Box>
    </Box>
  )
}

export default PostList
