import React from 'react'
import { Box, styled } from '@mui/material'

// Styled component for the loader container
const LoaderWrapper = styled(Box)(({ theme }) => ({
  '--size': '32px',
  '--duration': '800ms',
  height: 'calc(var(--size) * 2)',
  width: 'calc(var(--size) * 3)',
  position: 'relative',
  transformStyle: 'preserve-3d',
  transformOrigin: '50% 50%',
  marginTop: '420px',
  marginLeft: '45%',
  transform: 'rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px)',
}))

// Styled component for each box inside the loader
const BoxContainer = styled(Box)({
  width: 'var(--size)',
  height: 'var(--size)',
  position: 'absolute',
  transformStyle: 'preserve-3d',
  top: 0,
  left: 0,
})

const BoxDiv = styled(Box)(
  ({ bgcolor, top, left, right, bottom, rotateY, rotateX, translateZ }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: bgcolor,
    top: top || 'auto',
    right: right || 'auto',
    bottom: bottom || 'auto',
    left: left || 'auto',
    transform: `rotateY(${rotateY || '0deg'}) rotateX(${
      rotateX || '0deg'
    }) translateZ(${translateZ || 'calc(var(--size) / 2)'})`,
  })
)

// Styled animations for each box
const animatedStyles = {
  box1: {
    animation: 'box1 var(--duration) linear infinite',
  },
  box2: {
    animation: 'box2 var(--duration) linear infinite',
  },
  box3: {
    animation: 'box3 var(--duration) linear infinite',
  },
  box4: {
    animation: 'box4 var(--duration) linear infinite',
  },
}

const CustomStackLoader = () => {
  return (
    <LoaderWrapper>
      {[1, 2, 3, 4].map((_, idx) => (
        <BoxContainer key={idx} sx={animatedStyles[`box${idx + 1}`]}>
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: '#5C8DF6',
              top: '0',
              left: '0',
              bottom: 'auto',
              right: 'auto',
              transform: `rotateY(${'0deg'}) rotateX(${'0deg'}) translateZ(${'calc(var(--size) / 2)'})`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: '#145af2',
              top: 'auto',
              right: '0',
              left: 'auto',
              bottom: 'auto',
              transform: `rotateY(${'90deg'}) rotateX(${'0deg'}) translateZ(${'calc(var(--size) / 2)'})`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: '#447cf5',
              top: 'auto',
              right: 'auto',
              left: 'auto',
              bottom: 'auto',
              transform: `rotateY(${'0deg'}) rotateX(${'-90deg'}) translateZ(${'calc(var(--size) / 2)'})`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: '#DBE3F4',
              top: '0',
              right: 'auto',
              left: '0',
              bottom: 'auto',
              transform: `rotateY(${'0deg'}) rotateX(${'0deg'}) translateZ(${'calc(var(--size) * 3 * -1)'})`,
            }}
          />
        </BoxContainer>
      ))}
    </LoaderWrapper>
  )
}

export default CustomStackLoader
