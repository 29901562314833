import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import {
  emailResolver,
  fullnameResolver,
  fullnameValidator,
  passwordResolver,
  passwordValidator,
  phoneResolver,
  phoneValidator,
} from '../../../Validators/authValidator'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useTheme } from '@emotion/react'
import { useContext, useState } from 'react'

import createMemberFormStyle, {
  commonTextFieldStyle,
  ContinueButton,
  labelTextfield,
} from './createMemberFormStyle'
import { Loader } from '@infostacks/ui-components'
import { useLocation } from 'react-router-dom'
import UserContext from '../../../Context/userContext'
const CreateMemberForm = ({
  open,
  invitedWorkspaceData,
  onSubmit,
  btnLoader,
}) => {
  const { userAuth, setCurrentUser, currentUser, sendEmailLinkContext } =
    useContext(UserContext)
  console.log('invitedWorkspaceData', invitedWorkspaceData)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const address = useLocation()
  // console.log('address',address.pathname+address.search);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm()
  const password = watch('password')

  const theme = useTheme()
  return (
    <Modal open={open} sx={createMemberFormStyle.model_Style}>
      <Box sx={createMemberFormStyle.parent_Box}>
        <form
          className='signupForm'
          onSubmit={handleSubmit(onSubmit)}
          style={createMemberFormStyle.form_Style}
        >
          <Box sx={createMemberFormStyle.title_Box}>
            <Typography sx={createMemberFormStyle.heading_Text}>
              Create an account
            </Typography>
            <Typography sx={createMemberFormStyle.desc_Text}>
              Please provide your name and email
            </Typography>
          </Box>
          <Box sx={createMemberFormStyle.allFieldsWrapper}>
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Name
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  variant='outlined'
                  placeholder='Enter full name'
                  fullWidth
                  autoComplete='off'
                  {...register('fullname', fullnameValidator)}
                  sx={commonTextFieldStyle(errors.fullname)}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.fullname && touchedFields.fullname
                    ? fullnameResolver[errors.fullname.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Email*
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  autoComplete='off'
                  variant='outlined'
                  placeholder='Enter your email'
                  value={invitedWorkspaceData?.email}
                  disabled
                  sx={commonTextFieldStyle(errors.email)}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.email && touchedFields.email
                    ? emailResolver[errors.email.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            {/* <Box sx={createMemberFormStyle.fieldParentBox}>
                <label className='textfield-label' style={labelTextfield(theme)}>
                  Company
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    sx={commonTextFieldStyle(errors.company)}
                    fullWidth
                    variant='outlined'
                    placeholder='Company Name'
                    disabled
                    value={invitedWorkspaceData?.companyName}
                    autoComplete='off'
                  />
                  <FormHelperText sx={createMemberFormStyle.helperText}>
                    {errors.company && touchedFields.company
                      ? companyResolver[errors.company?.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box> */}
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Phone Number
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  {...register('phoneNumber', phoneValidator)}
                  sx={commonTextFieldStyle(errors.phoneNumber)}
                  fullWidth
                  // value={phoneNumber}
                  type='tel'
                  autoComplete='off'
                  // onChange={(e) => setPhoneNumber(e.target.value)}
                  variant='outlined'
                  placeholder='Phone Number'
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.phoneNumber && touchedFields.phoneNumber
                    ? phoneResolver[errors.phoneNumber?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Password
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.password)}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  variant='outlined'
                  placeholder='Enter your password'
                  autoComplete='off'
                  {...register('password', passwordValidator)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                        >
                          {showPassword ? (
                            <Visibility fontSize='small' />
                          ) : (
                            <VisibilityOff fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.password && touchedFields.password
                    ? passwordResolver[errors.password?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Confirm password
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.confirmPassword)}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  variant='outlined'
                  placeholder='Confirm password'
                  autoComplete='off'
                  {...register('confirmPassword', {
                    required: 'Confirm Password is required',
                    validate: (value) =>
                      value === password || 'Passwords do not match',
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                        >
                          {showPassword ? (
                            <Visibility fontSize='small' />
                          ) : (
                            <VisibilityOff fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.confirmPassword && touchedFields.confirmPassword
                    ? errors.confirmPassword.message
                    : ''}
                </FormHelperText>
              </div>
            </Box>
          </Box>
          <Button type='submit' variant='contained' sx={ContinueButton(theme)}>
            {btnLoader ? <Loader thickness={7} size={30} /> : 'Continue'}
          </Button>
        </form>
      </Box>
    </Modal>
  )
}
export default CreateMemberForm
