import { GridView } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { platformLogo, platformsBox } from '../sidebarStyle'
import { useTheme } from '@emotion/react'

const SidebarPlatforms = ({ allSpaces, platformMenuOpen }) => {
  const theme = useTheme()

  return (
    <Box onClick={platformMenuOpen} sx={platformsBox(theme, allSpaces)}>
      <GridView sx={platformLogo()} />
    </Box>
  )
}

export default SidebarPlatforms
