import { useEffect, useState } from 'react'
import UserContext from './userContext'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  EmailVerification,
  emailVerification,
  forgotPassword,
  handleFacebookLogin,
  handleGoogleLogin,
  logOut,
  loginInAuth,
  signUpAuth,
} from '../Firebase/auths'
import { onAuthStateChanged, updateProfile } from 'firebase/auth'
import { auth } from '../Firebase/firebaseConfig'
import {
  emailVerificationAPI,
  forgotPasswordAPI,
  loginAPI,
  signOutAPI,
  signupAPI,
} from '../API/AuthAPIs'
import makeHeader from '../API/Headers'

const UserContextProvider = () => {
  const navigate = useNavigate()
  const [userAuth, setUserAuth] = useState()
  const [currentUser, setCurrentUser] = useState()
  const [spacesArray, setSpacesArray] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSkelton, setIsSkelton] = useState(false)
  const [page, setPage] = useState('workspace')
  const [disabled, setDisabled] = useState(true)
  const [showTimer, setShowTimer] = useState(true)
  const [favourite, setFavourite] = useState(false)
  const [activeSpace, setActiveSpace] = useState()
  const [platforms, setPlatforms] = useState(null)
  const [specificPage, setSpecificPages] = useState()
  const [postData, setPostData] = useState()
  const [buttonStatus, setButtonStatus] = useState()
  const [manageRole, setManageRole] = useState()
  const [buttonState, setButtonState] = useState()
  const [stateCheck, setStateCheck] = useState(false)
  const [stackRoutes, setStackRoutes] = useState(['/dashboard'])
  const [activeSidebarOption, setActiveSidebarOption] = useState('/dashboard')
  const [aiModalOpen, setAiModalOpen] = useState(false)


  console.log('currentUser', currentUser)

  const [timer, setTimer] = useState(() => {
    const storedTimer = localStorage.getItem('timer')
    return storedTimer ? parseInt(storedTimer, 10) : 120
  })

  const addRouteToStack = (route) => {
    console.log('route', route)
    if (stackRoutes.includes(route)) return
    setStackRoutes([...stackRoutes, route])
  }
  const getLatestRoute = () => {
    let arr = [...stackRoutes]
    const route = arr.pop() || '/dashboard'
    setStackRoutes(arr)
    return route
  }

  useEffect(() => {
    let intervalId

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          localStorage.setItem('timer', prevTimer - 1)
          return prevTimer - 1
        })
      }, 1000)
    } else {
      setDisabled(false)
      setShowTimer(false)
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [timer])

  const signUpContext = async (
    username,
    email,
    password,
    phoneNumber,
    company,
    country
  ) => {
    let signResponse = { error: 0, message: 'Successful' }
    console.log('new country', country)
    await signUpAuth(email, password)
      .then(async (UserCredentials) => {
        console.log('UserCredentials', UserCredentials)
        await updateProfile(auth.currentUser, {
          displayName: username,
        })
        await signupAPI(UserCredentials, { phoneNumber, company, country })
          .then(async (response) => {
            setCurrentUser(response)
            window.localStorage.setItem('user', JSON.stringify(response))
            await sendEmailLinkContext()
          })
          .catch((error) => {
            signResponse.error = 1
            signResponse.message = error.message
          })
      })
      .catch((error) => {
        signResponse.error = 1
        signResponse.message = error.message
      })
    return signResponse
  }

  const checkEmailExistContext = async (body) => {
    console.log('body', body)
    try {
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/auth/check_email`

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      })
      if (response.status === 200) {
        const responseData = await response.json()
        return responseData
      }
      const responseData = response.text()

      return responseData // Return the data here
    } catch (error) {
      console.error('Error in checkEmailExistContext:', error)
      throw error
    }
  }

  const updateUserContext = async (token, body, Id) => {
    const idToken = await token?.getIdToken(true)
    let myResponse = { error: 1, message: '', user: null }
    await fetch(
      `${process.env.REACT_APP_ANALYTICS_API}/auth/update-user/${Id}`,
      makeHeader('PUT', idToken, body)
    )
      .then((response) => response.json())
      .then((user) => {
        console.log('data', user)
        setCurrentUser(user.user)
        window.localStorage.setItem('user', JSON.stringify(user.user))
        myResponse.error = 0
        myResponse.message = user.message
        myResponse.user = user.user
      })
      .catch((error) => {
        myResponse.error = 1
        myResponse.message = error.message
      })
    return myResponse
  }

  const updateMemberToUserContext = async (token, body) => {
    const idToken = await token?.getIdToken(true)
    let myResponse = { error: 1, message: '', user: null }
    await fetch(
      `${process.env.REACT_APP_ANALYTICS_API}/member/make_user`,
      makeHeader('PUT', idToken, body)
    )
      .then((response) => response.json())
      .then((user) => {
        console.log('data', user)
        setCurrentUser(user.user)
        window.localStorage.setItem('user', JSON.stringify(user.user))
        myResponse.error = 0
        myResponse.message = user.message
        myResponse.user = user.user
      })
      .catch((error) => {
        myResponse.error = 1
        myResponse.message = error.message
      })
    return myResponse
  }

  const logInContext = async (email, password) => {
    let myResponse = { error: 0, message: 'Successfully' }
    try {
      const userCredentials = await loginInAuth(email, password)
      const response = await loginAPI(userCredentials)
      // console.log('responseAfterLogin', response)
      setCurrentUser(response)
      window.localStorage.setItem('user', JSON.stringify(response))
      if (userCredentials?.user?.emailVerified) {
        if (response?.accountType === 'member') {
          if (response?.workspacesCount > 1) {
            navigate('/choose-workspace')
          }
          if (response?.workspacesCount <= 1) {
            navigate('/dashboard')
          }
        }
        if (response?.accountType === 'user') {
          if (response?.Subscription?.status === 'active') {
            if (response?.workspacesCount > 1) {
              navigate('/choose-workspace')
            }
            if (response?.workspacesCount < 1) {
              navigate('/dashboard')
            }
          }
          if (response?.Subscription?.status !== 'active') {
            navigate('choose-plan')
          }
        }
      } else {
        sendEmailLinkContext()
        navigate('/welcome')
      }
    } catch (error) {
      myResponse.error = 1
      myResponse.message = error.message
    }
    return myResponse
  }

  const forgotPasswordContext = async (email) => {
    let myResponse = {}
    try {
      await forgotPasswordAPI(email)
        .then(async (response) => {
          if (response.error) throw new Error(response.message)

          await forgotPassword(email)
            .then(() => {
              myResponse = response
            })
            .catch((error) => {
              myResponse.error = 1
              myResponse.message = error.message
            })
        })
        .catch((error) => {
          // throw new Error(error)
          myResponse.error = 1
          myResponse.message = error.message
        })
    } catch (error) {
      myResponse = { error: 1, message: error.message }
    }
    return myResponse
  }

  const signOutContext = async () => {
    await auth.currentUser.getIdToken(true).then(async (token) => {
      await signOutAPI(token)
        .then(async (response) => {
          console.log('logout response', response)
          if (response.error) throw new Error(response.message)
          await logOut()
          navigate('/')
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  const signout = async () => {
    await auth.currentUser.getIdToken(true).then(async (token) => {
      await signOutAPI(token)
        .then(async (response) => {
          if (response.error) throw new Error(response.message)
          await logOut()
          navigate('/')
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  const googleAuthorizationContext = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ANALYTICS_API}/google/auth/url`
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      window.location.assign(data?.url)
    } catch (error) {
      console.error('Error fetching Google Auth URL:', error.message)
    }
  }

  const facebookAuthorizationContext = async () => {
    var myHeaders = new Headers()
    myHeaders.append('ngrok-skip-browser-warning', true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ANALYTICS_API}/facebook/auth/url`,
        {
          method: 'GET',
          headers: myHeaders,
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      window.location.assign(data?.url)
    } catch (error) {
      console.error('Error fetching facebook Auth URL:', error.message)
    }
  }

  const instagramAuthorizationContext = async () => {
    var myHeaders = new Headers()
    myHeaders.append('ngrok-skip-browser-warning', true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ANALYTICS_API}/instagram/auth/url`,
        {
          method: 'GET',
          headers: myHeaders,
        }
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      window.location.assign(data?.url)
    } catch (error) {
      console.error('Error fetching instagram Auth URL:', error.message)
    }
  }

  const sendEmailLinkContext = async () => {
    if (userAuth?.emailVerified)
      return { error: 0, message: 'Already Verified' }
    let myResponse = {}
    try {
      await auth.currentUser.getIdToken(true).then(async (token) => {
        await emailVerificationAPI(token)
          .then(async (response) => {
            if (response.error) throw new Error(response.message)

            await emailVerification(auth.currentUser, getLatestRoute)
              .then(() => {
                myResponse = response
              })
              .catch((error) => {
                myResponse.error = 1
                myResponse.message = error.message
              })
          })
          .catch((error) => {
            // throw new Error(error)
            myResponse.error = 1
            myResponse.message = error.message
          })
      })
    } catch (error) {
      myResponse = { error: 1, message: error.message }
    }
    return myResponse
  }

  const logInWithGoogleContext = async () => {
    let myResponse = { error: 0, message: 'Successfully' }
    try {
      const result = await handleGoogleLogin()
      console.log('User Info: ', result)
      const response = await loginAPI(result)
      console.log('response After google Login', response)
      setCurrentUser(response)
      window.localStorage.setItem('user', JSON.stringify(response))

      if (result?.user?.emailVerified) {
        if (response?.Subscription?.status === 'active') {
          if (response?.accountType === 'user') {
            if (response?.workspacesCount > 1) {
              navigate('/choose-workspace')
            } else navigate('/dashboard')
          }
        }
        if (response?.Subscription?.status !== 'active') {
          if (response?.accountType === 'member') {
            if (response?.workspacesCount > 1) {
              navigate('/choose-workspace')
            } else navigate('/dashboard')
          }
          if (response?.accountType === 'user') {
            navigate('/choose-plan')
          }
        }
      }
      // if (
      //   result.user.emailVerified &&
      //   response?.Subscription.status === 'active' &&
      //   response.workspacesCount <= 1
      // ) {
      //   navigate('/dashboard')
      // } else if (
      //   result.user.emailVerified &&
      //   response?.Subscription.status === 'active' &&
      //   response.workspacesCount > 1
      // ) {
      //   navigate('/choose-workspace')
      // } else if (
      //   result.user.emailVerified &&
      //   (!response?.Subscription || response.Subscription.status !== 'active') && // Handle null or inactive Subscription
      //   response.accountType !== 'member'
      // ) {
      //   navigate('/choose-plan')
      // }  else if (
      //   result.user.emailVerified &&
      //   (!response?.Subscription || response.Subscription.status !== 'active') &&
      //   response.accountType === 'member'
      // )
      //   if (response.workspacesCount <= 1) {
      //     // Check workspace count only for members with inactive subscription
      //     navigate('/dashboard')
      //   } else {
      //     navigate('/choose-workspace')
      //   }
    } catch (error) {
      myResponse.error = 1
      myResponse.message = error.message
    }
    return myResponse
  }

  const logInWithFacebookContext = async () => {
    let myResponse = { error: 0, message: 'Successfully' }
    try {
      const result = await handleFacebookLogin()
      console.log('User Info: ', result)
      const response = await loginAPI(result)
      console.log('response After google Login', response)
      setCurrentUser(response)
      window.localStorage.setItem('user', JSON.stringify(response))
      if (result.user.emailVerified) {
        navigate('/dashboard')
      } else {
        sendEmailLinkContext()
        navigate('/welcome')
      }
    } catch (error) {
      myResponse.error = 1
      myResponse.message = error.message
    }
    return myResponse
  }

  const initializeUser = (user) => {
    setUserAuth(user)
    setCurrentUser(JSON.parse(window.localStorage.getItem('user')))
    const storedWorkspaces = JSON.parse(
      window.localStorage.getItem('workspaces')
    )
    setSpacesArray(Array.isArray(storedWorkspaces) ? storedWorkspaces : [])
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        initializeUser(user)
        console.log('user', user)
      } else {
        // initializeUser(null)
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('workspaces')
        window.localStorage.removeItem('spaceId')
        window.sessionStorage.removeItem('spaceId')
        window.sessionStorage.removeItem('action')
        window.localStorage.removeItem('recentSpace')
        window.localStorage.removeItem('manageRole')
        window.localStorage.removeItem('buttonState')
        window.localStorage.removeItem('DashBoardSpaceId')
        window.sessionStorage.removeItem('platformForIntegrate')
        initializeUser(null)
      }
      setIsLoading(false)
    })
    return () => unsubscribe()
  }, [])

  const value = {
    isSkelton,
    setIsSkelton,
    isLoading,
    setIsLoading,
    userAuth,
    currentUser,
    setCurrentUser,
    spacesArray,
    setSpacesArray,
    signUpContext,
    sendEmailLinkContext,
    logInContext,
    forgotPasswordContext,
    signOutContext,
    page,
    setPage,
    updateUserContext,
    disabled,
    setDisabled,
    showTimer,
    setShowTimer,
    timer,
    setTimer,
    favourite,
    setFavourite,
    setActiveSpace,
    activeSpace,
    googleAuthorizationContext,
    facebookAuthorizationContext,
    platforms,
    setPlatforms,
    setSpecificPages,
    specificPage,
    setPostData,
    postData,
    buttonStatus,
    setButtonStatus,
    manageRole,
    setManageRole,
    buttonState,
    setButtonState,
    stackRoutes,
    setStackRoutes,
    addRouteToStack,
    getLatestRoute,
    stateCheck,
    setStateCheck,
    activeSidebarOption,
    setActiveSidebarOption,
    checkEmailExistContext,
    logInWithGoogleContext,
    logInWithFacebookContext,
    updateMemberToUserContext,
    instagramAuthorizationContext,
    aiModalOpen,
    setAiModalOpen,
  }
  return (
    <UserContext.Provider value={value}>
      <Outlet />
    </UserContext.Provider>
  )
}
export default UserContextProvider
