import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import UserContext from '../../../../../Context/userContext'
import { ArrowDropDown } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import CustomMenu from '../../../../ReusableComponents/customMenu'

const EditDraftedPostFooter = ({
  handleOptionSelect,
  setTimeModal,
  formatDate,
  scheduleTime,
  inputStr,
  images,
  anchorEl,
  handleClosebtn,
  handleClick,
}) => {
  const theme = useTheme()
  const { postData } = useContext(UserContext)
  const menuItems = [
    {
      label: 'Publish',
      onClick: () => handleOptionSelect('Publish'),
    },
    {
      label: 'Schedule',
      onClick: () => handleOptionSelect('Schedule'),
    },
  ]
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '95%',
          background: '',
          alignItems: 'center',
          justifyContent: 'start',
          height: '50px',
          gap: '20px',
          visibility: inputStr || images.length > 0 ? 'visible' : 'hidden',
        }}
      >
        <Box
          sx={{
            background: '',
            display: scheduleTime ? 'flex' : 'none',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: theme?.typography.h4.fontFamily,
              fontSize: theme.typography.h5.fontSize,
              fontWeight: theme?.typography.h5.fontWeight,
              lineHeight: '18px',
              color: theme.palette.black.light,
            }}
          >
            {' '}
            Schedule date:
          </Typography>
          <Typography
            sx={{
              fontFamily: theme?.typography.h4.fontFamily,
              fontSize: theme.typography.h5.fontSize,
              fontWeight: theme?.typography.h5.fontWeight,
              lineHeight: '18px',
              color: theme.palette.black.main,
            }}
          >
            {formatDate(scheduleTime)}
          </Typography>
          <Button
            variant='outlined'
            onClick={() => setTimeModal(true)}
            sx={{
              textTransform: 'capitalize',
              color: theme.palette.primary.dark,
              ml: '20px',
              padding: '0px',
              paddingX: '12px',
              height: '35px',
              boxSizing: 'border-box',
              fontFamily: theme?.typography.h4.fontFamily,
              fontWeight: theme?.typography.h2.fontWeight,
              fontSize: theme.typography.h5.fontSize,
              lineHeight: 'normal',
              letterSpacing: '0.06px',
              backgroundColor: 'white',
              border: `1px solid ${theme.palette.primary.dark}`,
              ':hover': {
                border: `1px solid ${theme.palette.primary.dark}`,
                backgroundColor: 'transparent',
              },
            }}
          >
            Edit Schedule
          </Button>
        </Box>
        <Button
          type='submit'
          onClick={() => handleOptionSelect('DraftToSave')}
          variant='contained'
          sx={{
            textTransform: 'capitalize',
            display: postData.status === 'drafted' ? 'flex' : 'none',
            borderRadius: '8px',
            ml: 'auto',
            padding: '0px',
            paddingX: '12px',
            height: '35px',
            boxSizing: 'border-box',
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h2.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            background: theme.palette.primary.dark,
            lineHeight: 'normal',
          }}
        >
          Save edit
        </Button>
        <Box
          sx={{
            background: theme.palette.primary.dark,
            alignItems: 'center',
            gap: '0px',
            borderRadius: '8px',
            display: 'flex',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          <Button
            onClick={() => handleOptionSelect('Publish')}
            sx={{
              textTransform: 'capitalize',
              boxSizing: 'border-box',
              padding: '0px',
              height: '35px',
              paddingX: '12px',
              borderRadius: '8px',
              fontFamily: theme?.typography.h4.fontFamily,
              fontWeight: theme?.typography.h2.fontWeight,
              fontSize: theme.typography.h5.fontSize,
              background: theme.palette.primary.dark,
              lineHeight: 'normal',
              color: theme.palette.customWhite.main,
            }}
          >
            Publish
          </Button>
          <Divider
            orientation='vertical'
            sx={{ height: '20px', backgroundColor: 'white' }}
          />
          <IconButton onClick={handleClick}>
            <ArrowDropDown sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Box>
      <CustomMenu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClosebtn}
        menuItems={menuItems}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  )
}

export default EditDraftedPostFooter
