const signupPageStyles = {
  main_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '',
    boxSizing: 'border-box',
    paddingY: '20px',
  },
  formMainBox: {
    width: { xs: '300px', sm: '450px', lg: '400px' },
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '',
    gap: { xs: '35px', sm: '69px' },
  },
  logo_MainBox: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    background: '',
    mr: 'auto',
    cursor: 'pointer',
  },
  latics_Text_Style: {
    color: '#000',
    fontFamily: 'Libre Franklin',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  stepOne_Form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '',
  },
  title_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  field_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    mt: '12px',
  },
  devider_MainBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    mt: '16px',
  },
  devider_Style: {
    flexGrow: 1,
    backgroundColor: '#C6C6CE',
  },

  countinue_MainBox: {
    display: 'flex',
    width: '100%',
    background: '',
    alignItems: 'center',
    gap: '4px',
    mt: '16px',
  },

  select_divStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  welcome_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '',
  },
  // welcome_textStyle: {
  //   color: '#000',
  //   fontFamily: 'Libre Franklin',
  //   fontSize: '40px',
  //   fontWeight: 600,
  //   letterSpacing: '-1.6px',
  // },
  // paragraph_textStyle: {
  //   color: '#494949',
  //   fontFamily: 'Libre Franklin',
  //   fontSize: '16px',
  //   fontWeight: 500,
  //   fontStyle: 'normal',
  //   textAlign: 'center',
  // },
  helperText: {
    position: 'absolute',
    bottom: '-16px',
    color: 'red',
    width: '100%',
    background: '',
    textAlign: 'end',
    lineHeight: 'normal',
  },
  socialBtn_Box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    mt: '16px',
    background: '',
  },

  image_Style: { height: '20px', width: '20px' },
  swiper_Style: {
    width: '426px',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  swiperSlide_Style: {
    backgroundColor: '',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 'fit-content',
  },
  swiperSlide_Img: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    borderRadius: '32px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}
export default signupPageStyles
export const parent_Box = (theme) => ({
  backgroundColor: theme.palette.customWhite.main,
  width: '100%',
  display: 'flex',
  height: { xs: 'fit-content', sm: '100vh' },
  justifyContent: 'center',
  boxSizing: 'border-box',
  padding: '20px',
})
export const latics_Text_Style = (theme) => ({
  lineHeight: 'normal',
  fontSize: theme.typography.headingThree.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.black.main,
})
export const commonSelectStyle = (error) => ({
  background: '',
  borderRadius: '14px',
  height: '44px',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : '#241BFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '& .MuiSelect-select': {
    // height: "15px",
    color: '', // Ensure the color is set correctly or remove it
  },
  '& .MuiOutlinedInput-input': {
    // height: '20px' ,

    padding: '12px 16px',
    color: '#000',
    fontSize: '16px',
  },
})
export const ContinueBtn = (theme) => ({
  width: '100%',
  height: '44px',
  boxSizing: 'border-box',
  paddingX: '132px',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  lineHeight: '24px',
  paddingY: '10px',
  textTransform: 'capitalize',
  borderRadius: '14px',
  backgroundColor: theme.palette.primary.dark,
  marginTop: '32px',
})
export const heading_Text = (theme) => ({
  lineHeight: 'normal',
  fontSize: {
    xs: theme.typography.headingThree.fontSize,
    sm: theme.typography.h1.fontSize,
  },
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.black.main,
})

export const desc_Text = (theme) => ({
  lineHeight: '20px',
  mt: { xs: '8px', sm: '16px' },
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.main,
})
export const footer_Text = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '20px',
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.light,
})
export const signin_Button = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '20px',
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.main,
  textDecoration: 'underLine',
  boxSizing: 'border-box',
  padding: '0px',
  textTransform: 'capitalize',
  ':hover': { textDecoration: 'underLine' },
})
export const button_Style = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.main,
  display: 'flex',
  width: '100%',
  height: '44px',
  textTransform: 'capitalize',
  borderRadius: '14px',
  border: '1px solid #C6C6CE',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  gap: '0px',
  lineHeight: '20px',
})
export const or_Text_Style = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.border.dark,
  mx: 2,
})
