import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserContext from "../../../../Context/userContext";
import { Box, Typography } from "@mui/material";
import {
  headerstyle,
  instagramContentBox,
  instagramStyle,
} from "./instagramStyle";
import { getSpecificSpacesAPI } from "../../../../API";
import card1 from "./../../../../Assets/Images/fcard.png";
import card2 from "./../../../../Assets/Images/scard.png";
import { Loader } from "@infostacks/ui-components";
import InstagramPostModal from "./InstagramPostModal";
import EmptyScreen from "./emptyScreen";
import Header from "./InstagramPosts/Header/header";
import NoPost from "./NoPost/Index";
import Post from "./InstagramPosts";
import { instagramProfilesListApi } from "../../../../API/PlatformsAPIs/InstagramApi";

const Instagram = () => {
  const { currentUser, userAuth, stateCheck } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const basePath = currentPath.split("/").slice(0, 3).join("/");
  const [IgFound, setIgFound] = useState();
  const [loading, setLoading] = useState(true);

  const [instagramAccounts, setInstagramAccounts] = useState([]);

  // console.log('instagramAccounts', instagramAccounts[0].id)

  const getInstagramProfileslist = async (spaceId) => {
    try {
      const res = await instagramProfilesListApi(userAuth, spaceId);
      console.log("res", res);
      setInstagramAccounts(res.filter((obj) => obj.instagram_business_account));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getRecentSpace = async (spaceId) => {
    setLoading(true);
    try {
      const fetchSpace = await getSpecificSpacesAPI(
        userAuth,
        currentUser,
        spaceId
      );
      console.log("fetchSpace", fetchSpace);
      const platforms = fetchSpace.platforms?.some(
        (val) => val.name === "instagram"
      );
      setIgFound(platforms);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem("spaceId");
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`);
    }
    const fetch = async () => {
      if (userAuth) {
        getRecentSpace(spaceId);
        getInstagramProfileslist(spaceId);
      }
    };
    fetch();
  }, [id, basePath, navigate, stateCheck, userAuth]);

  return (
    <Box sx={instagramStyle.parentContainer}>
      {loading ? (
        <Box sx={instagramStyle.circularProgressBox}>
          <Loader thickness={6} sx={{ color: "blue" }} />
        </Box>
      ) : (
        <Box sx={instagramContentBox(IgFound)}>
          {IgFound ? (
            <>
              <Box sx={instagramStyle.instagramHeader}>
                <Typography sx={headerstyle.main}>Instagram</Typography>
              </Box>
              <Box
                sx={{
                  background: "",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {instagramAccounts.map((val, ind) => (
                  <Box
                    onClick={() =>
                      navigate(
                        `/dashboard/instagram/${val?.id}/${val?.instagram_business_account?.id}/posts/${id}`
                      )
                    }
                    key={ind}
                    sx={{
                      boxSizing: "border-box",
                      display: "flex",
                      padding: "16px",
                      gap: "16px",
                      cursor: "pointer",
                      background: "#F8F9FF",
                      borderRadius: "16px",
                      border: "1px solid #DADAFF",
                      boxShadow:
                        "0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)",
                    }}
                  >
                    <img
                      src={val?.instagram_business_account?.profile_picture_url}
                      height={48}
                      width={48}
                      style={{
                        borderRadius: "50%",
                        border: "1px solid #DADAFF",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        background: "",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ background: "", fontSize: "14px" }}>
                        Account Name :{" "}
                        <b>{val?.instagram_business_account?.name}</b>
                      </Typography>
                      <Typography sx={{ background: "", fontSize: "14px" }}>
                        User Name :{" "}
                        <b>{val?.instagram_business_account?.username}</b>
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <EmptyScreen />
          )}
        </Box>
      )}
    </Box>
  );
};

export default Instagram;
