import { CloseRounded } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { postModel } from '../../instagramStyle'
import { useTheme } from '@emotion/react'

const ManualPostHeader = ({handleClose,loading}) => {
    const theme = useTheme()
  return (
    <Box sx={postModel.modelHeader}>
        <Typography
          sx={{
            fontSize: theme?.typography.mediumText.fontSize,
            fontWeight: theme.typography.menualText.fontWeight,
            fontFamily: 'Libre Franklin',
            lineHeight:'30px',
          }}
        >
          Create new post
        </Typography>
        <IconButton disabled={loading} onClick={handleClose} sx={{boxSizing:'border-box',padding:'0px'}}><CloseRounded  sx={{ cursor: 'pointer',fontSize:"24px" }}  /></IconButton>
      </Box>
  )
}

export default ManualPostHeader