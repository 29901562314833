import { Box, Button, Menu, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { DoubleClickSvg, NotificationSvg } from '../../PasswordStartAdornment'
import {
  center_Button_Box,
  notificationMenuItemStyle,
  custom_Button_Style,
  notificationText,
  menu_Style,
} from './notificationStyle'
import AllNotifications from './allNotifications'

const NotificationMenuItem = ({ anchorEl, open, onClose }) => {
  const [selectedButton, setSelectedButton] = useState('All')
  const theme = useTheme()
  const handleChangeButton = (e) => {
    setSelectedButton(e)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={1}
      PaperProps={{
        sx: menu_Style(theme),
      }}
    >
      <Box sx={notificationMenuItemStyle.header}>
        <Box sx={notificationMenuItemStyle.titleBox}>
          <Typography sx={notificationText(theme)}>Notification</Typography>
          <Box sx={notificationMenuItemStyle.btn_Main_Box}>
            <Button
              startIcon={<NotificationSvg />}
              variant='text'
              sx={notificationMenuItemStyle.snoozeAndMarkButton(theme)}
            >
              Snooze
            </Button>
            <Button
              startIcon={<DoubleClickSvg />}
              variant='text'
              sx={notificationMenuItemStyle.snoozeAndMarkButton(theme)}
            >
              Mark all as read
            </Button>
          </Box>
        </Box>
        <Box sx={center_Button_Box(theme)}>
          <Button
            onClick={() => handleChangeButton('All')}
            sx={{
              ...custom_Button_Style(theme),
              backgroundColor:
                selectedButton === 'All'
                  ? theme.palette.sky.light
                  : theme.palette.gray.main,
            }}
          >
            All
          </Button>
          <Button
            onClick={() => handleChangeButton('Action')}
            sx={{
              ...custom_Button_Style(theme),
              backgroundColor:
                selectedButton === 'Action'
                  ? theme.palette.sky.light
                  : theme.palette.gray.main,
            }}
          >
            Action
          </Button>
          <Button
            onClick={() => handleChangeButton('Request')}
            sx={{
              ...custom_Button_Style(theme),
              backgroundColor:
                selectedButton === 'Request'
                  ? theme.palette.sky.light
                  : theme.palette.gray.main,
            }}
          >
            Request
          </Button>
        </Box>
      </Box>
      <Box className='scrollbar' sx={notificationMenuItemStyle.scrollbar_Style}>
        {selectedButton === 'All' && <AllNotifications />}
        {selectedButton === 'Action' && <Box>Action content here</Box>}
        {selectedButton === 'Request' && <Box>Request content here</Box>}
      </Box>
    </Menu>
  )
}

export default NotificationMenuItem
