import React from 'react'

import { Box, Stepper, Step, StepConnector, styled } from '@mui/material'

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#000',
    borderTopWidth: 3,
    borderRadius: 1,
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: 'blue',
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: 'blue',
  },
}))

const CustomStepper = ({ activeStep }) => {
  return (
    <Box sx={{ width: '450px', mt: 'auto', mb: '20px' }}>
      <Stepper
        activeStep={activeStep}
        connector={<CustomConnector />}
        alternativeLabel
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'tan',
        }}
      >
        {[...Array(4)].map((_, index) => (
          <Step
            key={index}
            sx={{
              backgroundColor: 'red',
              // maxWidth: '400px', // Set the width for each step
              // mx: '0px', // Apply half the gap on each side (8px total)
            }}
          />
        ))}
      </Stepper>
    </Box>
  )
}

export default CustomStepper