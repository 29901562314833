import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import applogo from '../../Assets/Images/applogo.png'
import { useNavigate } from 'react-router-dom'
import LandingPageStyle from './landingPageStyle'

const NavBarLandingPage = () => {
  const navigate = useNavigate()

  return (
    <Box sx={LandingPageStyle.navBarContainer}>
      <Box sx={LandingPageStyle.leftSection}>
        <Box sx={LandingPageStyle.logoContainer}>
          <Box sx={LandingPageStyle.logoBox}>
            <img src={applogo} alt='logo' style={LandingPageStyle.logoImage} />
          </Box>
          <Typography sx={LandingPageStyle.logoText}>AILatics</Typography>
        </Box>
        <Box sx={LandingPageStyle.menuItems}>
          <Typography>Home</Typography>
          <Typography>About</Typography>
          <Typography>Pricing</Typography>
          <Typography>How it works</Typography>
        </Box>
      </Box>
      <Box sx={LandingPageStyle.rightSection}>
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <Button
            onClick={() => navigate('/signin')}
            sx={LandingPageStyle.loginButton}
          >
            Login
          </Button>

          <Typography
            onClick={() => navigate('/signup')}
            sx={LandingPageStyle.getStartedButton}
          >
            Get started - Free
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default NavBarLandingPage
