import React from "react";
import selectImage from "../../../../Assets/Images/image.png";
import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@emotion/react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomToolTip from "../../customToolTip";

const ImageSection = ({ generateMediaButton, onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/*": [],
    },
  });
  const theme = useTheme();
  return (
    <Box
      // {...getRootProps()}
      sx={{
        display: "flex",
        width: "fit-content",
        height: "57px",
        boxSizing: "border-box",
        padding: "12px",
        borderRadius: "4.972px",
        border: "1px dashed var(--Text-Text-50, #858585)",
        visibility: generateMediaButton === true ? "visible" : "hidden",
        gap: "14px",
        alignItems: "center",
        cursor: "pointer",
        background: "",
      }}
    >
      <input {...getInputProps()} />
      <img
        src={selectImage}
        alt="select img"
        style={{ width: "50px", height: "32px", cursor: "pointer" }}
      />
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{
            fontSize: theme?.typography.h5.fontSize,
            fontWeight: theme?.typography.h4.fontWeight,
            fontFamily: theme?.typography.h2.fontFamily,
            fontStyle: "normal",
            lineHeight: "20px",
            color: " var(--Text-Title-Color, #1F2937)",
            textAlign: "start",
          }}
        >
          Add Reference Media
        </Typography>
        <Typography
          sx={{
            fontSize: theme?.typography.paragraph.fontSize,
            fontWeight: theme?.typography.h5.fontWeight,
            fontFamily: theme?.typography.h2.fontFamily,
            fontStyle: "normal",
            lineHeight: "18px",
          }}
        >
          Drop your Media here or <span style={{ color: "blue" }}>browse</span>
        </Typography>
      </span>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "end",
        }}
      >
        <CustomToolTip
          title={
            <span
              style={{
                boxSizing: "border-box",
                padding: "8px 10px",
                color: "var(--Colors-White, #FFF",
                textAlign: "center",
                fontFamily: "Libre Franklin",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "18px",
                borderRadius: "4px",
              }}
            >
              Refrence Media Coming Soon
            </span>
          }
          icon={
            <ErrorOutlineIcon
              sx={{ color: "blue", height: "18px", width: "18px" }}
            />
          }
          bgColor={"#111827"}
        />
      </Box>
    </Box>
  );
};

export default ImageSection;
