import { useTheme } from '@emotion/react'
import { Box, Slide, Typography } from '@mui/material'
import {
  CreditsIcon,
  SeatsIcon,
  SignoutSvg,
} from '../../PasswordStartAdornment'
import { useContext } from 'react'
import UserContext from '../../../Context/userContext'
import { useNavigate } from 'react-router-dom'
import {
  logOutPopupStyle,
  main_Box_LogoutPopup,
  popupText_Style,
  signout_BoxStyle,
  subscriptionBoxStyles,
  upgradePlan_BoxStyles,
} from './logoutPopupStyle'

const LogOutPopup = ({ open, handlemoreCreditsAndSeatsPopup, drawer }) => {
  const { setIsLoading, signOutContext, currentUser } = useContext(UserContext)
  const theme = useTheme()
  const navigate = useNavigate()

  const signoutBtn = async () => {
    try {
      setIsLoading(true)
      const signOutResponse = await signOutContext()
      console.log('signOutResponse response', signOutResponse)
      setIsLoading(false)
    } catch (error) {
      console.log('error of signout', error)
    }
  }

  return (
    <Slide direction='left' in={open} timeout={{ enter: 500, exit: 500 }}>
      <Box sx={main_Box_LogoutPopup(open, drawer)}>
        <Box
          onClick={handlemoreCreditsAndSeatsPopup}
          sx={subscriptionBoxStyles(currentUser, theme)}
        >
          <Box sx={logOutPopupStyle.item_Box}>
            <SeatsIcon sx={{ height: '26px', width: '26px' }} />
          </Box>
          <Typography sx={popupText_Style(theme)}>
            Buy Seats & Credits
          </Typography>
        </Box>
        <Box
          onClick={() => navigate('/choose-plan')}
          sx={upgradePlan_BoxStyles(currentUser, theme)}
        >
          <CreditsIcon sx={{ height: '24px', width: '24px' }} />
          <Typography sx={popupText_Style(theme)}>Upgrade Plan</Typography>
        </Box>

        <Box onClick={signoutBtn} sx={signout_BoxStyle(theme)}>
          <SignoutSvg sx={{ height: '24px', width: '24px' }} />
          <Typography sx={popupText_Style(theme)}>Log Out</Typography>
        </Box>
      </Box>
    </Slide>
  )
}

export default LogOutPopup
