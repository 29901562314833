const userEditAiModelStyle = {
  model_Style: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 'calc(100vh - 100vh)',
    left: 'calc(100vw - 64vw)',
    bottom: 'calc(100vw - 97vw)',
  },
  form_Style: (showImages, theme) => ({
    width: '360px',
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    borderRadius: '16px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    boxShadow: 'none',
    overflow: 'hidden',
    transition: 'max-height 1s ease-in-out',
    maxHeight: showImages ? '498px' : '200px',
  }),
  prompt_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  promptText: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h4.fontFamily,
    color: theme.palette.black.main,
    lineHeight: '20px',
  }),
  field_Box: { position: 'relative', display: 'flex', alignItems: 'center' },
  loader_Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '328px',
    width: '100%',
  },
  showImage_Box: (showImages) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    maxHeight: showImages ? '328px' : '0',
    opacity: showImages ? 1 : 0,
    transition: 'max-height 1s ease-in-out, opacity 1s ease-in-out',
    overflow: 'hidden',
  }),
  footer_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backgroundImage_Box: {
    width: '160px',
    height: '160px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '8px',
    objectFit: 'cover',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  spanStyle: { display: 'flex', gap: '10px', alignItems: 'center' },
  footerText: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.h4.fontFamily,
    lineHeight: '20px',
  }),
}
export default userEditAiModelStyle
