import { Box, Typography } from '@mui/material'
import React from 'react'
import dashboard from '../../Assets/Images/dashboard.png'
import NavBarLandingPage from './navBarLandingPage'
import { useNavigate } from 'react-router-dom'
import bgimg from '../../Assets/Images/landingpagebackground.png'
import LandingPageStyle from './landingPageStyle'

const HeaderSection = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        ...LandingPageStyle.headerContainer,
        backgroundImage: `url(${bgimg})`, // dynamically applying background image
      }}
    >
      <NavBarLandingPage />

      <Box sx={LandingPageStyle.headerContent}>
        <Box sx={LandingPageStyle.headerTextContainer}>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={LandingPageStyle.titleText}>
              Write Prompt, Create Post, Boost Engagement
            </Typography>
            <Typography sx={LandingPageStyle.subtitleText}>
              With AILatics, simply write a prompt, let our AI create the
              perfect post, and watch your social media engagement soar.
              Simplify your social media strategy and grow your audience
              effortlessly.
            </Typography>
          </span>
          <Box sx={LandingPageStyle.headerButtonsContainer}>
            <Typography
              onClick={() => navigate('/signup')}
              sx={LandingPageStyle.gradientButton}
            >
              Get started - Free
            </Typography>
            <Typography
              onClick={() => navigate('/signup')}
              sx={LandingPageStyle.demoButton}
            >
              Try Demo
            </Typography>
          </Box>
        </Box>
        <Box sx={LandingPageStyle.dashboardContainer}>
          <img
            src={dashboard}
            alt='Dashboard'
            style={LandingPageStyle.dashboardImage}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HeaderSection
