import {
  Box,
  FormHelperText,
  IconButton,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useContext, useEffect, useState } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Loader } from '@infostacks/ui-components'
import { useForm } from 'react-hook-form'
import { AiWhiteIcon } from '../../../PasswordStartAdornment'
import { CustomFilterButton } from '../../../ReusableComponents/customButtons'
import {
  aiPromptResolver,
  aiPromptValidator,
} from '../../../../Validators/authValidator'
import newEditWorkspaceStyle, {
  commonTextFieldStyle,
} from './newEditWorkspaceStyle'
import { handleGetCreditsCountAPI } from '../../../../API/Stripe'
import UserContext from '../../../../Context/userContext'
import { handleGenerateContent } from '../../../../API/AiAPIs'
import styleEditAiModel, {
  footer_CreditsText,
  form_Style,
  mainBox_Style,
  write_Prompt_Style,
} from './styleEditAiModel'

const EditAiLogoModel = ({ open, onClose, handleAiImageSelection }) => {
  const [showImages, setShowImages] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [isGenerate, setIsGenerate] = useState(false)
  const [totalCredits, setTotalCredits] = useState()
  const [remainingCredits, setRemainingCredits] = useState()
  const { currentUser, userAuth } = useContext(UserContext)
  const spaceId = window.sessionStorage.getItem('spaceId')
  const [creditsSkelton, setCreditsSkelton] = useState(true)
  const [logoArray, setLogoArray] = useState([])
  console.log('logoArray', logoArray)
  const theme = useTheme()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = async (formData) => {
    formData.generateType = 'image'
    try {
      setLoading(true)
      const generateLogoResponse = await handleGenerateContent(
        userAuth,
        formData
      )
      console.log('generateLogoResponse', generateLogoResponse)
      if (generateLogoResponse.status === 200) {
        setLogoArray(
          generateLogoResponse.logos.map(
            (val) => 'data:image/png;base64,' + val?.b64_json
          )
        )
        setShowImages(true)
        setIsGenerate(true)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error generating logos:', error)
      setLoading(false)
    }
  }

  const handleImageClick = (image) => {
    handleAiImageSelection(image)
    onClose()
  }

  const currentImages = logoArray.slice(imageIndex, imageIndex + 4)

  const getCredits = async () => {
    setCreditsSkelton(true)
    try {
      const resp = await handleGetCreditsCountAPI(userAuth, spaceId)
      console.log('resp', resp)
      setTotalCredits(resp?.credits?.totalReceived)
      setRemainingCredits(resp?.credits?.remaining)
      setCreditsSkelton(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      getCredits()
    }
    fetch()
  }, [])

  return (
    <Modal open={open} sx={styleEditAiModel.aiModel_Style}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={form_Style(showImages, theme)}
      >
        <Box sx={styleEditAiModel.closeRoundedIcon_Box}>
          <Typography sx={write_Prompt_Style(theme)}>Write Prompt</Typography>
          <IconButton onClick={onClose} sx={{ height: '11px', width: '11px' }}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
        <div style={styleEditAiModel.field_Style}>
          <TextField
            sx={commonTextFieldStyle(errors.prompt)}
            fullWidth
            placeholder='Ex. Logo for Digital Agency'
            autoComplete='off'
            onInput={(e) => {
              setInputValue(e.target.value)
            }}
            {...register('userInput', aiPromptValidator)}
          />
          <FormHelperText sx={newEditWorkspaceStyle.helperText}>
            {errors.userInput && touchedFields.userInput
              ? aiPromptResolver[errors.userInput.type]
              : ''}
          </FormHelperText>
        </div>
        {loading ? (
          <Box sx={styleEditAiModel.loaderBox}>
            <Loader thickness={7} size={30} />
          </Box>
        ) : (
          showImages && (
            <Box sx={mainBox_Style(showImages)}>
              {logoArray.length === 0 && !loading ? (
                <Typography>No images to display</Typography>
              ) : (
                logoArray
                  .slice(imageIndex, imageIndex + 4)
                  .map((image, index) => (
                    <Box
                      key={index}
                      sx={styleEditAiModel.map_Box}
                      onClick={() => handleImageClick(image)}
                    >
                      <img
                        src={image}
                        alt={`Generated logo ${index}`}
                        style={styleEditAiModel.img_Style}
                      />
                    </Box>
                  ))
              )}
            </Box>
          )
        )}
        <Box sx={styleEditAiModel.footer_Box}>
          <span style={styleEditAiModel.span_Style}>
            {creditsSkelton ? (
              <Skeleton
                variant='text'
                width={120}
                height={25}
                animation='pulse'
              />
            ) : (
              <>
                <Typography sx={footer_CreditsText(theme)}>
                  <span style={{ color: 'red' }}>{totalCredits}</span>
                  <span style={{ color: 'green' }}>/{remainingCredits}</span>
                </Typography>
                <ErrorOutlineIcon
                  sx={{ color: 'blue', height: '18px', width: '18px' }}
                />
              </>
            )}
          </span>
          <CustomFilterButton
            icon={<AiWhiteIcon />}
            buttonText={isGenerate ? 'Regenerate' : 'Generate'}
            // onClick={handleGenerateClick}
            type='submit'
          />
        </Box>
      </form>
    </Modal>
  )
}

export default EditAiLogoModel
