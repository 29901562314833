import { useContext, useEffect } from 'react'
import UserContext from '../../../Context/userContext'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import ROUTES from '../All Routes'
import { StackLoader } from '@infostacks/ui-components'
import '@infostacks/ui-components/dist/cjs/index.css'
import { auth } from '../../../Firebase/firebaseConfig'
import CustomStackLoader from '../../ReusableComponents/CustomStackLoader'

export default function PrivateRouter() {
  const { currentUser, isLoading, addRouteToStack } = useContext(UserContext)
  const userAuth = auth.currentUser
  const location = useLocation()

  useEffect(() => {
    if (!userAuth) {
      if (ROUTES.private.includes(location.pathname)) {
        console.log('path Name', location)
        if (location.search === '') {
          addRouteToStack(location.pathname)
        } else addRouteToStack(location.pathname + location.search)
      }
    }
  })

  // console.log('new',isLoading);
  if (isLoading) {
    return <CustomStackLoader />
  }
  if (userAuth && !userAuth.emailVerified && location.pathname !== '/welcome') {
    return <Navigate to='/welcome' replace={true} />
  }

  if (!userAuth) {
    if (ROUTES.public.includes(location.pathname)) {
      return <Outlet />
    }
    return <Navigate to='/signup' replace={true} />
  }

  if (!currentUser) return <CustomStackLoader />
  return <Outlet />
}
