import { useTheme } from '@emotion/react'
import { ArrowBack } from '@mui/icons-material'
import { Box, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import {
  adminPageStyle,
  admin_Text,
  date_Text,
  edit_Role_Text,
  menuRole_Style,
  menu_Style,
} from './workspaceRoleAndAdminStyle'
import UserContext from '../../../../../Context/userContext'
import { formatDateString, specificRolePermissionAPI } from '../../../../../API'
import AdminPageAccordian from './adminPageAccordian'
import CustomMoreVertRoundedButton from '../../../../ReusableComponents/customMoreVertRoundedButton'

const ManageRole = () => {
  const { userAuth, setButtonState, manageRole, setManageRole } =
    useContext(UserContext)
  const [workSpacePermissions, setWorkSpacePermissions] = useState([])
  const [platformPermissions, setPlatformPermissions] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  // console.log('manageRole',manageRole);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const handleNavigate = () => {
    navigate('/dashboard/roles')
  }
  const theme = useTheme()

  const fetchPermissions = async (roleId) => {
    try {
      if (userAuth) {
        const resApi = await specificRolePermissionAPI(userAuth, roleId)
        setWorkSpacePermissions(resApi.permissions.workSpace)
        setPlatformPermissions(resApi.permissions.platform)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleEditRole = (paramRoleId) => {
    setButtonState('edit')
    window.sessionStorage.setItem('buttonState', 'edit')
    window.sessionStorage.setItem('manageRole', JSON.stringify(manageRole))
    navigate(`/dashboard/edit-role/${paramRoleId}`)
  }

  useEffect(() => {
    const roleData = window.sessionStorage.getItem('manageRole')
    const currentRole = JSON.parse(roleData)
    // console.log('currentRole',currentRole);
    setManageRole(currentRole)
    if (currentRole && userAuth) {
      fetchPermissions(currentRole._id)
    }
  }, [userAuth])

  return (
    <Box sx={adminPageStyle.parent_Box}>
      <Box sx={adminPageStyle.main_Box}>
        <Box sx={adminPageStyle.arrow_Box}>
          <ArrowBack
            onClick={handleNavigate}
            sx={adminPageStyle.arrowBack_Style}
          />
          <span style={{ width: '50%' }}>
            <Typography sx={admin_Text(theme)}>
              {manageRole?.roleName}
            </Typography>
            <Typography sx={date_Text(theme)}>
              {formatDateString(manageRole?.createdAt)}
            </Typography>
          </span>
        </Box>
        <CustomMoreVertRoundedButton onClick={handleOpenMenu} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: menuRole_Style(theme),
        }}
      >
        <MenuItem
          onClick={() => handleEditRole(manageRole._id)}
          sx={edit_Role_Text(theme)}
        >
          Edit Role
        </MenuItem>
      </Menu>
      <Box sx={adminPageStyle.customAccordion_Box}>
        <AdminPageAccordian
          title='Workspace Permissions'
          data={workSpacePermissions}
          icon={<WorkspacesIcon />}
        />
        <AdminPageAccordian
          title='Platform Permissions'
          data={platformPermissions}
          icon={<WorkspacesIcon />}
        />
      </Box>
    </Box>
  )
}

export default ManageRole
