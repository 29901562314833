import { Box, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles, {
  activeCardStyle,
  addIcon_Style,
  amount_Text_Style,
  bodyText_Style,
  card_BodyStyle,
  checkCircle_Style,
  plan_Style,
  plan_TextStyle,
  removeIcon_Style,
  teamUser_TextStyle,
  title_TextStyle,
} from '../pricingStyle'
import { CheckCircle } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useTheme } from '@emotion/react'
import UserContext from '../../../Context/userContext'
const PricingPlanCard = ({
  billingCycle,
  setCredits,
  credits,
  teamUsers,
  setTeamUsers,
  activeCard,
  setActiveCard,
}) => {
  const [cardIndex, setCardIndex] = React.useState(2)
  const { currentUser } = useContext(UserContext)
  const [teamCredits, setTeamCredits] = React.useState(10)
  const [individualQuarterly, setIndividualQuarterly] = React.useState(206.99)
  const [individualYearly, setIndividualYearly] = React.useState(215.99)
  const [teamQuarterly, setTeamQuarterly] = React.useState(210.98)
  const [teamYearly, setTeamYearly] = React.useState(219.98)
  console.log('currentUser in pricing', currentUser)
  const planType = currentUser?.Subscription?.planType
  const interval = currentUser?.Subscription?.billingInterval

  const handleIncrement = (title, e) => {
    e.stopPropagation()
    if (credits < 99 && billingCycle && title) {
      if (billingCycle === 'quarterly' && title === 'Individual') {
        setCredits((prevCredits) => prevCredits + 1)
        setIndividualQuarterly(
          (prevIndividualQuarterly) => prevIndividualQuarterly + 20
        )
      } else if (billingCycle === 'yearly' && title === 'Individual') {
        setCredits((prevCredits) => prevCredits + 1)
        setIndividualYearly((prevIndividualYearly) => prevIndividualYearly + 20)
      } else if (billingCycle === 'quarterly' && title === 'Team Plan') {
        setCredits((prevCredits) => prevCredits + 1)
        setTeamQuarterly((prevTeamQuarterly) => prevTeamQuarterly + 20)
      } else if (billingCycle === 'yearly' && title === 'Team Plan') {
        setCredits((prevCredits) => prevCredits + 1)
        setTeamYearly((prevTeamYearly) => prevTeamYearly + 20)
      }
    }
  }

  const handleTeamPlanUser = (val, title, e) => {
    e.stopPropagation()
    if ((val, billingCycle, title)) {
      if (
        val === 'add' &&
        teamUsers < 99 &&
        billingCycle === 'quarterly' &&
        title === 'Team Plan'
      ) {
        setTeamUsers((prev) => prev + 1)
        setTeamQuarterly((prev) => prev + 3.99)
      }
      if (
        val === 'add' &&
        teamUsers < 99 &&
        billingCycle === 'yearly' &&
        title === 'Team Plan'
      ) {
        setTeamUsers((prev) => prev + 1)
        setTeamYearly((prev) => prev + 3.99)
      }
      if (
        val === 'remove' &&
        teamUsers > 2 &&
        billingCycle === 'quarterly' &&
        title === 'Team Plan'
      ) {
        setTeamUsers((prev) => prev - 1)
        setTeamQuarterly((prev) => prev - 3.99)
      }
      if (
        val === 'remove' &&
        teamUsers < 99 &&
        billingCycle === 'yearly' &&
        title === 'Team Plan'
      ) {
        setTeamUsers((prev) => prev - 1)
        setTeamYearly((prev) => prev - 3.99)
      }
    }
  }

  const handleDecrement = (title, e) => {
    e.stopPropagation()
    if (credits > 10 && billingCycle && title) {
      if (billingCycle === 'quarterly' && title === 'Individual') {
        setCredits((prevCredits) => prevCredits - 1)
        setIndividualQuarterly(
          (prevIndividualQuarterly) => prevIndividualQuarterly - 20
        )
      }
      if (billingCycle === 'yearly' && title === 'Individual') {
        setCredits((prevCredits) => prevCredits - 1)
        setIndividualYearly((prevIndividualYearly) => prevIndividualYearly - 20)
      }
      if (billingCycle === 'quarterly' && title === 'Team Plan') {
        setCredits((prevCredits) => prevCredits - 1)
        setTeamQuarterly((prevTeamQuarterly) => prevTeamQuarterly - 20)
      }
      if (billingCycle === 'yearly' && title === 'Team Plan') {
        setCredits((prevCredits) => prevCredits - 1)
        setTeamYearly((prevTeamYearly) => prevTeamYearly - 20)
      }
    }
  }

  const handleCard = (index, title) => {
    // if (billingCycle&&title) {
    //   if (billingCycle==='quarterly') {
    //     if (title==='Individual') {
    //       setTeamQuarterly(210.98)
    //       setCredits(10)
    //     }
    //     if (title==='Team Plan') {
    //       setIndividualQuarterly(206.99)
    //       setCredits(10)
    //     }

    //   }
    //   if (billingCycle==='yearly') {
    //     if (title==='Individual') {
    //       setTeamYearly(843.92)
    //       setCredits(10)
    //     }
    //     if (title==='Team Plan') {
    //       setIndividualYearly(827.96)
    //       setCredits(10)
    //     }

    //   }
    // }
    setCredits(10)
    setTeamUsers(2)
    console.log('OOOOOOOOOO')
    setCardIndex(index)
    setActiveCard(title)
  }

  const formatToTwoDecimals = (value) => {
    return parseFloat(value).toFixed(2)
  }

  const plans = [
    {
      title: 'Basic Plan',
      quarterlyPrice: 0,
      yearlyPrice: 0,
      features: [
        '1 Workspace',
        '1 Ai post',
        '3 post per Platform',
        '5MB Storage',
        'Optional credit top ups',
        '3 concurrent fast jobs',
        '3 concurrent fast jobs',
        '3 concurrent fast jobs',
      ],
    },
    {
      title: 'Individual',
      quarterlyPrice: individualQuarterly,
      yearlyPrice: individualYearly,
      features: [
        '10M Credits',
        '5MB Storage',
        '15h Fast generations',
        'Unlimited Relaxed generations',
        'General commercial terms',
        'Access to member gallery',
        'Optional credit top ups',
        '3 concurrent fast jobs',
      ],
    },
    {
      title: 'Team Plan',
      quarterlyPrice: teamQuarterly,
      yearlyPrice: teamYearly,
      features: [
        '10M Credits',
        '2 Users',
        '30h Fast generations',
        'Unlimited Relaxed generations',
        'General commercial terms',
        'Access to member gallery',
        'Optional credit top ups',
        '3 concurrent fast jobs',
      ],
    },
    {
      title: 'Enterprise',
      quarterlyPrice: 120,
      yearlyPrice: 240,
      features: [
        '10M Credits',
        '60h Fast generations',
        'Unlimited Relaxed generations',
        'General commercial terms',
        'Access to member gallery',
        'Optional credit top ups',
        '12 concurrent fast jobs',
      ],
    },
  ]
  const isPlanEnabled = (planType, planTitle) => {
    if (!planType) {
      return true // Show all plans if planType does not exist
    }
    if (planType === 'team') {
      return planTitle === 'Enterprise'
    }
    if (planType === 'individual') {
      return ['Enterprise', 'Team Plan'].includes(planTitle)
    }
    if (planType === 'basic') {
      return ['Individual', 'Team Plan', 'Enterprise'].includes(planTitle)
    }
    return true
  }

  const theme = useTheme()
  return (
    <Box sx={styles.pricingContainer}>
      {plans.map((plan, index) => {
        const isDisabled = planType
          ? !isPlanEnabled(planType, plan.title)
          : false
        return (
          <Box
            onClick={(e) => {
              if (!isDisabled) {
                handleCard(index, plan.title, e)
                // setCardIndex(index);
                // setActiveCard(plan.title);
              }
            }}
            key={index}
            sx={{
              ...styles.gridInnerContainer(index, cardIndex),
              opacity: isDisabled ? 0.5 : 1, // Reduce opacity if disabled
              pointerEvents: isDisabled ? 'none' : 'auto', // Disable pointer events if disabled
            }}
          >
            <Box sx={styles.card_Header_Box}>
              <Box sx={styles.header_Text_Box}>
                <Typography sx={title_TextStyle(cardIndex, index, theme)}>
                  {plan.title}
                </Typography>
                <Box sx={activeCardStyle(index, cardIndex, theme)}>
                  Most Popular
                </Box>
              </Box>
              <Box sx={styles.amount_Box}>
                <Typography sx={amount_Text_Style(cardIndex, index, theme)}>
                  $
                  {formatToTwoDecimals(
                    billingCycle === 'quarterly'
                      ? plan.quarterlyPrice
                      : plan.yearlyPrice
                  )}
                </Typography>
                <Typography sx={plan_Style(theme)}>
                  {billingCycle === 'quarterly' ? 'quarterly' : 'yearly'}
                </Typography>
              </Box>
            </Box>

            <Box sx={card_BodyStyle.main_Box}>
              {plan.features.map((feature, ind) => (
                <Box sx={card_BodyStyle.plan_Features_Box} key={ind}>
                  <CheckCircle sx={checkCircle_Style(index, cardIndex)} />
                  {cardIndex === index &&
                  ind === 1 &&
                  plan.title === 'Team Plan' ? (
                    <>
                      <IconButton
                        onClick={(e) =>
                          handleTeamPlanUser('remove', plan.title, e)
                        }
                        sx={removeIcon_Style(teamUsers, theme)}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography
                        sx={teamUser_TextStyle(theme)}
                      >{`${teamUsers} User`}</Typography>
                      <IconButton
                        onClick={(e) =>
                          handleTeamPlanUser('add', plan.title, e)
                        }
                        sx={addIcon_Style(theme)}
                      >
                        <AddIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Typography sx={bodyText_Style(index, cardIndex, theme)}>
                      {feature}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default PricingPlanCard
