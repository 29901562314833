import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
    },

    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
    },
    error: {
      main: '#EF4444',
      light: '#ef5350',
      dark: '#E34935',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
    },

    info: {
      main: '#0288d1',
      light: '#03a9f4',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#44b700',
    },
    black: {
      main: '#000',
      light: '#494949',
      extraLight: '#52575D',
    },
    sky: {
      main: '#6C66FF',
      light: '#DADAFF',
      extraLight: '#E5E4FF',
      dark: '#CCE0FF',
    },
    customWhite: {
      main: '#FFF',
      light: '#EAEAEA',
      extraLight: '#F9F9FF',
      dark: '#FDFDFF',
      hover: '#F2F2F2',
    },
    gray: {
      main: '#F8F9FF',
      light: '#E5E7EB',
      extraLight: '#DBEAFE',
      dark: '#FAFBFC',
      hover: '#F3F4F6',
    },
    border: {
      main: '#C2C2C2',
      light: '#D9D9D9',
      dark: '#858585',
      extralight: '#E5E5E5',
    },
  },
  typography: {
    h1: {
      fontSize: '40px',
      fontWeight: '700',
      fontFamily: 'Libre Franklin',
    },
    h2: {
      fontSize: '24px',
      fontWeight: '600',
      fontFamily: 'Libre Franklin',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Libre Franklin',
    },
    h4: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Libre Franklin',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Libre Franklin',
    },
    menuItems: {
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'Libre Franklin',
    },
    paragraph: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: 'Libre Franklin',
    },
    menualText: {
      fontSize: '32px',
      fontWeight: '700',
      fontFamily: 'Libre Franklin',
    },
    extraLarge: {
      fontSize: '38px',
      fontWeight: '800',
    },
    large: {
      fontSize: '30px',
    },
    mediumText: {
      fontSize: '20px',
    },
    headingOne: {
      fontSize: '36px',
    },
    headingTwo: {
      fontSize: '28px',
    },
    headingThree: {
      fontSize: '25px',
    },
  },
})

const ThemeApply = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeApply
