const buyMoreCreditsStyle = {
  form_Style: {
    width: '560px',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    padding: '36px 40px',
    borderRadius: '32px',
  },
  header_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  credits_Box: {
    width: '100%',
    display: 'flex',
    background: '',
    border: '1px solid var(--Text-Text-25, #C2C2C2)',
    borderRadius: '16px',
  },
  field_Box: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  icon_Box: {
    width: '60px',
    background: '',
    borderLeft: '1px solid #C2C2C2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  recepit_Box: {
    mt: '32px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px',
    background: '#F3F4F6',
    gap: '8px',
    borderRadius: '12px',
  },
  main_CheckBox: {
    display: 'flex',
    marginTop: '16px',
    background: '',
    width: '100%',
    height: 'fit-content',
    alignItems: 'center',
    gap: '10px',
  },
  footer_Box: {
    display: 'flex',
    justifyContent: 'end',
    mt: '32px',
    width: '100%',
    gap: '16px',
  },
}
export default buyMoreCreditsStyle
export const buyCreditsMembersText = (theme) => ({
  fontSize: theme?.typography.large.fontSize,
  fontWeight: theme?.typography.h1.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: 'normal',
  letterSpacing: '-1.2px',
})
export const descriptionText = (theme) => ({
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  color: theme.palette.black.light,
  lineHeight: '20px',
  mt: '8px',
})
export const creditsText = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  mt: '32px',
  lineHeight: '24px',
})
export const counting_NumberStyle = (theme) => ({
  background: '',
  boxSizing: 'border-box',
  padding: '12px 16px',
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: '20px',
})
export const price_NumberStyle = (theme) => ({
  boxSizing: 'border-box',
  padding: '12px 16px',
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: '20px',
})
export const info_TextStyle = (theme) => ({
  fontSize: theme?.typography.paragraph.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  color: theme.palette.black.light,
  lineHeight: '20px',
})
export const members_Text = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  mt: '16px',
  lineHeight: '24px',
})

export const textStyle = (theme) => ({
  background: '',
  boxSizing: 'border-box',
  padding: '12px 16px',
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: '20px',
})
export const custom_styleText = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  color: theme.palette.black.main,
  lineHeight: '24px',
})
export const totalBill_styleText = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: '24px',
})
export const totalCostPrice = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h1.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  color: theme.palette.black.main,
  fontStyle: 'normal',
  lineHeight: '24px',
})
export const confirmitionText = (theme) => ({
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  color: theme.palette.black.main,
  lineHeight: '20px',
})
export const cancelButton = (theme) => ({
  borderRadius: '14px',
  border: `1px solid ${theme.palette.primary.dark}`,
  boxSizing: 'border-box',
  padding: '7px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: '24px',
  color: theme.palette.primary.dark,
  ':hover': { border: `1px solid ${theme.palette.primary.dark}` },
})
export const purchesButton = (theme) => ({
  width: '112px',
  borderRadius: '14px',
  // border: `1px solid ${theme.palette.primary.dark}`,
  boxSizing: 'border-box',
  padding: '7px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  lineHeight: '24px',
  color: theme.palette.customWhite.main,
  background: theme.palette.primary.dark,
})
