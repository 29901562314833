import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import instagram from '../../../../Assets/Images/instagram.png'
import avatar1 from '../../../../Assets/Images/avatar1.png'
import avatar2 from '../../../../Assets/Images/avatar2.png'
import avatar3 from '../../../../Assets/Images/avatar3.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css/bundle'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

const EmptyScreen = () => {
  const navigate =useNavigate()
  const slidesData = [
    {
      avatar: avatar1,
      message:
        'After using AI-scheduled posts, I saw my follower count grow by 25% in a month!',
      name: 'John Doe',
      profession: 'Fitness Influencer',
    },
    {
      avatar: avatar2,
      message: 'AI insights helped me increase engagement with minimal effort!',
      name: 'Jane Smith',
      profession: 'Content Creator',
    },
    {
      avatar: avatar3,
      message:
        'This tool saved me so much time scheduling posts and tracking metrics!',
      name: 'Emily Clark',
      profession: 'Entrepreneur',
    },
    {
      avatar: avatar2,
      message: 'AI insights helped me increase engagement with minimal effort!',
      name: 'Jane Smith',
      profession: 'Content Creator',
    },
  ]
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        padding: '20px',
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          maxWidth: '500px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <img src={instagram} alt='Instagram' width='109px' height='109px' />
        <Typography
          sx={{
            boxSizing: 'border-box',
            paddingTop: '16px',
            color: '#000',
            fontFamily: 'Libre Franklin',
            fontSize: { xs: '24px', md: '38px' },
            fontWeight: 500,
            letterSpacing: '-1.52px',
          }}
        >
          Get started by linking your Instagram account.
        </Typography>
        <Typography
          sx={{
            boxSizing: 'border-box',
            paddingTop: '8px',
            color: '#494949',
            fontFamily: 'Libre Franklin',
            fontSize: { xs: '14px', md: '18px' },
            fontWeight: 400,
            lineHeight: '27px',
          }}
        >
          By linking your account, you'll be able to create, schedule, and
          enhance your posts with AI-driven insights!
        </Typography>
        <Button
        onClick={()=>navigate('/social-connections')}
          sx={{
            boxSizing: 'border-box',
            marginTop: '16px',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
              color: '#FFF',
              fontFamily: 'Libre Franklin',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textTransform:'capitalize',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.dark,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          
            Connect Instagram
          
        </Button>
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
          width: '70%',
          overflow: 'hidden',
          paddingX: '2px',
          background: '',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontFamily: 'Libre Franklin',
            fontSize: { xs: '16px', md: '20px' },
            fontWeight: 600,
            lineHeight: '30px',
          }}
        >
          See how others are benefiting!
        </Typography>

        <Swiper
          spaceBetween={16}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesPerView={3}
          modules={[Autoplay]}
          fadeEffect={{ crossFade: false }}
          className='fbSwiper'
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide
              key={index}
              style={{
                background: '#F9F9FF',
                width: '100%',
                maxWidth: '360px',
                boxSizing: 'border-box',
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '12px',
                borderRadius: '16px',
                border: '1px solid #DADAFF',
              }}
            >
              <img
                src={slide.avatar}
                alt={slide.name}
                style={{
                  borderRadius: '50%',
                  height: '44px',
                  width: '44px',
                }}
              />
              <Typography
                sx={{
                  color: '#0C0C0C',
                  fontFamily: 'Libre Franklin',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                }}
              >
                {slide.message}
              </Typography>
              <span style={{ display: 'flex', gap: '4px' }}>
                <Typography
                  sx={{
                    color: '#0C0C0C',
                    fontSize: '16px',
                    fontFamily: 'Libre Franklin',
                    fontWeight: 600,
                    lineHeight: '24px',
                  }}
                >
                  {slide.name}
                </Typography>
                -
                <Typography
                  sx={{
                    color: '#0C0C0C',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    fontFamily: 'Libre Franklin',
                  }}
                >
                  {slide.profession}
                </Typography>
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default EmptyScreen
