import makeHeader from "../Headers"

export const handleStripeAPI = async (userAuth,body) => {
    // console.log('body',body);
    try {
      const idToken = await userAuth.getIdToken(true)
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/stripe/create_subscription/`
  
      const response = await fetch(apiUrl, makeHeader('POST', idToken,body))
  
      const responseData = await response.json()
  
      return responseData
    } catch (error) {
      console.error('Error in handleStripeAPI', error)
      throw error
    }
  }



export const handleBuyAdsOnAPI = async (userAuth,body) => {
  console.log('body',body);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/stripe/buy_adds_on`

    const response = await fetch(apiUrl, makeHeader('POST', idToken,body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleBuyAdsOnAPI', error)
    throw error
  }
}

export const handleUpdateSubscriptionAPI = async (userAuth,body) => {
  
  console.log('body',body);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/stripe/update_subscription/`

    const response = await fetch(apiUrl, makeHeader('PUT', idToken,body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleUpdateSubscriptionAPI', error)
    throw error
  }
}

export const handleShareCreditsAPI = async (userAuth,body) => {
  
  console.log('body',body);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/credits/share`

    const response = await fetch(apiUrl, makeHeader('POST', idToken,body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleShareCreditsAPI', error)
    throw error
  }
}


export const handleRevertCreditsAPI = async (userAuth,body) => {
  
  console.log('body',body);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/credits/revert`

    const response = await fetch(apiUrl, makeHeader('POST', idToken,body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleRevertCreditsAPI', error)
    throw error
  }
}


export const handleGetCreditsCountAPI = async (userAuth,workspaceId) => {
  
  console.log('workspaceId',workspaceId);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/credits/get?workspaceId=${workspaceId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken,))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleGetCreditsCountAPI', error)
    throw error
  }
}