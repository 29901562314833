import makeHeader from "../../Headers";

export const dataType64toFile = (dataurl, filename = "NewFile") => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let newFile = new File([u8arr], filename, {
    type: mime,
  });
  return newFile;
};

export const facebookGetPagesApi = async (userAuth, spaceId) => {
  // console.log('userAuth',userAuth, "space",spaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/list?workspaceId=${spaceId}`;

    const response = await fetch(apiUrl, makeHeader("GET", idToken));
    const responseData = await response.json();

    return responseData; // Return the data here
  } catch (error) {
    console.error("Error in facebookGetPagesApi:", error);
    throw error;
  }
};

export const facebookPostWithImagesApi = async (
  userAuth,
  body,
  spaceId,
  pageId
) => {
  console.log("body obj", body, "id", pageId, "spaceId", spaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    if (pageId === "page id in body") {
      console.log("Goooo");
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/post?pageId=${body.pageId}&workspaceId=${spaceId}`;
      const response = await fetch(apiUrl, makeHeader("POST", idToken, body));
      const responseData = await response.json();
      return responseData; // Return the data here
    } else {
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/post?pageId=${pageId}&workspaceId=${spaceId}`;

      const response = await fetch(apiUrl, makeHeader("POST", idToken, body));
      const responseData = await response.json();

      return responseData; // Return the data here
    }
  } catch (error) {
    console.error("Error in facebookGetPagesApi:", error);
    throw error;
  }
};

export const getFeedsForAllPages = async (userAuth, spaceId) => {
  console.log("getFeedsForAllPages spaceId", spaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/all_pages/feed?workspaceId=${spaceId}`;

    const response = await fetch(apiUrl, makeHeader("GET", idToken));
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("Error in getFeedsForAllPages:", error);
    throw error;
  }
};

export const facebookScheduleApi = async (userAuth, body, spaceId) => {
  console.log(
    "Schedule body obj",
    body,
    "pagaId",
    body.pageId,
    "spaceId",
    spaceId
  );
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/schedule-post?workspaceId=${spaceId}&pageId=${body.pageId}`;

    const response = await fetch(apiUrl, makeHeader("POST", idToken, body));
    const responseData = await response.json();
    console.log("responseData", responseData);
    return responseData;
  } catch (error) {
    console.error("Error in facebookScheduleApi:", error);
    throw error;
  }
};

export const deleteFacebookPostApi = async (
  userAuth,
  pageId,
  postId,
  workspaceId
) => {
  console.log("pagaId", pageId, "postId", postId, "workspaceId", workspaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/delete-post?workspaceId=${workspaceId}&pageId=${pageId}&pagePostId=${postId}`;

    const response = await fetch(apiUrl, makeHeader("DELETE", idToken));
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error in deleteFacebookPostApi:", error);
    throw error;
  }
};

export const getFacebookEngagements = async (
  userAuth,
  pageId,
  postId,
  workspaceId
) => {
  console.log("pagaId", pageId, "postId", postId, "workspaceId", workspaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/post_interactions?workspaceId=${workspaceId}&pagePostId=${postId}&pageId=${pageId}`;

    const response = await fetch(apiUrl, makeHeader("GET", idToken));
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error in getFacebookEngagements:", error);
    throw error;
  }
};

export const editFacebookPost = async (
  userAuth,
  body,
  workspaceId,
  pageId,
  postId
) => {
  console.log("pageId", pageId, "postId", postId, "workspaceId", workspaceId);
  try {
    const idToken = await userAuth.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/facebook/page/update-post?workspaceId=${workspaceId}&pagePostId=${postId}&pageId=${pageId}`;

    const response = await fetch(apiUrl, makeHeader("PUT", idToken, body));
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error in editFacebookPost:", error);
    throw error;
  }
};
