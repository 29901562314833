const LandingPageStyle = {
  // NavBar styles
  navBarContainer: {
    height: '77px',
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingX: '48px',
    paddingY: '8px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSection: {
    display: 'flex',
    gap: '60px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    gap: '9px',
    alignItems: 'center',
  },
  logoBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImage: {
    height: '100%',
    width: '100%',
  },
  logoText: {
    paddingX: '8px',
    color: '#FFF',
    fontFamily: 'Libre Franklin',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '72px',
    letterSpacing: '0.48px',
  },
  menuItems: {
    display: 'flex',
    gap: '32px',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#FFF',
    fontWeight: '400',
    fontFamily: 'Libre Franklin',
  },
  rightSection: {
    display: 'flex',
    gap: '60px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginButton: {
    paddingX: '16px',
    paddingY: '8px',
    boxSizing: 'border-box',
    fontFamily: 'Libre Franklin',
    fontSize: '16px',
    fontWeight: '400',
    color: 'black !important',
    lineHeight: '24px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  getStartedButton: {
    borderRadius: '8px',

    background:
      'conic-gradient(from 220deg at 50.7% 140.75%, #3354FF 29.7deg, #62FDEA 167.6deg)',
    color: 'black',
    paddingX: '16px',
    paddingY: '8px',
    boxSizing: 'border-box',
    fontFamily: 'Libre Franklin',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    cursor: 'pointer',
  },

  // Header Section styles
  headerContainer: {
    width: '100%',

    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headerContent: {
    boxSizing: 'border-box',
    width: '100%',
    paddingX: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingY: '24px',
    gap: '30px',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  titleText: {
    width: '946px',
    height: '144px',
    fontFamily: 'Libre Franklin',
    fontSize: '60px',
    color: '#FFF',
    fontWeight: '700',
    lineHeight: '72px',
    letterSpacing: '0.9px',
    textAlign: 'center',
  },
  subtitleText: {
    width: '669px',
    textAlign: 'center',
    height: '90px',
    fontFamily: 'Libre Franklin',
    fontSize: '20px',
    color: '#FFF',
    fontWeight: '400',
    lineHeight: '30px',
  },
  headerButtonsContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientButton: {
    background:
      'conic-gradient(from 194deg at 66.7% 110.75%, #3354FF 29.74820852279663deg, #62FDEA 147.59999871253967deg, #FFF 260deg)',
    color: 'black',
    borderRadius: '8px',
    paddingX: '16px',
    paddingY: '8px',
    boxSizing: 'border-box',
    fontFamily: 'Libre Franklin',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    cursor: 'pointer',
  },
  demoButton: {
    background: '#FFF',
    color: 'black',
    borderRadius: '8px',
    paddingX: '16px',
    paddingY: '8px',
    boxSizing: 'border-box',
    fontFamily: 'Libre Franklin',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    cursor: 'pointer',
  },
  dashboardContainer: {
    backgroundSize: 'cover',
    display: 'flex', // Ensure content inside the mainbox is flexed
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
    boxSizing: 'border-box',
    backgroundPosition: 'center',
    width: '100%',
    maxWidth: '1120px',
    borderRadius: '10px',
    border: '1px solid rgba(255, 255, 255, 0.10)',
    boxShadow:
      '0px -20px 70px 0px rgba(140, 69, 255, 0.25), 0px -19px 70px 0px rgba(140, 69, 255, 0.40)',
    background:
      'linear-gradient(270deg, #607CE6 0%, rgba(96, 124, 230, 0.00) 50%, #607CE6 100%)',
  },
  dashboardImage: {
    display: 'flex', // Ensure the image is flexed if necessary for layout purposes
    borderRadius: '10px',
    boxSizing: 'border-box',
    width: '100%',
    padding: '10px',
  },
}

export default LandingPageStyle

export const introSectionStyles = {
  container: {
    width: '100%',
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: '44px',
    boxSizing: 'border-box',
  },
  contentWrapper: {
    display: 'flex',
    padding: '44px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    alignSelf: 'stretch',
  },
  logoRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '48px',
  },
  logosWrapper: {
    display: 'flex',
    boxSizing: 'border-box',
    maxWidth: '1204px',
    width: '100%',
    padding: '20px 40px',
    alignItems: 'center',
    gap: '48px',
    position: 'relative',
  },
  gradientLeft: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100px',
    backgroundImage: `
        linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,   
          rgba(255, 255, 255, 0) 100%
        )
      `,
    zIndex: 1,
  },
  gradientRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100px',
    backgroundImage: `
        linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 0%,   
          rgba(255, 255, 255, 0) 100%
        )
      `,
    zIndex: 1,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingX: '0px',
    paddingY: '110px',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  introText: {
    textAlign: 'center',
    width: '936px',
    height: 'auto',
    fontFamily: 'Libre Franklin',
    fontSize: '44px',
    fontWeight: '700',
    lineHeight: 'normal',
    color: 'gray',
  },
  subText: {
    color: 'var(--Text-Text-50, #858585)',
    fontFamily: 'Libre Franklin',
    fontSize: '44px',
    fontWeight: '700',
    lineHeight: 'normal',
  },
  highlightedText: {
    background: 'linear-gradient(270deg, #62FDEA 33.86%, #3354FF 59.39%)',
    backgroundClip: 'text',
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent',
    fontFamily: 'Libre Franklin',
    fontSize: '48px',
    fontWeight: '700',
    textDecorationLine: 'underline',
  },
}

// landingPageStyle.js
export const featureSectionStyles = {
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '60px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
  },
  titleText: {
    width: '936px',
    height: '110px',
    textAlign: 'center',
    fontFamily: 'Libre Franklin',
    fontSize: '44px',
    fontWeight: '700',
    lineHeight: 'normal',
    color: 'gray',
    '& span': {
      textDecoration: 'underline',
      color: 'linear-gradient(to right,  #6C95FC, #62FDEA)',
    },
  },
  highlightedText: {
    background: 'linear-gradient(270deg, #62FDEA 33.86%, #3354FF 59.39%)',
    backgroundClip: 'text',
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent',
    fontFamily: 'Libre Franklin',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
  },
  featureCardContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '32px',
  },
  cardBox: {
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '420px',
    padding: '40px',
    height: '433px',
    maxHeight: '433px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    marginLeft: '5px',
    borderRadius: '24px',
    border: '1px solid rgba(69, 0, 255, 0.20)',
    background: 'linear-gradient(180deg, #E9EDFD 0%, #BDBFEF 100%)',
  },
  cardBox1: {
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '420px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    borderRadius: '24px',
    border: '1px solid rgba(69, 0, 255, 0.20)',
    background: 'linear-gradient(180deg, #E9EDFD 0%, #BDBFEF 100%)',
  },
  cardImageBox: {
    maxWidth: '280px',
    width: '100%',
    maxHeight: '211px',
    height: '100%',
    flexShrink: 0,
    borderRadius: '24px',
    background: '#FFF',
  },
  cardText: {
    color: 'var(--Text-Text, #0C0C0C)',
    fontFamily: 'Libre Franklin',
    fontSize: '30px',
    fontStyle: 'normal',
    alignItems: 'flex-start',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.3px',
  },
  cardDescription: {
    color: 'rgba(0, 0, 0, 0.50)',
    fontFamily: 'Libre Franklin',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
  },
}

export const featureStyles = {
  container: {
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
  },
  featureBox: {
    display: 'flex',
    padding: '16px 20px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '14px',
    border: '1px solid rgba(69, 0, 255, 0.20)',
    background: 'var(--Light-Hover, #DADAFF)',
    justifyContent: 'center',
    textAlign: 'center',
  },
  featureText: {
    color: 'rgba(0, 0, 0, 0.50)',
    fontFamily: 'Libre Franklin',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '25.2px',
    letterSpacing: '-0.18px',
  },
}

// landingPageStyle.js
export const containerStyles = {
  display: 'flex',
  boxSizing: 'border-box',
  padding: '160px 60px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
}

export const featuresWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '60px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}

export const headerWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
}

export const featureCardStyles = {
  display: 'flex',
  boxSizing: 'border-box',
  maxWidth: '420px',
  padding: '40px 32px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '58px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  borderRadius: '24px',
  border: '1px solid rgba(69, 0, 255, 0.10)',
  background: '#F2F2FD',
}

export const featureIconWrapperStyles = {
  boxSizing: 'border-box',
  width: '98px',
  height: '98px',
  background: '#C5C2FFE3',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const titleTextStyles = {
  maxHeight: '31.2px',
  color: 'var(--Text-Text, #0C0C0C)',
  fontFamily: 'Libre Franklin',
  fontSize: '26px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '31.2px',
  letterSpacing: '-0.52px',
}

export const descriptionTextStyles = {
  maxWidth: '341.33px',
  color: 'var(--Text-Text-75, #494949)',
  fontFamily: 'Libre Franklin',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '25.2px',
  letterSpacing: '-0.18px',
}

export const SocialMediaContentStyle = {
  container: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '140px 60px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '60px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  titleText: {
    width: '706px',
    color: 'var(--Text-Text, #0C0C0C)',
    textAlign: 'center',
    fontFamily: 'Libre Franklin',
    fontSize: '44px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  featuresContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
    alignSelf: 'stretch',
  },
  featureBox: {
    display: 'flex',
    boxSizing: 'border-box',
    maxWidth: '420px',
    padding: '40px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '58px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '24px',
    border: '1px solid rgba(69, 0, 255, 0.10)',
    background: '#F2F2FD',
  },
  iconContainer: {
    boxSizing: 'border-box',
    width: '98px',
    height: '98px',
    background: '#C5C2FFE3',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  featureTitle: {
    maxHeight: '31.2px',
    color: 'var(--Text-Text, #0C0C0C)',
    fontFamily: 'Libre Franklin',
    fontSize: '26px',
    fontWeight: '400',
    lineHeight: '31.2px',
    letterSpacing: '-0.52px',
  },
  featureDescription: {
    maxWidth: '341.33px',
    color: 'var(--Text-Text-75, #494949)',
    fontFamily: 'Libre Franklin',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '25.2px',
    letterSpacing: '-0.18px',
    marginTop: '15px',
  },
}

export const BottomSectionStyle = {
  container: {
    display: 'flex',
    padding: '60px 160px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerBox: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '60px',
    justifySelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1060px',
    alignItems: 'center',
    gap: '32px',
    borderRadius: '24px',
    background: `
      radial-gradient(395.27% 77.56% at 50% 34.38%, rgba(255, 255, 255, 0.30) 100%, rgba(255, 255, 255, 0.00) 0%),
      radial-gradient(128.47% 223.47% at 2% 50.5%, #3354FF 10.65%, rgba(51, 84, 255, 0.00) 50%),
      radial-gradient(74.86% 100.16% at 50% 114.06%, #DEEBFF 0%, #62FDEA 100%)
    `,
  },
  titleText: {
    color: 'var(--Text-Text, #0C0C0C)',
    textAlign: 'center',
    fontFamily: 'Libre Franklin',
    maxWidth: '882px',
    fontSize: '54px',
    fontWeight: 500,
    lineHeight: '64.8px',
    letterSpacing: '-1.62px',
  },
  descriptionText: {
    width: '713px',
    color: 'var(--Text-Text-75, #494949)',
    textAlign: 'center',
    fontFamily: 'Libre Franklin',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '-0.2px',
    opacity: 0.9,
  },
  button: {
    color: 'var(--Text-Text, #0C0C0C)',
    fontStyle: 'normal',
    background: '#FFF',
    borderRadius: '8px',
    paddingX: '16px',
    paddingY: '8px',
    fontFamily: 'Libre Franklin',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '27px',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#f0f0f0', // Light gray for hover effect
    },
  },
}

export const FooterStyle = {
  container: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: 'var(--Numbers-Number-15, 30px) 165px', // Use your theme variable
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--Numbers-Number-14, 28px)', // Use your theme variable
    alignSelf: 'stretch',
    background: `url(${require('../../Assets/Images/footerbg.png')})`,
    backgroundSize: 'cover', // Makes the image cover the entire background
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  logoContainer: {
    display: 'flex',
    gap: '9px',
    alignItems: 'center',
  },
  logoBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImage: {
    height: '100%',
    width: '100%',
    filter: 'invert(1)',
  },
  appName: {
    paddingX: '8px',
    boxSizing: 'border-box',
    fontFamily: 'Libre Franklin',
    fontSize: '32px',
    fontStyle: 'normal',
    color: '#0C0C0C',
    fontWeight: '700',
    lineHeight: '72px',
    letterSpacing: '0.48px',
  },
  linkContainer: {
    display: 'flex',
    gap: '32px',
  },
  link: {
    color: 'var(--Text-Text, #0C0C0C)',
    fontVariantNumeric: 'lining-nums proportional-nums',
    fontFamily: 'Libre Franklin',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%', // This can also be '22.4px' if you want to specify the pixel value directly
    cursor: 'pointer',
  },
}

export const horizontalFeaturesStyle = {
  mainbox: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: '32px',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  box2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: {
      md: '20px', // for medium screens like tablets
      lg: '30px', // for large screens like desktops
    },
  },

  card: {
    display: 'flex',
    boxSizing: 'border-box',
    width: {
      md: '500px', // for medium screens like tablets
      lg: '646px', // for large screens like desktops
    },
    height: '210px',
    padding: '40px 0px 40px 40px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: ' var(--Numbers-Number-15, 30px)',
    border: '1px solid rgba(69, 0, 255, 0.20)',
    background: 'linear-gradient(180deg, #E9EDFD 0%, #BDBFEF 100%)',
  },

  cardp1: {
    display: 'flex',
    width: '244px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  },

  heading: {
    color: ' var(--Text-Text, #0C0C0C)',
    fontFamily: 'Libre Franklin',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.26px',
    maxWidth: '204px',
  },

  text: {
    color: 'rgba(0, 0, 0, 0.50)',

    fontFamily: 'Libre Franklin',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '27px' /* 150% */,
  },
  card1p2: {
    width: '300px', // Controls the overall container width
    height: 'auto',

    marginTop: '-20px',
    marginLeft: '60px',
    display: 'flex',
    flexWrap: 'wrap', // Allows items to wrap onto the next line

    overflow: 'hidden', // Ensures that anything outside the box is not visible
  },

  row: {
    display: 'flex',
    gap: '18px',
  },
  icons: {
    display: 'flex',
    width: '80.53px',
    height: '80.53px',
    background: 'rgba(178, 174, 255, 0.89)',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  card2p2: {
    width: '86px',
    height: '86px',
    flexShrink: 0,
    borderRadius: '86px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  card2pic: {
    width: '60px',
    height: '60px',
    flexShrink: 0,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the content
    border: '5px solid #9B97FF',
    backgroundSize: 'cover', // Ensure the image covers the entire area
  },
  pointer: {
    position: 'absolute',
    top: '70px', // Adjust to position it correctly
    left: '160px', // Match with the last circle's left position
    width: '20px', // Set width as needed
    height: '20px', // Set height as needed
    zIndex: 5, // Ensure it's on top of the circles
  },
  name: {
    display: 'inline-flex',
    padding: '5px 14px',
    marginTop: '86px',
    marginLeft: '175px',
    alignItems: 'flex-start',
    borderRadius: '2px 24px 24px 24px',
    background: 'var(--Secondary, #241BFF)',
    boxShadow: '4px 4px 10px 0px rgba(124, 212, 253, 0.16)',
  },
  nametext: {
    width: '40px',
    color: 'var(--White, #FFF)',
    fontFamily: 'Libre Franklin',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px' /* 160% */,
    letterSpacing: '-0.15px',
  },
}
