const workSocialPageStyle = {
  circularProgressBox: {
    position: 'fixed',
    color: 'red',
    marginTop: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  mainContainerBox: {
    width: { xs: '95%', sm: '550px', md: '900px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '10px' },
  },
  crossBtnBox: {
    background: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ml: 'auto',
  },
  crossButton: { height: '35px', width: '35px' },
  typographyMainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  socialMediaMainBox: {
    width: { xs: '95%', xl: '80%' },
    gap: { xs: '16px', sm: '22px' },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: { xs: '30px' },
  },
  mapingDataBox: (item, target) => ({
    cursor: 'pointer',
    height: { xs: '100px', sm: '130px', md: '184px', xl: '184px' },
    width: { xs: '100px', sm: '130px', md: '160px', xl: '160px' },
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: { xs: '10px', sm: '16px' },
    border: '1px solid var(--Text-Text-25, #C2C2C2)',
    backgroundColor: 'var(--Light, #F9F9FF)',
    boxSizing: 'border-box',
    padding: '5px',
    position: 'relative',
  }),
  checkCircleRoundedStyle: {
    color: '#1D7AFC',
    ml: '80%',
    mb: '95%',
    position: 'absolute',
  },

  TypographyMainBox: {
    height: { sm: '20%', md: '30%' },
    width: { sm: '90%' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '95%',
  },
}
export default workSocialPageStyle
export const parentBox = (theme) => ({
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  paddingTop: { xs: '60px', sm: '100px', md: '140px' },
  paddingBottom: '40px',
  backgroundColor: theme.palette.customWhite.main,
})

export const headingTypography = (theme) => ({
  fontWeight: theme.typography.menualText.fontWeight,
  fontSize: {
    xs: theme.typography.h2.fontSize,
    sm: theme.typography.menualText.fontSize,
  },
  color: theme.palette.black.main,
  fontFamily: theme.typography.h1.fontFamily,
  lineHeight: '40px',
})
export const paragraphTypography = (theme) => ({
  color: theme.palette.black.light,
  fontFamily: theme.typography.h3.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  fontSize: {
    xs: theme.typography.h5.fontSize,
    sm: theme.typography.h3.fontSize,
  },
  textAlign: 'center',
  lineHeight: '1',
})
export const headingText = (theme) => ({
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h3.fontFamily,
  lineHeight: 'normal',
  fontSize: {
    xs: theme.typography.h4.fontSize,
    sm: theme.typography.h3.fontSize,
  },
  color: theme.palette.black.main,
})
export const descriptionText = (theme) => ({
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h3.fontFamily,
  lineHeight: 'normal',
  fontSize: theme.typography.paragraph.fontSize,
  color: theme.palette.black.light,
  visibility: { xs: 'hidden', md: 'visible' },
  display: { xs: 'none', md: 'flex' },
})
export const socialPageButton = (theme) => ({
  border: `1px solid ${theme.palette.border.main}`,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h3.fontFamily,
  padding: {
    sm: '10px var(--spacing-16, 32px)',
    lg: '10px var(--spacing-16, 64px)',
  },
  textTransform: 'capitalize',
  width: { xs: '40%', sm: '40%', md: '30%' },
  fontSize: {
    xs: theme.typography.h5.fontSize,
    sm: theme.typography.h3.fontSize,
    lg: theme.typography.h4.fontSize,
  },
  color: theme.palette.black.main,
  borderRadius: '8px',
  mt: { xs: '30px' },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
    color: 'white',
  },
})
