import { useTheme } from '@emotion/react'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import instagram from '../../../Assets/Images/instagram.png'
import linkdin from '../../../Assets/Images/linkedin.png'
import fb from '../../../Assets/Images/facebook.png'

import {
  dateTimeTextStyle,
  info_Notification_Box,
  notificationMenuItemStyle,
  paragraphText,
  viewInvitation_Parent,
} from './notificationStyle'
import { acceptButton } from '../DashboardHeader/dashboardHeaderStyles'

const notifications = [
  {
    platform: instagram,
    message: 'You have been invited to the Dell Workspace by Farhan.',
    time: 'Thursday 3:40pm',
    timeAgo: '2 hours ago',
  },
  {
    platform: linkdin,
    message: 'You have been invited to the Dell Workspace by Farhan.',
    time: 'Thursday 3:40pm',
    timeAgo: '2 hours ago',
  },
  {
    platform: fb,
    message: 'You have been invited to the Dell Workspace by Farhan.',
    time: 'Thursday 3:40pm',
    timeAgo: '2 hours ago',
  },
  {
    platform: linkdin,
    message: 'You have been invited to the Dell Workspace by Farhan.',
    time: 'Thursday 3:40pm',
    timeAgo: '2 hours ago',
  },
]

const AllNotifications = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <>
      {notifications.map((notification, index) => (
        <Box key={index} sx={viewInvitation_Parent(theme)}>
          <img
            src={notification.platform}
            alt='pic'
            height={64}
            width={64}
            style={{ borderRadius: '50%' }}
          />
          <Box sx={info_Notification_Box()}>
            <Typography sx={paragraphText(theme)}>
              You have been invited to the{' '}
              <span style={{ fontWeight: theme.typography.h2.fontWeight }}>
                Dell Workspace
              </span>{' '}
              by{' '}
              <span style={{ fontWeight: theme.typography.h2.fontWeight }}>
                Farhan
              </span>
              .
            </Typography>

            <Box sx={notificationMenuItemStyle.time_Box}>
              <Typography sx={dateTimeTextStyle(theme)}>
                {notification.time}
              </Typography>
              <Typography sx={dateTimeTextStyle(theme)}>
                {notification.timeAgo}
              </Typography>
            </Box>
            <Button
              // onClick={() => navigate('/invitation')}
              variant='contained'
              sx={acceptButton(theme)}
            >
              View Invitation
            </Button>
          </Box>
        </Box>
      ))}
    </>
  )
}

export default AllNotifications
