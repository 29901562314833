import React, { useState } from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from '@mui/material'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@emotion/react'
import {
  mainPropsPlatform,
  menuItemsPlatform,
  platformtext,
  selectPlateform,
  socialPlatforms,
} from '../../homePageStyle'

const platformIcons = {
  Facebook: require('../../../../../Assets/Images/facebook.png'),
  Instagram: require('../../../../../Assets/Images/instagram.png'),
  TikTok: require('../../../../../Assets/Images/tiktok.png'),
}

const PlatformChip = ({ platform, onRemove }) => {
  const theme = useTheme()
  return (
    <Box sx={socialPlatforms.plateformChips}>
      <img
        src={platformIcons[platform]}
        alt={platform}
        className='platform-img'
        style={socialPlatforms.iconImg}
      />
      <Typography sx={platformtext}>{platform}</Typography>
      <IconButton
        onClick={onRemove}
        className='close-icon'
        size='small'
        sx={socialPlatforms.iconButton}
        aria-label={`Remove ${platform}`}
      >
        <CloseIcon
          sx={{
            height: '16px',
            width: '16px',
          }}
        />
      </IconButton>
    </Box>
  )
}

const HomePlatformSelector = () => {
  const [platforms, setPlatforms] = useState([])

  const handleChange = (event) => {
    const value = event.target.value
    if (value && !platforms.includes(value)) {
      setPlatforms((prev) => [...prev, value])
    }
  }

  const handleRemove = (platformToRemove) => {
    setPlatforms((prev) =>
      prev.filter((platform) => platform !== platformToRemove)
    )
  }
  const theme = useTheme()

  return (
    <Box sx={socialPlatforms.main}>
      <Typography sx={socialPlatforms.selectPlatforms_Text(theme)}>
        Select Platforms
      </Typography>
      <Box sx={socialPlatforms.form}>
        <FormControl sx={socialPlatforms.formControl}>
          <Select
            autoFocus={false}
            value=''
            onChange={handleChange}
            displayEmpty
            IconComponent={KeyboardArrowDownRoundedIcon}
            MenuProps={{
              PaperProps: {
                sx: socialPlatforms.selectStyle,
                autoFocus: false,
              },
              autoFocus: false,
            }}
            sx={mainPropsPlatform(theme)}
            renderValue={(selected) =>
              selected.length === 0 ? (
                <span style={selectPlateform(theme)}>Platform</span>
              ) : (
                selected.join(',')
              )
            }
          >
            {Object.keys(platformIcons).map((platform) => (
              <MenuItem
                key={platform}
                value={platform}
                sx={menuItemsPlatform(theme)}
              >
                {platform}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={socialPlatforms.plateformChipsMain}>
          {platforms.map((platform) => (
            <PlatformChip
              key={platform}
              platform={platform}
              onRemove={() => handleRemove(platform)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default HomePlatformSelector
