import React from 'react'
import HeaderSection from './headerSection'
import IntroSection from './introSection'
import FeatureSection from './Features/featureCard'
import { Box } from '@mui/material'
import SocialMediaContent from './socialMediaContent'
import Footer from './footer'

const LandingPage = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <HeaderSection />
      <IntroSection />
      <>
        <FeatureSection />
        <SocialMediaContent />
      </>
      <Footer />
    </Box>
  )
}
export default LandingPage
