import {
  Box,
  FormHelperText,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useContext, useState } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import logoOneImage from './../../../../Assets/Images/rectangleA.png'
import logoTwoImage from './../../../../Assets/Images/rectangleB.png'
import logoThreeImage from './../../../../Assets/Images/rectangleC.png'
import logoFourImage from './../../../../Assets/Images/rectangleD.png'
import imageOne from '../../../../Assets/Images/appLogo.webp'
import imageTwo from './../../../../Assets/Images/rectangleC.png'
import imageThree from '../../../../Assets/Images/welcom.png'
import imageFour from '../../../../Assets/Images/share.png'
import one from '../../../../Assets/Images/facebook.png'
import two from '../../../../Assets/Images/linkedin.png'
import three from '../../../../Assets/Images/google.png'
import four from '../../../../Assets/Images/instagram.png'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Loader } from '@infostacks/ui-components'
import { useForm } from 'react-hook-form'
import { AiWhiteIcon } from '../../../PasswordStartAdornment'
import userEditProfileStyle, {
  commonTextFieldStyle,
} from './userEditProfileStyle'
import {
  aiPromptResolver,
  aiPromptValidator,
} from '../../../../Validators/authValidator'
import { CustomFilterButton } from '../../../ReusableComponents/customButtons'
import { handleGetCreditsCountAPI } from '../../../../API/Stripe'
import UserContext from '../../../../Context/userContext'
import userEditAiModelStyle from './userEditAiModelStyle'

const UserAiImage = ({ open, onClose, selectedImage }) => {
  const [showImages, setShowImages] = useState(false)
  const [totalCredits, setTotalCredits] = useState()
  const [remainingCredits, setRemainingCredits] = useState()
  const [imageIndex, setImageIndex] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [isGenerate, setIsGenerate] = useState(false)
  const { currentUser, userAuth } = useContext(UserContext)
  const spaceId = window.sessionStorage.getItem('spaceId')

  const theme = useTheme()
  const logoArray = [
    logoOneImage,
    logoTwoImage,
    logoThreeImage,
    logoFourImage,
    imageOne,
    imageTwo,
    imageThree,
    imageFour,
    one,
    two,
    three,
    four,
  ]

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = (formData) => {
    console.log('formData', formData)
  }

  const handleGenerateClick = () => {
    if (inputValue.trim()) {
      setLoading(true)
      setTimeout(() => {
        setShowImages(true)
        setImageIndex((prevIndex) =>
          prevIndex + 4 < logoArray.length ? prevIndex + 4 : 0
        )
        setLoading(false)
        setIsGenerate(true)
      }, 2000)
    }
  }

  const handleImageClick = (image) => {
    selectedImage(image)
    onClose()
  }

  const currentImages = logoArray.slice(imageIndex, imageIndex + 4)

  return (
    <Modal open={open} sx={userEditAiModelStyle.model_Style}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={userEditAiModelStyle.form_Style(showImages, theme)}
      >
        <Box sx={userEditAiModelStyle.prompt_Box}>
          <Typography sx={userEditAiModelStyle.promptText(theme)}>
            Write Prompt
          </Typography>
          <IconButton onClick={onClose} sx={{ height: '11px', width: '11px' }}>
            <CloseRoundedIcon color='black' />
          </IconButton>
        </Box>
        <div style={userEditAiModelStyle.field_Box}>
          <TextField
            sx={commonTextFieldStyle(errors.prompt)}
            fullWidth
            placeholder='Ex. Logo for Digital Agency'
            autoComplete='off'
            onInput={(e) => {
              setInputValue(e.target.value)
            }}
            {...register('prompt', aiPromptValidator)}
          />
          <FormHelperText sx={userEditProfileStyle.helperText}>
            {errors.prompt && touchedFields.prompt
              ? aiPromptResolver[errors.prompt.type]
              : ''}
          </FormHelperText>
        </div>
        {loading ? (
          <Box sx={userEditAiModelStyle.loader_Box}>
            <Loader thickness={7} size={30} />
          </Box>
        ) : (
          showImages && (
            <Box sx={userEditAiModelStyle.showImage_Box(showImages)}>
              {currentImages.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    ...userEditAiModelStyle.backgroundImage_Box,
                    backgroundImage: `url(${image})`,
                  }}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </Box>
          )
        )}
        <Box sx={userEditAiModelStyle.footer_Box}>
          <span style={userEditAiModelStyle.spanStyle}>
            <Typography sx={userEditAiModelStyle.footerText(theme)}>
              <span style={{ color: 'red' }}>
                {currentUser?.Subscription?.credits?.total}
              </span>
              <span style={{ color: 'green' }}>
                /{currentUser?.Subscription?.credits?.remaining}
              </span>
            </Typography>
            <ErrorOutlineIcon
              sx={{ color: 'blue', height: '18px', width: '18px' }}
            />
          </span>
          <CustomFilterButton
            icon={<AiWhiteIcon />}
            buttonText={isGenerate ? 'Regenerate' : 'Generate'}
            onClick={handleGenerateClick}
            type='submit'
          />
        </Box>
      </form>
    </Modal>
  )
}

export default UserAiImage
