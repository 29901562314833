import { EmojiEmotionsOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import EmojiPicker from 'emoji-picker-react'
import React, { useState } from 'react'

const AddPicker = ({caption,handleEmojiClick,mediaType,loading}) => {
  const [emojiState, setEmojiState] = useState(false)
  const [char,setChar] = useState(2200)
  const handleChangeEmoji = (event, emojiObject) => {
    // console.log('emoji', event)

    if (handleEmojiClick) {
      handleEmojiClick(event)
    }
    setEmojiState(false)
  }
  return (
          <>
            <Box sx={{display:'flex',height:'fit-content',width:'100%',background:'',alignItems:'center',justifyContent:'space-between',mt:'auto',position: 'relative',}}>
               <IconButton disabled={mediaType==='story' || loading} onClick={()=>setEmojiState(!emojiState)} sx={{boxSizing:'border-box',padding:'0px'}}><EmojiEmotionsOutlined/></IconButton>
               <Typography sx={{color:'#858585',fontSize:'12px',fontWeight:400,lineHeight:'18px',fontFamily:'Libre Franklin'}}>{caption?.length}/{char}</Typography>
            </Box>
                   {emojiState && (
                       <EmojiPicker Reactions={true} lazyLoadEmojis={false} width={350} height={450} theme='dark' emojiStyle='facebook' emojiVersion="0.6" style={{zIndex: '999',top: '300px',right:'290px',position: 'absolute'}} onEmojiClick={handleChangeEmoji} />
                   )}
          </>
  )
}

export default AddPicker