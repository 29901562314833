const styleAdminPageAccordian = {
  AccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    paddingLeft: '6px',
    boxSizing: 'border-box',
  },
  accordianSummry: {
    display: 'flex',
    '.MuiAccordionSummary-content': { gap: '12px' },
  },
}
export default styleAdminPageAccordian
export const accordionStyle = (theme) => ({
  border: `2px solid ${theme.palette.sky.light}`,
  backgroundColor: theme.palette.customWhite.extraLight,
  boxShadow: 'none',
  borderRadius: '8px',
  '&:before': { display: 'none' },
  '&.MuiAccordion-root': { borderRadius: '8px' },
})
export const label_Heading = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWight: theme.typography.h4.fontWight,
  fontFamily: theme.typography.h2.fontFamily,
  fontStyle: 'normal',
  lineWeight: '24px',
  letterSpacing: '0.08px',
})
export const accordian_Details = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 12px',
  boxSizing: 'border-box',
  gap: '6px',
  width: 'auto',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.gray.light}`,
  background: '',
})
export const title_Style = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWight: theme.typography.h4.fontWight,
  fontFamily: theme.typography.h2.fontFamily,
  fontStyle: 'normal',
})
