const styleEditAiModel = {
  aiModel_Style: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 'calc(100vh - 100vh)',
    left: 'calc(100vw - 64vw)',
    bottom: 'calc(100vw - 97vw)',
  },
  closeRoundedIcon_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  field_Style: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  loaderBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '328px',
    width: '100%',
  },
  map_Box: {
    width: '160px',
    height: '160px',
    borderRadius: '8px',
    cursor: 'pointer',
    background: '',
  },
  img_Style: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
    border: '1px solid #CBD4E1',
  },
  footer_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  span_Style: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}
export default styleEditAiModel
export const form_Style = (showImages, theme) => ({
  width: '365px',
  backgroundColor: theme.palette.background.paper,
  boxSizing: 'border-box',
  borderRadius: '16px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  boxShadow: 'none',
  overflow: 'hidden',
  transition: 'max-height 1s ease-in-out',
  maxHeight: showImages ? '400px' : '200px',
})
export const write_Prompt_Style = (theme) => ({
  fontWeight: theme.typography.h2.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontStyle: 'normal',
  lineHeight: '20px',
})
export const mainBox_Style = (showImages) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '8px',
  maxHeight: showImages ? '328px' : '0',
  opacity: showImages ? 1 : 0,
  transition: 'max-height 1s ease-in-out, opacity 1s ease-in-out',
})
export const footer_CreditsText = (theme) => ({
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontStyle: 'normal',
  lineHeight: '20px',
})
