import { Box, Typography, Grid } from '@mui/material'
import React from 'react'
import vector from '../../../Assets/Images/vecto.png'
import { featureStyles } from '../landingPageStyle' // Import styles

function Features() {
  const features = [
    'Feature-1',
    'Feature-2',
    'Feature-3',
    'Feature-4',
    'Feature-5',
    'Feature-6',
    'Feature-7',
  ]

  return (
    <Box sx={featureStyles.container}>
      <Grid container spacing={2} justifyContent='center'>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box sx={featureStyles.featureBox}>
              <img src={vector} width='20px' height='20px' alt={feature} />
              <Typography sx={featureStyles.featureText}>{feature}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Features
