const style = {
    box: {
      // paddingLeft: "16px",
      color: "black",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    link: {
      color: "black",
      textDecoration: "none",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
    },
  };
  
  export default style;