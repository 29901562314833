import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import { activeGoals, textStyle, valueTextStyle } from './styleUserProfile'
import { useTheme } from '@emotion/react'

function ActiveGoals() {
  const value = 3
  const total = 4
  const percentage = (value / total) * 100
  const theme = useTheme()
  return (
    <Box sx={activeGoals.parent_Box(theme)}>
      <Box sx={activeGoals.main_Box}>
        <Typography sx={textStyle(theme)}>Active Goals</Typography>
        <Typography sx={valueTextStyle(theme)}>{value}</Typography>
      </Box>
      <Box sx={activeGoals.circularProgress_Box}>
        <CircularProgress
          variant='determinate'
          value={100}
          thickness={7}
          sx={activeGoals.circularProgress_Style(theme)}
        />
        <CircularProgress
          variant='determinate'
          value={percentage}
          thickness={7}
          sx={activeGoals.percentage_Progress(theme)}
        />
        <Typography sx={activeGoals.percentage_Text(theme)}>
          {`${value}/${total}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default ActiveGoals
