import { DeleteOutline } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import {
  ArchivedSpacesLogo,
  ArchivedSpacesTypography,
  sidebarArchivedSpaces,
} from './sidebarStyle'
import { useTheme } from '@emotion/react'
import UserContext from '../../Context/userContext'

const ArchivedSpaces = ({ drawer, navigation }) => {
  const theme = useTheme()
  const { activeSidebarOption, setActiveSidebarOption } =
    useContext(UserContext)
  const handleArchivedSpaces = (e) => {
    setActiveSidebarOption(e)
    navigation(e)
  }
  return (
    <Box
      onClick={() => handleArchivedSpaces('/dashboard/archived-spaces')}
      sx={sidebarArchivedSpaces(activeSidebarOption, theme)}
    >
      <Box sx={ArchivedSpacesLogo(drawer)}>
        <DeleteOutline sx={{ height: '26px', width: '26px' }} />
      </Box>
      <Typography sx={ArchivedSpacesTypography(drawer, theme)}>
        Archived
      </Typography>
    </Box>
  )
}

export default ArchivedSpaces
