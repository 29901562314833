import React, { forwardRef, useContext, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { Add, Remove, Close } from "@mui/icons-material";
import UserContext from "../../Context/userContext";
import { useForm } from "react-hook-form";
import { Checkbox } from "antd";
import { Loader } from "@infostacks/ui-components";
import { handleBuyAdsOnAPI } from "../../API/Stripe";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import buyMoreCreditsStyle, {
  buyCreditsMembersText,
  cancelButton,
  confirmitionText,
  counting_NumberStyle,
  creditsText,
  custom_styleText,
  descriptionText,
  info_TextStyle,
  members_Text,
  price_NumberStyle,
  purchesButton,
  textStyle,
  totalBill_styleText,
  totalCostPrice,
} from "./buyMoreCreditsStyle";

const BuyMoreCreditsAndSeats = forwardRef(
  ({ setMoreCreditsAndSeatsPopup, handleClick }, ref) => {
    const { currentUser, userAuth, updateUserContext } =
      useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [purchase, setPurchase] = useState(false);
    const [credits, setCredits] = useState(0);
    const [seats, setSeats] = useState(0);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const fullName = currentUser?.fullName;
    const nameParts = fullName ? fullName.split(" ") : [];
    let updatedData;

    const onSubmit = async (data) => {
      setLoading(true);
      try {
        if (currentUser?.Subscription?.planType === "team") {
          updatedData = { ...data, credits: credits, members: seats };
        } else {
          updatedData = { ...data, credits: credits };
        }
        console.log("updatedData", updatedData);
        const resp = await handleBuyAdsOnAPI(userAuth, updatedData);
        console.log("resp", resp);
        if (resp.message === "Adds On Bought Successfully!" && userAuth) {
          const updateUser = await updateUserContext(
            userAuth,
            { fullName: currentUser?.fullName },
            currentUser?._id
          );
          setLoading(false);
          setMoreCreditsAndSeatsPopup(false);
          handleClick();
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const handleCheckBox = (e) => {
      setPurchase(e);
    };

    const creditCost = 1;
    const seatCost = 3.99;

    const totalCost = credits * creditCost + seats * seatCost;
    const totalCreditCost = credits * creditCost;
    const theme = useTheme();
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={buyMoreCreditsStyle.form_Style}
      >
        <Box sx={buyMoreCreditsStyle.header_Box}>
          <Typography sx={buyCreditsMembersText(theme)}>
            Buy Credits & Members
          </Typography>
          <IconButton
            disabled={loading}
            onClick={() => setMoreCreditsAndSeatsPopup(false)}
          >
            <Close />
          </IconButton>
        </Box>

        <Typography sx={descriptionText(theme)}>
          Credits are used for AI interactions. Seats allow additional team
          members to access the platform.
        </Typography>

        <Typography sx={creditsText(theme)}>Credits:</Typography>
        <Box sx={buyMoreCreditsStyle.credits_Box}>
          <Box sx={buyMoreCreditsStyle.field_Box}>
            <Typography sx={counting_NumberStyle(theme)}>{credits}K</Typography>
            <Typography sx={price_NumberStyle(theme)}>
              $ {(credits * creditCost).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={buyMoreCreditsStyle.icon_Box}>
            <IconButton
              disabled={credits === 0 || loading}
              onClick={() => setCredits(Math.max(0, credits - 1))}
            >
              <Remove />
            </IconButton>
          </Box>
          <Box sx={buyMoreCreditsStyle.icon_Box}>
            <IconButton
              disabled={loading}
              onClick={() => setCredits(credits + 1)}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
        <Typography sx={info_TextStyle(theme)}>
          (${creditCost} for every 1 thousand credits)
        </Typography>
        {currentUser?.Subscription?.planType === "team" ? (
          <>
            <Typography sx={members_Text(theme)}>Members:</Typography>
            <Box sx={buyMoreCreditsStyle.credits_Box}>
              <Box sx={buyMoreCreditsStyle.field_Box}>
                <Typography sx={counting_NumberStyle(theme)}>
                  {seats}
                </Typography>
                <Typography sx={textStyle(theme)}>
                  $ {(seats * seatCost).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={buyMoreCreditsStyle.icon_Box}>
                <IconButton
                  disabled={seats === 0 || loading}
                  onClick={() => setSeats(Math.max(0, seats - 1))}
                >
                  <Remove />
                </IconButton>
              </Box>
              <Box sx={buyMoreCreditsStyle.icon_Box}>
                <IconButton
                  disabled={loading}
                  onClick={() => setSeats(seats + 1)}
                >
                  <Add />
                </IconButton>
              </Box>
            </Box>
            <Typography sx={info_TextStyle(theme)}>
              (${seatCost.toFixed(2)} for every member)
            </Typography>
          </>
        ) : null}

        <Box sx={buyMoreCreditsStyle.recepit_Box}>
          <Box sx={buyMoreCreditsStyle.field_Box}>
            <Typography sx={custom_styleText(theme)}>
              Credits to purchase:
            </Typography>
            <Typography sx={totalBill_styleText(theme)}>
              {credits.toLocaleString()}
            </Typography>
          </Box>
          {currentUser?.Subscription?.planType === "team" ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={custom_styleText(theme)}>
                Members to purchase:
              </Typography>
              <Typography sx={totalBill_styleText(theme)}>{seats}</Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={custom_styleText(theme)}>Total cost:</Typography>
            <Typography sx={totalCostPrice(theme)}>
              $
              {currentUser?.Subscription?.planType === "team"
                ? totalCost.toFixed(2)
                : totalCreditCost.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={buyMoreCreditsStyle.main_CheckBox}>
          <Checkbox
            disabled={loading || (credits < 1 && seats < 1)}
            onChange={() => handleCheckBox(!purchase)}
          />
          {currentUser?.Subscription?.planType === "team" ? (
            <Typography sx={confirmitionText(theme)}>
              I confirm that I want to purchase <b>{credits}K</b> AI Credits and{" "}
              {""}
              <b>{seats}</b> members.
            </Typography>
          ) : (
            <Typography sx={confirmitionText(theme)}>
              I confirm that I want to purchase <b>{credits} K</b> AI Credits.
            </Typography>
          )}
        </Box>

        <Box sx={buyMoreCreditsStyle.footer_Box}>
          <Button
            variant="outlined"
            disabled={loading}
            sx={cancelButton(theme)}
            onClick={() => setMoreCreditsAndSeatsPopup(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!purchase || (credits === 0 && seats === 0)}
            type="submit"
            variant="contained"
            sx={purchesButton(theme)}
          >
            {loading ? <Loader size={22} thickness={7} /> : "Purchase"}
          </Button>
        </Box>
      </form>
    );
  }
);
BuyMoreCreditsAndSeats.displayName = "BuyMoreCreditsAndSeats";
export default BuyMoreCreditsAndSeats;
