const workSpaceMemberModalStyle = {
  modal_Style: {
    width: '600px',
    backgroundColor: '',
    height: 'fit-content',
    mx: 'auto',
    my: 'auto',
    borderRadius: '16px',
  },
  main_Box: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    padding: '20px',
    borderRadius: '16px',
    gap: '10px',
  },
  permisstionBox: {
    display: 'flex',
    width: '100%',
    background: '',
    justifyContent: 'space-between',
  },
  scrollbar_Box: {
    background: '',
    width: '100%',
    maxHeight: '300px',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  infoBox_Style: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    background: '',
    justifyContent: '',
    height: 'fit-content',
    gap: '10px',
  },
  second_Box: {
    display: 'flex',
    background: '',
    alignItems: 'center',
    gap: '20px',
  },
}
export default workSpaceMemberModalStyle
export const permission_Text = (theme) => ({
  lineHeight: 'normal',
  fontSize: theme?.typography.h2.fontSize,
  fontWeight: theme?.typography.h1.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
})
export const desc_Text = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.light,
  background: '',
  lineHeight: '24px',
})
export const scrollbar_Box = () => ({
  background: '',
  width: '100%',
  maxHeight: '300px',
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
})
export const permission_MapBox = (theme) => ({
  background: theme?.palette.gray.main,
  border: `1px solid ${theme.palette.sky.light}`,
  borderRadius: '16px',
  display: 'flex',
  boxSizing: 'border-box',
  padding: '10px',
  gap: '16px',
  alignItems: 'center',
})
export const websiteLink_Text = (theme) => ({
  fontSize: theme?.typography.paragraph.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  lineHeight: 'normal',
})
export const spaceName_Text = (theme) => ({
  fontSize: theme?.typography.mediumText.fontSize,
  fontWeight: theme?.typography.h1.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  lineHeight: 'normal',
})
export const changeRoleModelStyle = {
  modal_Style: {
    width: '600px',
    backgroundColor: '',
    height: 'fit-content',
    mx: 'auto',
    my: 'auto',
    borderRadius: '16px',
  },
  loader_Box: {
    position: 'fixed',
    color: '',
    top: '60%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  changRole_MainBox: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    padding: '20px',
    borderRadius: '16px',
    gap: '10px',
  },
  icon_Box: {
    display: 'flex',
    width: '100%',
    background: '',
    justifyContent: 'space-between',
  },
  formBox: {
    background: '',
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  form_Style: {
    backgroundColor: '',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  menuitem_Style: {
    height: '44px',
    mb: '6px',
    borderRadius: '8px',
    border: '1px solid var(--Light-Hover, #E5E7EB)',
    '&:hover': {
      border: '1px solid var(--Light-Hover, #241BFF)',
      backgroundColor: 'white',
    },
    textField_Main_Box: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '6px',
      backgroundColor: '',
    },
    helper_Text: {
      position: 'absolute',
      bottom: '-20px',
      color: 'red',
    },
  },
  button_Box: {
    display: 'flex',
    justifyContent: 'end',
    gap: '12px',
    alignItems: 'end',
    boxSizing: 'border-box',
    background: '',
  },
}
export const changeRole_Text = (theme) => ({
  fontSize: theme?.typography.h2.fontSize,
  fontWeight: theme?.typography.h1.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  background: '',
  lineHeight: 'normal',
})
export const roleText_Text = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.light,
  lineHeight: '24px',
})
export const textfield = () => ({
  background: '#FFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C6C6CE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C6C6CE',
    },
    '& .MuiOutlinedInput-input': {
      height: { xs: '15px', sm: '10px', md: '10px' },
    },
  },
})
export const saveChangeBtn = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '12px',
  border: '1px solid var(--Light-Hover, #DADAFF)',
  zIndex: 5,
  color: theme?.palette.customWhite.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '0.075px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
  background: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transition: 'background-color 0.2s ease-in-out',
    color: 'white',
  },
})
export const discardBtn = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '12px',
  border: '1px solid var(--Light-Hover, #DADAFF)',
  zIndex: 5,
  color: theme?.palette.customWhite.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '0.075px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
  background: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transition: 'background-color 0.2s ease-in-out',
    color: theme.palette.customWhite.main,
  },
})
export const commonSelectStyle = (error) => ({
  background: '#FFF',
  borderRadius: '8px',
  height: '42px',
  paddingLeft: '0px',
  // color:'#a0a0a4',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : '#241BFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '& .MuiSelect-select': {
    // height: "15px",
    color: '', // Ensure the color is set correctly or remove it
  },
})
export const labelTextfield = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.black.main,
  width: 'auto',
  marginRight: 'auto',
  lineHeight: '20px',
})
export const menuRole_Style = (theme) => ({
  borderRadius: '12px',
  backgroundColor: theme?.palette?.customWhite?.main || '#ffffff',
  padding:
    'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px) ',
  border: `1px solid ${theme?.palette?.gray?.light || '#d3d3d3'}`,
  boxShadow:
    ' 0px 10px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 3px 0px rgba(0, 0, 0, 0.10)    ',
})
