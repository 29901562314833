import { Avatar, AvatarGroup, Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import img from './../../Assets/Images/signupImage.png'
import avatar from './../../Assets/Images/Pic.png'
import { userAllWorkspaces } from '../../API'
import UserContext from '../../Context/userContext'
import { Loader } from '@infostacks/ui-components'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import chooseWorkspaceStyle from './chooseWorkspaceStyle'

const ChooseWorkspace = () => {
  const { userAuth, currentUser } = useContext(UserContext)
  const [accepted, setAccepted] = useState([])
  const [pending, setPending] = useState([])
  const [loading, setLoading] = useState(false)
  console.log('accepted workspaces', accepted)
  console.log('pending workspaces', pending)

  const [option, setOption] = useState('workspace')
  const navigate = useNavigate()
  const handleOptions = (state) => {
    setOption(state)
  }

  const handleNavigate = (id) => {
    window.sessionStorage.setItem('spaceId', id)
    navigate('/dashboard')
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const data = await userAllWorkspaces(userAuth)
        setPending(data.filter((val) => val.status === 'pending'))
        setAccepted(data.filter((val) => val.status === 'accepted'))
        setLoading(false)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()
  }, [option])
  const theme = useTheme()
  return (
    <Box sx={chooseWorkspaceStyle.parent_Box(theme)}>
      <Box sx={chooseWorkspaceStyle.container_Box}>
        <Box sx={chooseWorkspaceStyle.firstBox}>
          <Typography sx={chooseWorkspaceStyle.welcome_Text_Style(theme)}>
            👋 Welcome back
          </Typography>
          <Box sx={chooseWorkspaceStyle.workspace_Box}>
            <Typography
              sx={chooseWorkspaceStyle.workspaceName_Text_Style(theme)}
            >
              Workspaces for {currentUser?.email}
            </Typography>
            <Button
              onClick={() => handleOptions('pending')}
              variant='text'
              sx={chooseWorkspaceStyle.pendingInvitation_Button(
                option,
                pending,
                theme
              )}
            >
              View Pending Invitations
            </Button>
            <Button
              onClick={() => handleOptions('workspace')}
              variant='text'
              sx={chooseWorkspaceStyle.viewWorkspaces_Button(
                option,
                pending,
                theme
              )}
            >
              View Workspaces
            </Button>
          </Box>
        </Box>
        {loading ? (
          <Box sx={chooseWorkspaceStyle.loader_Box}>
            <Loader thickness={6} sx={{ color: 'blue' }} />
          </Box>
        ) : (
          <>
            {option === 'workspace' ? (
              <Box sx={chooseWorkspaceStyle.optionWorkspace_Box}>
                {accepted.map((val, ind) => (
                  <Box key={ind} sx={chooseWorkspaceStyle.map_Containor(theme)}>
                    <img
                      src={val.workspaceImageUrl}
                      alt=''
                      width={88}
                      height={80}
                      style={{ borderRadius: '0px' }}
                    />
                    <Box sx={chooseWorkspaceStyle.main_Box}>
                      <Typography
                        sx={chooseWorkspaceStyle.workspaceName(theme)}
                      >
                        {val.workspaceName}
                      </Typography>
                      <Box sx={chooseWorkspaceStyle.avatr_Box}>
                        <Typography
                          sx={chooseWorkspaceStyle.roleName_Text(theme)}
                        >
                          {val.roleName}
                        </Typography>
                        <AvatarGroup
                          spacing={'medium'}
                          max={5}
                          sx={{ gap: '1px' }}
                        >
                          {val.workspaceMembers.map((val, ind) => (
                            <Avatar
                              key={ind}
                              variant='circular'
                              sx={{ height: '20px', width: '20px' }}
                              alt='Travis Howard'
                              src={val.imageUrl}
                            />
                          ))}
                        </AvatarGroup>
                        <Typography
                          sx={chooseWorkspaceStyle.memberName_Text(theme)}
                        >
                          {val.workspaceMembers?.length} members
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      onClick={() => handleNavigate(val.workspcaeId)}
                      sx={chooseWorkspaceStyle.countine_Button(theme)}
                    >
                      Continue
                    </Button>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={chooseWorkspaceStyle.pendindWorkspace_Box}>
                {pending.map((val, ind) => (
                  <Box key={ind} sx={chooseWorkspaceStyle.map_Containor(theme)}>
                    <img
                      src={val.workspaceImageUrl}
                      alt=''
                      width={88}
                      height={80}
                    />
                    <Box sx={chooseWorkspaceStyle.main_Box}>
                      <Typography
                        sx={chooseWorkspaceStyle.workspaceName(theme)}
                      >
                        {val.workspaceName}
                      </Typography>
                      <Typography
                        sx={chooseWorkspaceStyle.desc_Text_Style(theme)}
                      >
                        {'You’ve been invited to join the this Workspace'}
                      </Typography>
                    </Box>
                    <Box sx={chooseWorkspaceStyle.button_Box}>
                      <Button
                        sx={chooseWorkspaceStyle.rejactButton_Style(theme)}
                      >
                        Reject
                      </Button>
                      <Button
                        sx={chooseWorkspaceStyle.acceptButton_Style(theme)}
                      >
                        Accept
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default ChooseWorkspace
