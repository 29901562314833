import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react'

const EditSchedulePostFooter = ({
  handleOptionSelect,
  setTimeModal,
  formatDate,
  scheduleTime,
  inputStr,
  images,
}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        width: '95%',
        background: '',
        height: '50px',
        alignItems: 'center',
        visibility: inputStr || images.length > 0 ? 'visible' : 'hidden',
      }}
    >
      <Box sx={{ background: '', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
          {' '}
          Schedule date:
        </Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
          {formatDate(scheduleTime)}
        </Typography>
        <Button
          variant='outlined'
          onClick={() => setTimeModal(true)}
          sx={{
            textTransform: 'capitalize',
            color: theme.palette.primary.dark,
            ml: '20px',
            padding: '0px',
            paddingX: '12px',
            height: '35px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h2.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            lineHeight: 'normal',
            letterSpacing: '0.06px',
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.dark}`,
            ':hover': {
              border: `1px solid ${theme.palette.primary.dark}`,
              backgroundColor: 'transparent',
            },
          }}
        >
          Edit Schedule
        </Button>
      </Box>

      <Box sx={{ ml: 'auto', display: 'flex', gap: '20px', background: '' }}>
        <Button
          type='submit'
          onClick={() => handleOptionSelect('Edit')}
          variant='contained'
          sx={{
            textTransform: 'capitalize',
            color: '',
            boxSizing: 'border-box',
            padding: '0px',
            height: '35px',
            paddingX: '12px',
            borderRadius: '8px',
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h2.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            background: theme.palette.primary.dark,
            lineHeight: 'normal',
          }}
        >
          Save edit
        </Button>
        <Button
          type='submit'
          onClick={() => handleOptionSelect('scheduleToPublish')}
          variant='contained'
          sx={{
            textTransform: 'capitalize',
            color: '',
            boxSizing: 'border-box',
            padding: '0px',
            height: '35px',
            paddingX: '12px',
            borderRadius: '8px',
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h2.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            background: theme.palette.primary.dark,
            lineHeight: 'normal',
          }}
        >
          Publish
        </Button>
      </Box>
    </Box>
  )
}

export default EditSchedulePostFooter
