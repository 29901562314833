import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { instagramStyle } from "../instagramStyle";
import Header from "./Header/header";
import card1 from "./../../../../../Assets/Images/fcard.png";
import card2 from "./../../../../../Assets/Images/scard.png";
import InstagramPostModal from "../InstagramPostModal";
import Postcard from "./PostCard";
import NoPost from "../NoPost/Index";
import UserContext from "../../../../../Context/userContext";
import { Loader } from "@infostacks/ui-components";
import noData from "./../../../../../Assets/Images/noData.jpg";
import { instagramGetAllPostsApi } from "../../../../../API/PlatformsAPIs/InstagramApi";
import { useParams } from "react-router-dom";

const InstagramPosts = () => {
  const { setAiModalOpen, userAuth } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [instaModal, setInstaModal] = useState(false);
  const [filterBtns, setFilterBtns] = useState("Posted");
  const [postArray, setPostArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, igUserId } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setInstaModal(false);
    setAnchorEl(false);
    setAiModalOpen(false);
  };
  const Posts = [
    {
      image: card2,
      des: "4K Epic Itachi Animated Wallpaper | Stunning Visuals for Your Desktop",
      time: "23 hours ago",
      views: "112k views",
      likes: "115k likes",
      comments: "131k comments",
    },
    {
      image: card1,
      des: "4K Epic Itachi Animated Wallpaper | Stunning Visuals for Your Desktop",
      time: "23 hours ago",
      views: "12k views",
      likes: "51k likes",
      comments: "13k comments",
    },
    {
      image: card2,
      des: "4K Epic Itachi Animated Wallpaper | Stunning Visuals for Your Desktop",
      time: "23 hours ago",
      views: "12k views",
      likes: "15k likes",
      comments: "13k comments",
    },
  ];

  const getInstaPosts = async () => {
    setLoading(true);
    try {
      const resp = await instagramGetAllPostsApi(userAuth, id, igUserId);
      console.log(
        "resp",
        resp.filter((val, ind) => val.status === "posted")
      );
      console.log(filterBtns);
      if (filterBtns === "Posted") {
        setPostArray(resp.filter((val, ind) => val.status === "posted"));
      } else setPostArray([]);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      getInstaPosts();
    };
    fetchPost();
  }, [filterBtns]);

  return (
    <Box sx={instagramStyle.parentContainer}>
      {/* {postArray.length === 0 ? (
        <NoPost setInstaModal={setInstaModal} />
      ) : ( */}
      <>
        <Header
          setInstaModal={setInstaModal}
          filterBtns={filterBtns}
          setFilterBtns={setFilterBtns}
        />
        <Box sx={instagramStyle.postCardsParent}>
          {loading ? (
            <Box sx={instagramStyle.circularProgressBox}>
              <Loader thickness={6} />
            </Box>
          ) : postArray.length === 0 ? (
            <img
              src={noData}
              alt="no data"
              style={instagramStyle.noDataImage}
            />
          ) : (
            postArray.map((val, ind) => (
              <Postcard
                key={ind}
                val={val}
                anchorEl={anchorEl}
                open={open}
                handleClick={handleClick}
                handleClose={handleClose}
              />
            ))
          )}
        </Box>
      </>
      {/* )} */}
      {instaModal ? (
        <InstagramPostModal instaModal={instaModal} handleClose={handleClose} />
      ) : null}
    </Box>
  );
};

export default InstagramPosts;
