import { Box, FormHelperText, TextField } from '@mui/material'
import React from 'react'
import {
  aiPromptResolver,
  aiPromptValidator,
} from '../../../../../Validators/authValidator'

const AiPromptField = ({ register, errors, touchedFields,textPrompt,setTextPrompt }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        background: '',
        borderRadius: '10px',
        border: '1px solid #E5E7EB',
        gap: '10px',
        boxSizing: 'border-box',
        paddingBottom: '10px',
        mt: '5px',
      }}
    >
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          background: '',
        }}
      >
        <TextField
        value={textPrompt}
        onChange={(e)=>setTextPrompt(e.target.value)}
          variant='standard'
          placeholder='Type your prompt . . .'
          fullWidth
          sx={{
            background: '#FAFBFC',
            width: '95%',
            mx: 'auto',
            overflowY: 'auto',
            '& textarea': {
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            },
          }}
          InputProps={{
            rows: 4,
            multiline: true,
            inputComponent: 'textarea',
            disableUnderline: true,
          }}
          // {...register('aiPrompt', aiPromptValidator)}
        />

        {/* <FormHelperText
          sx={{
            position: 'absolute',
            bottom: '-30px',
            color: 'red',
            ml: '0px',
          }}
        >
          {errors.aiPrompt && touchedFields.aiPrompt
            ? aiPromptResolver[errors.aiPrompt?.type]
            : ''}
        </FormHelperText> */}
      </div>
    </Box>
  )
}

export default AiPromptField
