const allPlatformsStyles = {
  parentDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    background: '',
    boxSizing: 'border-box',
    padding: '15px',
    overflowY: 'hidden',
  },
  platformHeader: {
    display: { xs: 'flex' },
    justifyContent: 'space-between',
    background: '',
    boxSizing: 'border-box',
    width: { xs: '100%', sm: '99%' },
    paddingY: { xs: '8px', sm: '10px' },
  },

  iconBox: {
    width: '100%',
    backgroundColor: '',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  cardFooter: {
    height: '40px',
    width: '100%',
    backgroundColor: '',
    gap: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },

  MenuTag_Parent: {
    borderRadius: '12px',
    paddingY: { xs: '2px', sm: '4px' },
    paddingX: { xs: '8px', sm: '8px' },
    border: '1px solid var(--Overlays-Dropdowns-Border-Color, #E5E7EB)',
    boxShadow:
      '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
  },
  menuItem_services_disconnect: {
    fontWeight: '',
    '&:hover': {
      backgroundColor: '#F3F4F6',
      borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)', // Change background color on hover
    },
  },
}
export default allPlatformsStyles

export const connectBtn = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  color: theme?.palette.primary.light,
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  borderRadius: '6px',
})

export const connectedBtn = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  color: theme?.palette.customWhite.main,
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  borderRadius: '6px',
  background: theme?.palette.primary.light,
  '&:hover': { backgroundColor: theme?.palette.primary.light },
  cursor: 'not-allowed',
})

export const moreMenuOptionsStyle = {
  MenuTag_Parent: {
    borderRadius: '12px',
    backgroundColor: 'var(--Overlays-Dropdowns-Bg-Color, #FFF)',
    paddingY: { xs: '2px', sm: '4px' },
    paddingX: { xs: '8px', sm: '8px' },
    border: '1px solid var(--Overlays-Dropdowns-Border-Color, #E5E7EB)',
    boxShadow:
      '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
  },
  menuItem_Edit_Archive: {
    fontWeight: '',
    '&:hover': {
      backgroundColor: '#F3F4F6',
      borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)', // Change background color on hover
    },
  },
}
export const allPlatformsHeaderText = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.mediumText.fontSize,
  },
  fontWeight: {
    xs: theme.typography.h2.fontWeight,
    sm: theme?.typography.menualText.fontWeight,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
})
export const card_Main = (theme) => ({
  width: {
    xs: 'auto',
    sm: '360px',
  },
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  gap: { xs: '15px' },
  borderRadius: '16px',
  border: `1px solid ${theme.palette.sky.light}`,
  backgroundColor: theme?.palette?.gray.main,
  boxSizing: 'border-box',
  boxShadow:
    '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
})
export const name = (theme) => ({
  fontSize: theme.typography.mediumText.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
})
export const description = (theme) => ({
  width: '100%',
  alignItems: 'center',
  gap: 'var(--Components-Badge-Placeholder-Gap-Between, 7px)',
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  color: theme?.palette.black.light,
  lineHeight: 'normal',
})
export const footerText = (theme) => ({
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.main,
})
