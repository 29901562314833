import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { Modal } from "antd";
import React, { useContext, useRef, useState } from "react";
import { postModel } from "../instagramStyle";
import UserContext from "../../../../../Context/userContext";
import AiPostModal from "../../../../ReusableComponents/ReusableAiPostModal/aiPostModal";
import ManualPostForm from "./ManualPostForm";
import { dataType64toFile } from "../../../../../API/PlatformsAPIs/Facebook";

const InstagramPostModal = ({ instaModal, handleClose }) => {
  const [media, setMedia] = useState([]);
  const [caption, setCaption] = useState("");
  const theme = useTheme();
  const textRef = useRef(null);
  const { aiModalOpen } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleInsert = (data) => {
    const cursor = textRef?.current?.selectionStart;
    const updatedText = caption.slice(cursor) + data.text;
    setCaption(updatedText);
    const newCursor = cursor + data?.text?.length;
    setTimeout(
      () => textRef?.current?.setSelectionRange(newCursor, newCursor),
      0
    );
    if (data.image) {
      const res = dataType64toFile(data.image);
      const fileUrl = URL.createObjectURL(res);
      setMedia((prevImages) => [...prevImages, { file: res, url: fileUrl }]);
    }
  };

  return (
    <Modal
      open={instaModal}
      onCancel={handleClose}
      footer={null}
      centered
      width="fit-content"
      maskClosable={false}
      closable={false}
      style={{ backgroundColor: "" }}
    >
      <Box sx={postModel.instagramModalsParentBox}>
        {aiModalOpen && <AiPostModal handleInsert={handleInsert} />}
        <ManualPostForm
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          media={media}
          setMedia={setMedia}
          caption={caption}
          setCaption={setCaption}
        />
      </Box>
    </Modal>
  );
};

export default InstagramPostModal;
