export const googleServiceStyle = {
  parentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '',
    padding: '20px',
    boxSizing: 'border-box',
    gap: '24px',
  },
  GoogleServicesHeader: {
    display: { xs: 'flex' },
    justifyContent: 'space-between',
    background: '',
    boxSizing: 'border-box',
    width: { xs: '100%' },
    // paddingY: { xs: '8px', sm: '2px' },
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '',
  },

  menu_Style: {
    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#F8F9FF',
    paddingY: '6px',
    paddingX: '10px',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
  },
  unarchive_Space_Text: {
    borderRadius: '4px',
    padding: '6px',
    boxSizing: 'border-box',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#DADAFF' },
  },
}
export const googleServicesHeaderText = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontWeight: {
    xs: theme.typography.h2.fontWeight,
    sm: theme?.typography.menualText.fontWeight,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  letterSpacing: '-1.2px',
})
export const card_Main = (theme) => ({
  width: {
    xs: 'auto',
    sm: '360px',
  },
  display: 'flex',
  flexDirection: 'column',
  padding: ' var(--Numbers-Number-9, 18px) 24px',
  gap: { xs: '16px' },
  borderRadius: '16px',
  border: `1px solid ${theme.palette.sky.light}`,
  backgroundColor: theme?.palette?.gray.main,
  boxSizing: 'border-box',
  boxShadow:
    '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
})
export const name = (theme) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: '27px',
  fontStyle: 'normal',
})
export const description = (theme) => ({
  width: '100%',
  alignItems: 'center',
  gap: 'var(--Components-Badge-Placeholder-Gap-Between, 7px)',
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  color: theme?.palette.black.light,
  lineHeight: '24px',
  fontStyle: 'normal',
})
export const footerText = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  color: theme?.palette.black.light,
  lineHeight: '24px',
  fontStyle: 'normal',
})
export const integratedText = (theme) => ({
  padding: '4px 6px',
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--Components-Badge-Placeholder-Gap-Between, 7px)',
  fontSize: theme?.typography.paragraph.fontSize,
  fontWeight: theme?.typography.h4.fontWeight,
  borderRadius: 'var(--Components-Badge-Border-Radius, 6px)',
  border: `1px solid ${theme?.palette.primary.light}`,
  background: theme?.palette.sky.dark,
})
