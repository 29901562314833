const makeHeader = (method, token, body) => {
  var myHeaders = new Headers()
  myHeaders.append('Authorization', `Bearer ${token}`)
  myHeaders.append('ngrok-skip-browser-warning', true)
  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: 'follow',
  }
  if (method !== 'GET') {
    // Only add Content-Type for non-FormData bodies
    if (!(body instanceof FormData)) {
      myHeaders.append('Content-Type', 'application/json');
      requestOptions.body = JSON.stringify(body);
    } else {
      // Assign FormData directly if it's a FormData object
      requestOptions.body = body;
    }
  }
  return requestOptions
}

export default makeHeader



// const makeHeader = (method, token, body) => {
//   var myHeaders = new Headers()
//   myHeaders.append('Authorization', `Bearer ${token}`)
//   myHeaders.append('ngrok-skip-browser-warning', true)
//   var requestOptions = {
//     method: method,
//     headers: myHeaders,
//     redirect: 'follow',
//   }
//   if (method !== 'GET') {
//     myHeaders.append('Content-Type', 'application/json')
//     requestOptions.body = JSON.stringify(body)
//   }
//   return requestOptions
// }

// export default makeHeader

