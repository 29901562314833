const dashboardHeaderStyles = {
  midMainBox: {
    backgroundColor: '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    gap: { xs: '4px', sm: '8px', md: '4px', lg: '16px', xl: '19px' },
  },

  searchBox: {
    backgroundColor: '',
    height: '44px',
    borderRadius: '22px',
    display: 'flex',
    alignItems: 'center',
    paddingX: '20px',
    boxSizing: 'border-box',
    border: '1.5px solid var(--Text-Text-50, #858585)',
    width: { xs: '160px', sm: '100%' },
  },

  inputfieldBox: {
    border: 'none',
    outline: 'none',
    backgroundColor: '',
    width: '100%',
  },

  ButtonsMainBox: {
    display: 'flex',
    // gap: '10px',
    background: '',
    // borderRadius:'50%',
    boxSizing: 'border-box',
    padding: '0px',
    // width: { sm: '100px', md: 'auto', lg: 'auto', xl: 'auto' },
    alignItems: 'center',
    justifyContent: 'center',
  },

  userInfoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '',
  },

  button: {
    padding: '10px',
    boxSizing: 'border-box',
    background: 'pink',
    cursor: 'pointer',
  },

  buttonUserProfile: {
    borderRadius: '50%',
    border: '1.5px solid var(--Text-Text-50, #858585)',
    background: '',
    height: '40px',
    width: '40px',
    cursor: 'pointer',
  },

  main_Menu_Box: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    background: 'purple',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
  },
  imageBoxStyle: {
    background: '',
    display: 'flex',
    gap: '12px',
    boxSizing: 'border-box',
    padding: '  var(--Numbers-Number-0px, 12px)',
    width: '354px',
    borderRadius: '12px',
  },
  userImage: {
    borderRadius: '50%',
    border: '1.5px solid var(--Text-Text-50, #858585)',
    background: '',
    height: '46px',
    width: '46px',
    cursor: 'pointer',
    objectFit: 'cover',
  },

  user_Info_Box: {
    height: '40px',
    width: '46px',
    borderRadius: '50%',
    background: 'purple',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
  },
  custom_Style: {
    height: '20px',
    width: '20px',
    color: 'gray',
  },
  spanStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '',
  },
  planBox_Style: (theme) => ({
    border: `1px solid var(--Tertiary-Colors-Success-On-Success-Surface, #22A06B)`,
    background: 'var(--Tertiary-Colors-Success-Success-Surface, #DFFCF0)',
    fontFamily: theme?.typography.h4.fontFamily,
    boxSizing: 'border-box',
    padding: '2px 8px',
    borderRadius: 'var(--Numbers-Number-15, 30px)',
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontStyle: 'normal',
    lineHeight: '20px',
    width: 'fit-content',
    textTransform: 'capitalize',
  }),
  devider_Box: {
    boxSizing: 'border-box',
    padding: '4px',
  },
  devider_Style: (theme) => ({
    borderRadius: '3px',
    background: theme.palette.border.light,
    width: '100%',
    height: '1px solid',
  }),
  badge_Style: (theme) => ({
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.dark,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
}

export default dashboardHeaderStyles
export const main_Box = (theme) => ({
  boxSizing: 'border-box',
  paddingY: '20px',
  paddingX: '20px',
  backgroundColor: theme?.palette?.customWhite.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid #E5E5E5`,
})
export const itemsDropDown = (theme) => ({
  marginTop: '56px',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding:
    'var(--Overlays-Dropdowns-List-Spacing-Y, 4px) var(--Overlays-Dropdowns-List-Spacing-X, 12px) ',
  backgroundColor: theme?.palette?.customWhite.main,
  border: `1px solid ${theme.palette.border.main}`,
  boxShadow:
    '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
})
export const profileMenuItem = (theme) => ({
  fontSize: theme.typography.h3.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  color: '#33333A',
  lineHeight: '27px',
  borderRadius: '12px',
  backgroundColor: '',
  gap: '12px',
  display: 'flex',
  cursor: 'pointer',
  boxSizing: 'border-box',
  padding: 'var(--Numbers-Number-8, 12px)',
  justifyContent: 'start',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
  },
})
export const signoutMenuItem = (theme) => ({
  fontSize: theme.typography.h3.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  color: '#33333A',
  lineHeight: '24px',
  borderRadius: '12px',
  gap: '12px',
  cursor: 'pointer',
  boxSizing: 'border-box',
  padding: 'var(--Numbers-Number-8, 12px)',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
  },
})
export const userNameText = (theme) => ({
  fontSize: theme.typography.h3.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: '24px',
})
export const onlineText = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: '#818089',
  lineHeight: '24px',
})
export const acceptButton = (theme) => ({
  width: '146px',
  padding: 'var(--Numbers-Number-4, 8px) var(--Numbers-Number-10, 20px)',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.primary.dark,
  textTransform: 'capitalize',
  color: theme.palette.customWhite.main,
  borderRadius: 'var(--Numbers-Number-5, 10px)',
  fontFamily: theme.typography.h4.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.h3.fontWeight,
  lineHeight: '20px',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark,
  },
})
