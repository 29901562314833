import { useTheme } from '@emotion/react'
import {
  AddPhotoAlternateOutlined,
  EmojiEmotionsOutlined,
  Image,
} from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import EmojiPicker from 'emoji-picker-react'
import React, { useState } from 'react'
import { emojiPicker, postHeader_Box } from '../facebookServicesStyle'

const PostHeaderOptions = ({ onEmojiClick }) => {
  const [emojiState, setEmojiState] = useState(false)

  const handleChangeEmoji = (event, emojiObject) => {
    console.log('emoji', event)

    if (onEmojiClick) {
      onEmojiClick(event)
    }
    setEmojiState(false)
  }
  const theme = useTheme()
  return (
    <>
      <Box sx={postHeader_Box(theme)}>
        <IconButton
          sx={{ background: '' }}
          onClick={() => setEmojiState(!emojiState)}
        >
          <EmojiEmotionsOutlined />
        </IconButton>
        <IconButton sx={{ background: '' }}>
          <AddPhotoAlternateOutlined />
        </IconButton>
        {emojiState && (
          <EmojiPicker
            Reactions={true}
            lazyLoadEmojis={false}
            width={350}
            height={450}
            theme='dark'
            emojiStyle='facebook'
            emojiVersion='0.6'
            style={emojiPicker()}
            onEmojiClick={handleChangeEmoji}
          />
        )}
      </Box>
    </>
  )
}

export default PostHeaderOptions
