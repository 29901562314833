import {
  Box,
  Typography,
  IconButton,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useTheme } from '@emotion/react'
import AddIcon from '@mui/icons-material/Add'
import { useForm } from 'react-hook-form'
import {
  categoryResolver,
  categoryValidator,
  websiteResolver,
  websiteValidator,
  workplaceDescriptionResolver,
  workplaceDescriptionValidator,
  workplaceNameResolver,
  workplaceNameValidator,
} from '../../../../Validators/authValidator'
import { useContext, useEffect, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { message, Modal } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import newEditWorkspaceStyle, {
  add_ImageText,
  cancelChangeButton,
  commonSelectStyle,
  commonTextFieldStyle,
  descriptionTextFieldStyle,
  heading_Text,
  image,
  image_MainBox,
  label_Style,
  labelTextfield,
  paragraph_Text,
  pareantBox,
  saveChangeButton,
} from './newEditWorkspaceStyle'
import UserContext from '../../../../Context/userContext'
import { AiWhiteIcon, CameraSvg } from '../../../PasswordStartAdornment'
import EditAiLogoModel from './editAiLogoModel'
import { updateSpacesAPI } from '../../../../API'
import { uploadImageToFirebase } from '../../../../Firebase/uploadImagesToFirebase'
import { Loader } from '@infostacks/ui-components'
import { CustomFilterButton } from '../../../ReusableComponents/customButtons'
const NewEditWorkspaceForm = () => {
  const { userAuth, currentUser } = useContext(UserContext)
  const location = useLocation()
  const { spaceData } = location.state
  const [loading, setLoading] = useState(false)
  const [openModel, setOpenModel] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  console.log('selectedImage', selectedImage)
  const [updateData, setUpadteData] = useState()
  const [selectedImageURL, setSelectedImageURL] = useState(null)
  console.log('selectedImageURL', selectedImageURL)
  const [slideClass, setSlideClass] = useState('slide-out')
  const [edit, setEdit] = useState(false)
  const editorRef = useRef()
  const fileInputRef = useRef()

  const spaceId = window.sessionStorage.getItem('spaceId')

  const specificSpace = async () => {
    setUpadteData(spaceData)
    reset({
      name: spaceData.name,
      websiteLink: spaceData.websiteLink,
      category: spaceData.category,
      description: spaceData.description,
    })
  }

  useEffect(() => {
    if (userAuth) {
      specificSpace()
    }
  }, [userAuth])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = async (workspaceFormData) => {
    // console.log('object', workspaceFormData)
    try {
      setLoading(true)
      let imageUrl = ''
      if (selectedImage) {
        console.log('image', selectedImage)
        imageUrl = await uploadImageToFirebase(
          selectedImage,
          currentUser._id,
          'Spaces',
          spaceData?.name
        )
      }
      workspaceFormData.imageUrl = imageUrl
      workspaceFormData.creatorId = spaceData.creatorId
      workspaceFormData.isFavourite = spaceData.isFavourite
      const responseOfUpdateSpaceAPI = await updateSpacesAPI(
        userAuth,
        spaceId,
        workspaceFormData
      )
      if (responseOfUpdateSpaceAPI._id) {
        window.sessionStorage.setItem('spaceId', responseOfUpdateSpaceAPI._id)
        // const recentSpaceString = JSON.stringify(responseOfUpdateSpaceAPI)
        // window.localStorage.setItem('recentSpace', recentSpaceString)
        setLoading(false)
        navigate('/dashboard/workspace')
      }
    } catch (error) {
      console.error('Error', error.message)
    }
  }

  const imageUpload = (event) => {
    const file = event.target.files[0]
    console.log('file', file)
    setSelectedImage(file)
    setSelectedImageURL(URL.createObjectURL(file))
    setEdit(true)
  }

  const handleClose = () => {
    setSelectedImage(null)
    setSelectedImageURL(null)
    setEdit(false)
  }

  const handleSave = async () => {
    try {
      const canvas = editorRef.current.getImage().toDataURL()
      const blob = await fetch(canvas).then((res) => res.blob())
      setSelectedImage(blob)
      // setSelectedImageURL(canvas)
      setEdit(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleModelOpen = () => {
    setOpenModel(true)
  }

  const handleModelClose = () => {
    setOpenModel(false)
  }

  const handleAiImageSelection = (image) => {
    console.log('imageeeuuuuuuuuuuuu', image)
    if (image) {
      setEdit(true)
    }
    setSelectedImage(image)
    setSelectedImageURL(image)
  }

  const theme = useTheme()

  const navigate = useNavigate()

  const changeNaviagte = () => {
    navigate('/dashboard')
  }

  useEffect(() => {
    setSlideClass('slide-in')
  }, [])

  return (
    <div className={`transition-container ${slideClass}`}>
      <Box sx={pareantBox(theme)}>
        {loading ? (
          <Box sx={newEditWorkspaceStyle.circularProgressBox}>
            <Loader thickness={6} sx={{ color: 'blue' }} />
          </Box>
        ) : (
          <>
            <Box
              onClick={() => navigate('/dashboard')}
              sx={newEditWorkspaceStyle.closeRoundedIcon_Box}
            >
              <IconButton sx={{ height: '32px', width: '32px' }}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={newEditWorkspaceStyle.fromStyle}
            >
              {/* First Box  */}
              <Box sx={newEditWorkspaceStyle.heading_Box}>
                <Typography sx={heading_Text(theme)}>
                  Edit Your Workspace
                </Typography>
                <Typography sx={paragraph_Text(theme)}>
                  A workspace is a group of channels and collaborators, a place
                  where you can organize your campaigns, workflowss.
                </Typography>
              </Box>
              {/* Second Box */}
              <Box sx={image_MainBox(theme)}>
                <label
                  className='ImageMainBox'
                  style={label_Style(theme, selectedImage)}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImageURL}
                      alt='Selected'
                      style={image(theme)}
                    />
                  ) : updateData?.imageUrl ? (
                    <img
                      src={updateData?.imageUrl}
                      alt='workspace-Img'
                      style={image(theme)}
                    />
                  ) : (
                    <>
                      <CameraSvg />
                      <Typography sx={add_ImageText(theme)}>
                        Add image
                      </Typography>
                    </>
                  )}
                </label>
                <span style={{ display: 'flex', gap: '8px' }}>
                  <CustomFilterButton
                    buttonText={'Edit Image'}
                    icon={<AddIcon />}
                    fontWeight={theme.typography.h4.fontWeight}
                    onClick={() => fileInputRef.current.click()}
                  />
                  <CustomFilterButton
                    onClick={handleModelOpen}
                    buttonText={'Generate Image'}
                    icon={<AiWhiteIcon />}
                    fontWeight={theme.typography.h4.fontWeight}
                  />
                </span>
                <Modal
                  open={edit}
                  onCancel={handleClose}
                  onOk={handleSave}
                  okText='Save'
                  style={{ background: 'white', borderRadius: '12px' }}
                  okButtonProps={{
                    style: {
                      background: theme.palette.primary.dark,
                    },
                  }}
                >
                  <Box sx={newEditWorkspaceStyle.avatar_Box}>
                    {selectedImage ? (
                      <AvatarEditor
                        ref={editorRef}
                        image={selectedImageURL}
                        width={150}
                        height={150}
                        backgroundColor='red'
                        scale={1}
                        rotate={0}
                        borderRadius={20}
                        border={0}
                      />
                    ) : updateData?.imageUrl ? (
                      <img
                        src={selectedImageURL}
                        alt='Preview'
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          borderRadius: '14px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                </Modal>
                <input
                  type='file'
                  accept='image/*'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={imageUpload}
                />
              </Box>
              {/* Third Box */}
              <Box sx={newEditWorkspaceStyle.field_MainBox}>
                <Box sx={newEditWorkspaceStyle.mainBoxOfTextfield}>
                  <label
                    className='textfield-label'
                    style={labelTextfield(theme)}
                  >
                    Name
                  </label>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      fullWidth
                      autoComplete='off'
                      variant='outlined'
                      placeholder={updateData?.name || 'write your name'}
                      {...register('name', workplaceNameValidator)}
                      sx={commonTextFieldStyle(errors.name)}
                    />
                    <FormHelperText sx={newEditWorkspaceStyle.helperText}>
                      {errors.name && touchedFields.name
                        ? workplaceNameResolver[errors.name?.type]
                        : ''}
                    </FormHelperText>
                  </div>
                </Box>

                <Box sx={newEditWorkspaceStyle.mainBoxOfTextfield}>
                  <label
                    className='textfield-label'
                    style={labelTextfield(theme)}
                  >
                    Website link
                  </label>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      fullWidth
                      disabled
                      autoComplete='off'
                      variant='outlined'
                      {...register('websiteLink', websiteValidator)}
                      sx={commonTextFieldStyle(errors.websiteLink)}
                    />
                    <FormHelperText sx={newEditWorkspaceStyle.helperText}>
                      {errors.websiteLink && touchedFields.websiteLink
                        ? websiteResolver[errors.websiteLink?.type]
                        : ''}
                    </FormHelperText>
                  </div>
                </Box>

                <Box sx={newEditWorkspaceStyle.mainBoxOfTextfield}>
                  <label
                    className='textfield-label'
                    style={labelTextfield(theme)}
                  >
                    Category
                  </label>
                  <div style={{ position: 'relative' }}>
                    <Select
                      sx={commonSelectStyle(errors.category)}
                      fullWidth
                      {...register('category', categoryValidator)}
                      value={updateData?.category || ''}
                      onChange={(e) =>
                        setUpadteData((prevState) => ({
                          ...prevState,
                          category: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={'Saas'}>SaaS</MenuItem>
                      <MenuItem value={'PaaS'}>PaaS</MenuItem>
                      <MenuItem value={'others'}>others</MenuItem>
                    </Select>
                    <FormHelperText sx={newEditWorkspaceStyle.helperText}>
                      {errors.category && touchedFields.category
                        ? categoryResolver[errors.category?.type]
                        : ''}
                    </FormHelperText>
                  </div>
                </Box>

                <Box sx={newEditWorkspaceStyle.mainBoxOfTextfield}>
                  <label
                    className='textfield-label'
                    style={labelTextfield(theme)}
                  >
                    Description
                  </label>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      multiline
                      fullWidth
                      autoComplete='off'
                      variant='outlined'
                      placeholder={
                        updateData?.description ||
                        'write a description about your workplace'
                      }
                      {...register(
                        'description',
                        workplaceDescriptionValidator
                      )}
                      sx={descriptionTextFieldStyle(errors.description)}
                      InputProps={{
                        rows: 7,
                        multiline: true,
                        inputComponent: 'textarea',
                      }}
                    />
                    <FormHelperText sx={newEditWorkspaceStyle.helperText}>
                      {errors.description && touchedFields.description
                        ? workplaceDescriptionResolver[errors.description?.type]
                        : ''}
                    </FormHelperText>
                  </div>
                </Box>
                <Box sx={newEditWorkspaceStyle.buttonBox}>
                  <Button
                    onClick={changeNaviagte}
                    variant='outlined'
                    style={cancelChangeButton(theme)}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    variant='outlined'
                    type='submit'
                    style={saveChangeButton(theme)}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        )}
        {openModel && (
          <EditAiLogoModel
            open={openModel}
            onClose={handleModelClose}
            handleAiImageSelection={handleAiImageSelection}
          />
        )}
      </Box>
    </div>
  )
}
export default NewEditWorkspaceForm
