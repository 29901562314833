export const notificationMenuItemStyle = {
  header: {
    width: '100%',
    height: 'fit-content',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  btn_Main_Box: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'end',
    alignItems: 'center',
    background: '',
  },
  snoozeAndMarkButton: (theme) => ({
    height: 'fit-content',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: ' var(--Numbers-Number-4, 8px)',
    background: '',
    textTransform: 'capitalize',
    boxSizing: 'border-box',
    padding: 'var(--Numbers-Number-3, 6px) var(--Numbers-Number-5, 10px)',
    borderRadius: 'var(--Numbers-Number-24, 48px)',
    fontWeight: '700',
    '&:hover': {
      backgroundColor: theme.palette.gray.light,
    },
  }),
  scrollbar_Style: {
    mt: '12px',
    width: '100%',
    height: '450px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flexDirection: 'column',
    background: '',
    boxSizing: 'border-box',
    overflowY: 'auto',
  },
  time_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}
export const notificationText = (theme) => ({
  fontSize: theme.typography.mediumText.fontSize,
  fontFamily: theme.typography.fontFamily.fontFamily,
  fontWeight: theme.typography.h3.fontWeight,
  color: theme?.palette.black.main,
  fontStyle: 'normal',
  lineHeight: ' normal',
  background: '',
})
export const snoozeText = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h5.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  fontStyle: 'normal',
  textTransform: 'capitalize',
  lineHeight: '24px',
})

export const center_Button_Box = (theme) => ({
  width: '100%',
  mt: '12px',
  padding: 'var(--Numbers-Number-4, 8px)',
  boxSizing: 'border-box',
  borderRadius: ' var(--Numbers-Number-32, 64px)',
  border: `1px solid ${theme.palette.sky.light}`,
  backgroundColor: theme?.palette?.gray.main,
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  boxShadow:
    '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)',
})
export const custom_Button_Style = (theme) => ({
  width: '100%',
  paddingY: '8px',
  color: theme?.palette?.black.light,
  fontFamily: theme.typography.h3.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.h3.fontWeight,
  lineHeight: '20px',
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  borderRadius: 'var(--Numbers-Number-32, 64px)',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    color: theme?.palette?.black.main,
  },
})
export const menu_Style = (theme) => ({
  width: '600px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: theme?.palette?.customWhite.main,
  padding: '12px',
  border: `1px solid ${theme.palette.gray.light}`,
  boxShadow:
    '0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)',
})

export const paragraphText = (theme) => ({
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h5.fontFamily,
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontStyle: 'normal',
  fontWeight: theme?.typography.h5.fontWeight,
  lineHeight: 'normal',
  background: '',
})
export const dateTimeTextStyle = (theme) => ({
  color: theme?.palette.border.dark,
  fontFamily: theme?.typography.h5.fontFamily,
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontStyle: 'normal',
  fontWeight: theme?.typography.h5.fontWeight,
  lineHeight: 'normal',
  background: '',
})
export const viewInvitation_Parent = (theme) => ({
  width: '100%',
  padding: 'var(--Numbers-Number-4, 8px)',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  backgroundColor: theme?.palette.gray.main,
  borderRadius: ' var(--Numbers-Number-8, 16px)',
})
export const info_Notification_Box = () => ({
  width: '100%',
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
  background: '',
})
