import { Box, Button, Card, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  spaceSectionStyle,
  socialText,
  card_Style,
  dateText,
  desc_Text,
  connectPlatforms_Btn,
} from './workspaceProfilePageStyle'
import google from '../../../../Assets/Images/google.png'
import facebook from '../../../../Assets/Images/facebook.png'
import insta from '../../../../Assets/Images/instagram.png'
import nodata from '../../../../Assets/Images/nodata.png'
import { useTheme } from '@emotion/react'

const PlatformsSectionWorkspaceProfilePage = ({ spaceDataPlatforms }) => {
  const navigate = useNavigate()
  // console.log('spaceDataPlatforms', spaceDataPlatforms)
  const theme = useTheme()
  return (
    <Box sx={spaceSectionStyle.parent_Box}>
      <Box sx={spaceSectionStyle.Card_Box}>
        {spaceDataPlatforms?.map((item, index) => (
          <Card key={index} sx={card_Style(theme)}>
            <Box sx={spaceSectionStyle.main_Box}>
              <Box sx={spaceSectionStyle.img_Box}>
                <img
                  src={
                    item.name === 'google'
                      ? google
                      : item.name === 'facebook'
                      ? facebook
                      : item.name === 'instagram'
                      ? insta
                      : null
                  }
                  alt={'platforms-img'}
                  style={{ height: '50px', width: '50px' }}
                />
                <Box sx={spaceSectionStyle.date_Box}>
                  <Typography sx={socialText(theme)}>{item.name}</Typography>
                  <Typography sx={dateText(theme)}> 05 Nov 2024</Typography>
                </Box>
              </Box>
              <IconButton sx={{ alignSelf: 'flex-start' }}>
                <MoreVertIcon />
              </IconButton>
            </Box>
            <Typography sx={desc_Text(theme)}>
              Integrating Google boosts productivity and enhances user
              experience on our platform.
            </Typography>
          </Card>
        ))}

        {!spaceDataPlatforms || spaceDataPlatforms.length === 0 ? (
          <Card sx={card_Style(theme)}>
            <Box sx={{ background: '', display: 'flex' }}>
              <img
                src={nodata}
                alt='dummy img'
                style={{ height: '35px', width: '35px' }}
              />
            </Box>
            <Typography sx={desc_Text(theme)}>
              There is no integrated platform
            </Typography>
            <Button
              variant='contained'
              onClick={() => navigate('/social-connections')}
              sx={connectPlatforms_Btn(theme)}
            >
              Connect Platforms
            </Button>
          </Card>
        ) : null}
      </Box>
    </Box>
  )
}
export default PlatformsSectionWorkspaceProfilePage
