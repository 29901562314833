import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal } from 'antd'
import {
  click_Or_Drop_Here_Text,
  custom_Label_Style,
  facebookPostModalStyle,
  postSelectStyles,
} from '../facebookServicesStyle'
import {
  pagesResolver,
  pagesValidator,
  richTextResolver,
} from '../../../../../Validators/authValidator'
import ReactImageUploading from 'react-images-uploading'
import {
  ArrowDropDown,
  CloseRounded,
  DeleteOutline,
  EditOutlined,
  ImageSearchOutlined,
} from '@mui/icons-material'
import { uploadImagesToFirebase } from '../../../../../Firebase/uploadImagesToFirebase'
import UserContext from '../../../../../Context/userContext'
import {
  dataType64toFile,
  editFacebookPost,
  facebookPostWithImagesApi,
  facebookScheduleApi,
} from '../../../../../API/PlatformsAPIs/Facebook'
import PostHeaderOptions from './postHeaderOptions'
import CustomCalendar from '../../../../Calendar/customCalendar'
import { useTheme } from '@emotion/react'
import EditPostedFooter from './editPostedFooter'
import EditSchedulePostFooter from './editSchedulePostFooter'
import { Loader } from '@infostacks/ui-components'
import AiPostModal from '../AiPostModal/aiPostModal'
import RepostFooter from './repostFooter'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import EditDraftedPostFooter from './editDraftedPostFooter'
import CustomMenu from '../../../../ReusableComponents/customMenu'
import leftarrow from './../../../../../Assets/Images/arrowLeft.png'
import rightarrow from './../../../../../Assets/Images/arrowRight.png'
import { AiSvgIcon } from './../../../../PasswordStartAdornment/index'
// import CropImageModal from './cropImageModal'

const FacebookPostModal = ({
  handleModalOpen,
  open,
  pagesNames,
  pagesData,
  spaceId,
}) => {
  const {
    currentUser,
    specificPage,
    userAuth,
    postData,
    setPostData,
    setStateCheck,
    stateCheck,
    buttonStatus,
    aiModalOpen,
    setAiModalOpen
  } = useContext(UserContext)
  console.log('postDataz', postData, 'buttonStatus', buttonStatus)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedOption, setSelectedOption] = useState('Publish')
  const [timeModal, setTimeModal] = useState(false)
  const [scheduleTime, setScheduleTime] = useState(postData?.scheduledAt || '')
  const [load, setLoad] = useState(false)
  const [swiper, setSwiper] = useState(null)
  console.log('selectedOption', selectedOption)
  const maxNumber = 10
  const handleOpenAiModal = () => {
    setAiModalOpen(true)
  }

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()
  const [selectedValues, setSelectedValues] = useState()
  const [AiGenerateMedia, setAiGenerateMedia] = useState(null)
  const [hoverStates, setHoverStates] = useState([])
  // const [cropImage, setCropImage] = useState(false)
  // const handleCropImageOpen = () => {
  //   setCropImage(true)
  // }
  // const handleCropImageClose = () => {
  //   setCropImage(false)
  // }
  const textRef = useRef(null)
  const [inputStr, setInputStr] = useState(
    postData ? postData.message + ' ' + postData.link : ''
  )

  const [images, setImages] = useState([])
  console.log('images List', images)

  const handleTextFieldChange = (e) => {
    setInputStr(e.target.value)
  }

  const handleInsert = (data) => {
    // console.log('Received text and image from insert Button', data)
    const cursor = textRef.current.selectionStart
    const updatedText =
      inputStr.slice(0, cursor) + data.text + inputStr.slice(cursor)
    setInputStr(updatedText)
    setAiGenerateMedia(data)
    const newCursor = cursor + data.text.length
    setTimeout(() => textRef.current.setSelectionRange(newCursor, newCursor), 0)
    if (data.image) {
      setImages((prevImages) => [...prevImages, { data_url: data.image }])
    }
  }

  const handleEmojiClick = (emojiObject) => {
    if (emojiObject && emojiObject.emoji) {
      const cursor = textRef.current.selectionStart
      const text =
        inputStr.slice(0, cursor) + emojiObject.emoji + inputStr.slice(cursor)
      setInputStr(text)
      // Update cursor position
      const newCursor = cursor + emojiObject.emoji.length
      setTimeout(
        () => textRef.current.setSelectionRange(newCursor, newCursor),
        0
      )
    }
  }

  useEffect(() => {
    if (postData && postData.imageUrls) {
      const initialImages = postData.imageUrls.map((url) => ({ data_url: url }))
      setImages(initialImages)
    }
  }, [postData])

  const onChange = (imageList, addUpdateIndex) => {
    console.log('addUpdateIndex', addUpdateIndex)
    setImages(imageList)
  }

  const handleChange = (event) => {
    const { value } = event.target
    setSelectedValues(value)
  }

  const handleClose = () => {
    handleModalOpen(false)
    setPostData('')
  }

  const onSubmit = async (data) => {
    setLoad(true)
    try {
      const randomId = Math.random().toString(36).substring(2)
      console.log('images', images)
      const newLinks = images.filter((item) => item.data_url.includes('base64'))
      console.log('newLinks', newLinks)
      const existingLinks = images.filter((item) =>
        item.data_url.includes('https://firebasestorage.googleapis.com/')
      )
      const firebaseLinks = existingLinks.map((link) => link.data_url)
      // console.log('firebaseLinks', firebaseLinks)

      const files = newLinks.map((image) =>
        dataType64toFile(image.data_url, 'image.png')
      )

      const urls = await uploadImagesToFirebase(
        files,
        currentUser._id,
        'Posts',
        randomId
      )
      if (firebaseLinks) {
        data.imageUrls = firebaseLinks.concat(urls)
      } else {
        data.imageUrls = urls
      }
      // console.log('checks imagesss ', data)
      data.message = inputStr
      const selectPage = pagesData.find((val) => val.name === data.pages)
      data.pageId = selectPage?.id
      const hasImages = data.imageUrls && data.imageUrls.length > 0
      if (!hasImages) {
        const messageLinks =
          data.message.match(/\b(?:https?:\/\/|www\.)\S+/gi) || []
        const containsOnlyOneLink =
          messageLinks.length === 1 &&
          data.message.replace(/\bhttps?:\/\/\S+/gi, '').trim() === ''
        const containsMultipleLinks =
          messageLinks.length > 1 &&
          data.message.replace(/\bhttps?:\/\/\S+/gi, '').trim() === ''
        const containsTextWithLinks =
          messageLinks.length > 0 &&
          data.message.trim().endsWith(messageLinks[messageLinks.length - 1]) &&
          data.message.replace(/\bhttps?:\/\/\S+/gi, '').trim() !== ''
        if (containsOnlyOneLink) {
          data.link = data.message
          delete data.message
        } else if (containsMultipleLinks) {
          const lastLink = messageLinks.pop()
          data.link = lastLink
          data.message = messageLinks.join(' ')
        } else if (containsTextWithLinks) {
          const lastLink = messageLinks.pop()
          data.link = lastLink
          data.message = data.message.replace(lastLink, '').trim()
        }
      }

      if (selectedOption === 'Publish') {
        data.postStatus = 'posted'
        console.log('data', data)
        const postResponse = await facebookPostWithImagesApi(
          userAuth,
          data,
          spaceId,
          'page id in body'
        )
        console.log('postResponse', postResponse)
      }
      if (selectedOption === 'scheduleToPublish') {
        data.postStatus = 'posted'
        data.id = postData._id
        console.log('postDataz', postData)
        console.log('scheduleToPublish data', data)
        const postResponse = await facebookPostWithImagesApi(
          userAuth,
          data,
          spaceId,
          postData.pageId
        )
        console.log('postResponse', postResponse)
      }
      if (selectedOption === 'draftedToPublish') {
        data.postStatus = 'posted'
        console.log('postDataz', postData)
        console.log('scheduleToPublish data', data)
        const postResponse = await facebookPostWithImagesApi(
          userAuth,
          data,
          spaceId,
          postData.pageId
        )
        console.log('postResponse', postResponse)
      }
      if (selectedOption === 'Edit') {
        if (scheduleTime) {
          data.scheduleTime = scheduleTime
        }
        data.postStatus = 'posted'
        if (postData.status === 'posted') {
          data.message = inputStr
          delete data.link
        }
        console.log('edit data', data)
        const postResponse = await editFacebookPost(
          userAuth,
          data,
          spaceId,
          postData.pageId,
          postData?._id
        )
        console.log('Edit postResponse', postResponse)
      }

      if (selectedOption === 'Schedule') {
        data.scheduleTime = scheduleTime
        console.log('data', data)

        const response = await facebookScheduleApi(userAuth, data, spaceId)
        console.log('Post scheduled response:', response)
      }
      if (selectedOption === 'Draft') {
        data.postStatus = 'drafted'
        if (scheduleTime === '') {
          delete data.scheduleTime
        } else data.scheduleTime = scheduleTime
        console.log('Draft data', data)
        const postResponse = await facebookPostWithImagesApi(
          userAuth,
          data,
          spaceId,
          'page id in body'
        )
        console.log('Draft postResponse', postResponse)
      }
      if (selectedOption === 'DraftToSave') {
        data.postStatus = 'drafted'
        if (scheduleTime === '') {
          delete data.scheduleTime
        } else data.scheduleTime = scheduleTime
        console.log('Draft data', data)
        const postResponse = await editFacebookPost(
          userAuth,
          data,
          spaceId,
          postData.pageId,
          postData?._id
        )
        console.log('DraftToSave postResponse', postResponse)
      }
      setLoad(false)
      setInputStr('')
      setImages([])
      handleClose()
      setStateCheck(!stateCheck)
    } catch (error) {
      console.error('Error during form submission:', error)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosebtn = () => {
    setAnchorEl(null)
  }

  const handleOptionSelect = (option) => {
    console.log('agya 123123123', option)

    if (option === 'Draft') {
      setSelectedOption(option)
    }
    if (option === 'Schedule') {
      setTimeModal(true)
      setSelectedOption(option)
    }
    if (option === 'Publish') {
      // setScheduleTime(null)
      setSelectedOption(option)
    }
    if (option === 'Edit') {
      setSelectedOption(option)
    }
    if (option === 'scheduleToPublish') {
      setSelectedOption(option)
    }
    if (option === 'DraftToSave') {
      setSelectedOption(option)
    }
    if (option === 'draftedToPublish') {
      setScheduleTime(null)
      setSelectedOption(option)
    }

    // setSelectedOption(option)
    setAnchorEl(null)
  }
  const closeTimeModal = (state, option) => {
    setSelectedOption(option)
    setTimeModal(state)
    setScheduleTime(postData.createdAt)
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }
    return date.toLocaleDateString('en-US', options)
  }
  const confirmDate = (dateTime, state) => {
    console.log('dateTime', dateTime, 'state', state)

    setScheduleTime(dateTime)
    setTimeModal(false)
  }
  const menuItems = [
    {
      label: 'Publish',
      onClick: () => handleOptionSelect('Publish'),
    },
    {
      label: 'Schedule',
      onClick: () => handleOptionSelect('Schedule'),
    },
  ]

  const theme = useTheme()
  return (
    <>
      {load ? (
        <Box
          sx={{
            position: 'fixed',
            color: 'red',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <Modal
          open={open}
          // onCancel={handleClose}
          footer
          centered
          width='fit-content'
          // title={'Create post'}
          maskClosable={false}
          closable={false}
          style={{ backgroundColor: '' }}
        >
          <Box
            sx={{
              display: 'flex',
              backgroundColor: '#4D4D4D',
              gap: '30px',
              width: 'fit-content',
            }}
          >
            {aiModalOpen ? (
              <AiPostModal
                setAiModalOpen={setAiModalOpen}
                handleInsert={handleInsert}
              />
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: '750px',
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '20px',
                borderRadius: '10px',
                boxSizing: 'border-box',
                paddingTop: '12px',
                paddingBottom: '12px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '',
                  alignItems: 'center',
                  width: '95%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme?.typography.mediumText.fontSize,
                    fontWeight: theme.typography.h3.fontWeight,
                    fontFamily: theme.typography.h2.fontFamily,
                    fontStyle: 'normal',
                    lineHeight: '30px',
                  }}
                >
                  Create Post
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseRounded />
                </IconButton>
              </Box>
              {buttonStatus === 'Edit' &&
              (postData?.status === 'scheduled' ||
                postData?.status === 'posted' ||
                postData?.status === 'drafted') ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '',
                    width: '95%',
                  }}
                >
                  <Tooltip
                    arrow
                    placement='top'
                    title={postData?.pageName}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <img
                      src={postData?.profilePic}
                      alt='pageProfile'
                      height={50}
                      width={50}
                      style={{ borderRadius: '50%' }}
                    />
                  </Tooltip>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    background: '',
                    gap: { sm: '5px' },
                  }}
                >
                  <label
                    className='textfield-label'
                    style={custom_Label_Style(theme)}
                  >
                    Select Page
                  </label>
                  <div style={facebookPostModalStyle.custom_Field_Style}>
                    <Select
                      sx={postSelectStyles()}
                      // multiple
                      defaultValue={[specificPage]}
                      value={selectedValues}
                      onChange={handleChange}
                      {...register('pages', pagesValidator)}
                    >
                      {pagesNames &&
                        pagesNames.map((page, index) => (
                          <MenuItem key={index} value={page}>
                            {page}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText
                      sx={facebookPostModalStyle.form_Helper_Text}
                    >
                      {errors.pages && touchedFields.pages
                        ? pagesResolver[errors.pages?.type]
                        : ''}
                    </FormHelperText>
                  </div>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '95%',
                  background: '',
                  borderRadius: '10px',
                  border: '1px solid #E5E7EB',
                  gap: '20px',
                  boxSizing: 'border-box',
                  paddingBottom: '10px',
                }}
              >
                <PostHeaderOptions onEmojiClick={handleEmojiClick} />

                <Box
                  sx={{
                    display: 'flex',
                    background: '',
                    width: '95%',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#C2C2C2',
                      fontSize: '15px',
                      fontWeight: 500,
                      fontFamily: 'Libre Franklin',
                      lineHeight: 'normal',
                      letterSpacing: ' 0.075px',
                    }}
                  >
                    Start writing or
                  </Typography>
                  <Box
                    onClick={handleOpenAiModal}
                    sx={{
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      gap: '5px',
                      paddingX: '12px',
                      paddingY: '8px',
                      display: 'flex',
                      border: '1px solid #C2C2C2',
                      borderRadius: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <AiSvgIcon width={21} height={16} />
                    <Typography
                      sx={{
                        color: '#C2C2C2',
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Libre Franklin',
                        lineHeight: '18px',
                      }}
                    >
                      Generate
                    </Typography>
                  </Box>
                </Box>

                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <TextField
                    variant='standard'
                    placeholder='Type your message . . .'
                    fullWidth
                    value={inputStr}
                    inputRef={textRef}
                    onChange={handleTextFieldChange}
                    sx={{
                      background: '#FAFBFC',
                      width: '95%',
                      mx: 'auto',
                      overflowY: 'auto',
                      '& textarea': {
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      },
                    }}
                    InputProps={{
                      rows: 7,
                      multiline: true,
                      inputComponent: 'textarea',
                      disableUnderline: true,
                    }}
                    // {...register('description', richTextValidator)}
                  />

                  <FormHelperText
                    sx={{
                      position: 'absolute',
                      bottom: '-20px',
                      color: 'red',
                      ml: '20px',
                    }}
                  >
                    {errors.description && touchedFields.description
                      ? richTextResolver[errors.description?.type]
                      : ''}
                  </FormHelperText>
                </div>

                <ReactImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey='data_url'
                  acceptType={['png', 'jpeg']}
                  maxFileSize={4194304}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div
                      className='upload__image-wrapper'
                      style={{
                        background: '',
                        width: '95%',
                        display: 'flex',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        height: 'fit-content',
                        gap: '10px',
                      }}
                    >
                      <Button
                        variant='outlined'
                        onClick={onImageUpload}
                        {...dragProps}
                        sx={{
                          textTransform: 'capitalize',
                          color: isDragging ? 'red' : null,
                          height: 'fit-content',
                          width: 'fit-content',
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          padding: '12px',
                          border: '2px dotted #E5E7EB',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          gap: '6px',
                          '&:hover': {
                            border: `2px dotted ${theme.palette.primary.dark}`,
                          },
                        }}
                      >
                        <ImageSearchOutlined
                          sx={{
                            fontSize: '30px',
                            color: theme.palette.primary.dark,
                          }}
                        />

                        <Typography sx={click_Or_Drop_Here_Text(theme)}>
                          Click or Drop here
                          <br /> max 10
                        </Typography>
                      </Button>
                      <img
                        onClick={() => {
                          if (swiper) {
                            swiper.slidePrev()
                          }
                        }}
                        src={leftarrow}
                        alt=''
                        style={{
                          height: '25px',
                          width: '25px',
                          marginLeft: '30px',
                          cursor: 'pointer',
                          visibility: images.length > 3 ? 'visible' : 'hidden',
                        }}
                      />

                      <div
                        style={{
                          height: 'fit-content',
                          width: '60%',
                          background: '',
                        }}
                      >
                        <Swiper
                          spaceBetween={10}
                          navigation={false}
                          onSwiper={(swiperInstance) =>
                            setSwiper(swiperInstance)
                          }
                          breakpoints={{
                            0: {
                              slidesPerView: 3,
                            },
                          }}
                          className='fbSwiper'
                          style={{
                            width: '100%',
                            height: '100px',
                            background: '',
                          }}
                        >
                          {/* {AiGenerateMedia.aiImage} */}
                          {imageList.map((image, index) => (
                            <SwiperSlide
                              key={index}
                              className='image-item'
                              style={{
                                background: '',
                                // width: '120px',
                                // height: '100px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                position: 'relative',
                                overflow: 'hidden',
                              }}
                              onMouseEnter={() =>
                                setHoverStates((prev) => [
                                  ...prev.slice(0, index),
                                  true,
                                  ...prev.slice(index + 1),
                                ])
                              }
                              onMouseLeave={() =>
                                setHoverStates((prev) => [
                                  ...prev.slice(0, index),
                                  false,
                                  ...prev.slice(index + 1),
                                ])
                              }
                            >
                              <img
                                src={image.data_url}
                                alt=''
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  borderRadius: '4px',
                                  display: 'block',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  objectFit: 'cover', // Ensures the image covers the entire box
                                  filter: hoverStates[index]
                                    ? 'brightness(70%)'
                                    : 'brightness(100%)',
                                  transition: 'filter 0.3s ease-in-out',
                                }}
                              />
                              <Box
                                sx={{
                                  display: hoverStates[index] ? 'flex' : 'none',
                                  justifyContent: 'space-evenly',
                                  width: '100%',
                                  position: 'absolute',
                                  background: '',
                                  zIndex: 2,
                                }}
                              >
                                <IconButton
                                  sx={{
                                    height: '25px',
                                    width: '25px',
                                    background: 'lightgray',
                                    '&:hover': { backgroundColor: '#DADAFF' },
                                  }}
                                  onClick={() => onImageUpdate(index)}
                                  // onClick={handleCropImageOpen}
                                >
                                  <EditOutlined
                                    sx={{ height: '20px', width: '20px' }}
                                  />
                                </IconButton>
                                {/* {
                                  <CropImageModal
                                    open={cropImage}
                                    handleClose={handleCropImageClose}
                                    editImage={imageList}
                                  />
                                } */}

                                <IconButton
                                  sx={{
                                    height: '25px',
                                    width: '25px',
                                    background: 'lightgray',
                                    '&:hover': { backgroundColor: '#DADAFF' },
                                  }}
                                  onClick={() => onImageRemove(index)}
                                >
                                  <DeleteOutline
                                    sx={{ height: '20px', width: '20px' }}
                                  />
                                </IconButton>
                              </Box>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                      <img
                        onClick={() => {
                          if (swiper) {
                            swiper.slideNext() // Go to next slide
                          }
                        }}
                        src={rightarrow}
                        alt=''
                        style={{
                          height: '25px',
                          width: '25px',
                          visibility: images.length > 3 ? 'visible' : 'hidden',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  )}
                </ReactImageUploading>
              </Box>

              {buttonStatus === 'Edit' && postData.status === 'posted' ? (
                <EditPostedFooter
                  handleOptionSelect={handleOptionSelect}
                  inputStr={inputStr}
                  images={images}
                />
              ) : buttonStatus === 'Edit' && postData.status === 'scheduled' ? (
                <EditSchedulePostFooter
                  inputStr={inputStr}
                  images={images}
                  handleOptionSelect={handleOptionSelect}
                  formatDate={formatDate}
                  setTimeModal={setTimeModal}
                  scheduleTime={scheduleTime}
                />
              ) : buttonStatus === 'Edit' && postData.status === 'drafted' ? (
                <EditDraftedPostFooter
                  inputStr={inputStr}
                  images={images}
                  handleOptionSelect={handleOptionSelect}
                  formatDate={formatDate}
                  setTimeModal={setTimeModal}
                  scheduleTime={scheduleTime}
                  anchorEl={anchorEl}
                  handleClosebtn={handleClosebtn}
                  handleClick={handleClick}
                />
              ) : buttonStatus === 'Repost' ? (
                <RepostFooter inputStr={inputStr} images={images} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    width: '95%',
                    background: '',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '50px',
                  }}
                >
                  <Box
                    sx={{
                      background: '',
                      display:
                        scheduleTime && selectedOption === 'Schedule'
                          ? 'flex'
                          : 'none',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', width: 'fit-content' }}>
                      Schedule date:
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                      {formatDate(scheduleTime)}
                    </Typography>
                    <Button
                      variant='outlined'
                      onClick={() => setTimeModal(true)}
                      sx={{
                        textTransform: 'capitalize',
                        ml: '20px',
                        boxSizing: 'border-box',
                        padding: '0px',
                        height: '35px',
                        paddingX: '12px',
                        borderRadius: '8px',
                        fontFamily: theme?.typography.h4.fontFamily,
                        fontWeight: theme?.typography.h2.fontWeight,
                        fontSize: theme.typography.h5.fontSize,
                        background: 'white',
                        border: `1px solid ${theme.palette.primary.dark}`,
                        color: theme.palette.primary.dark,
                        lineHeight: 'normal',
                        ':hover': {
                          border: `1px solid ${theme.palette.primary.dark}`,
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      Edit Schedule
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      width: 'fit-content',
                      ml: 'auto',
                      display: 'flex',
                      gap: '20px',
                      background: '',
                      height: 'fit-content',
                    }}
                  >
                    <Button
                      type='submit'
                      onClick={() => handleOptionSelect('Draft')}
                      variant='contained'
                      sx={{
                        display:
                          inputStr || images.length > 0 ? 'flex' : 'none',
                        textTransform: 'capitalize',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        padding: '0px',
                        height: '35px',
                        paddingX: '12px',
                        fontFamily: theme?.typography.h4.fontFamily,
                        fontWeight: theme?.typography.h2.fontWeight,
                        fontSize: theme.typography.h5.fontSize,
                        background: theme.palette.primary.dark,
                        lineHeight: 'normal',
                      }}
                    >
                      Draft
                    </Button>

                    <Box
                      sx={{
                        height: 'fit-content',
                        width: '140px',
                        background: theme?.palette.primary.dark,
                        alignItems: 'center',
                        gap: '0px',
                        borderRadius: '8px',
                        display:
                          inputStr || images.length > 0 ? 'flex' : 'none',
                      }}
                    >
                      <Button
                        type='submit'
                        sx={{
                          textTransform: 'capitalize',
                          color: 'white',
                          fontWeight: theme.typography.menualText.fontWeight,
                          fontFamily: theme?.typography.h4.fontFamily,
                          background: '',
                          height: '35px',
                          width: '100px',
                          mr: '0px',
                          boxSizing: 'border-box',
                          padding: '0px',
                          paddingX: '8px',
                        }}
                      >
                        {selectedOption}
                      </Button>
                      <Divider
                        orientation='vertical'
                        sx={{ height: '20px', backgroundColor: 'white' }}
                      />
                      <IconButton
                        onClick={handleClick}
                        sx={{
                          background: '',
                          height: '35px',
                          width: '35px',
                          padding: '0px',
                          boxSizing: 'border-box',
                        }}
                      >
                        <ArrowDropDown
                          sx={{ color: 'white', background: '' }}
                        />
                      </IconButton>
                    </Box>
                    <CustomMenu
                      anchorEl={anchorEl}
                      open={anchorEl}
                      onClose={handleClosebtn}
                      menuItems={menuItems}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                  </Box>
                </Box>
              )}
              {/* {selectedOption === 'Schedule' || postData.status ==='scheduled' ? ( */}
              <Modal
                centered
                open={timeModal}
                onCancel={() => closeTimeModal(false, 'Publish')}
                footer={false}
                maskClosable={false}
                style={{ background: 'white', borderRadius: '14px' }}
              >
                <CustomCalendar
                  confirmDate={confirmDate}
                  timeModal={timeModal}
                  closeTimeModal={closeTimeModal}
                />
              </Modal>
              {/* ) : null} */}
            </form>
          </Box>
        </Modal>
      )}
    </>
  )
}
export default FacebookPostModal
