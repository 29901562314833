import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BottomSectionStyle } from './landingPageStyle'

function BottomSection() {
  const navigate = useNavigate()
  return (
    <Box>
      <Box sx={BottomSectionStyle.container}>
        <Box sx={BottomSectionStyle.innerBox}>
          <Typography sx={BottomSectionStyle.titleText}>
            Unlock Your Social Media Potential with a Free Demo.
          </Typography>

          <Typography sx={BottomSectionStyle.descriptionText}>
            Get a glimpse of AI-powered social media management with our free
            demo. Start unleashing your social media potential in just a few
            clicks.
          </Typography>

          <Button
            onClick={() => navigate('/signup')}
            sx={BottomSectionStyle.button}
          >
            Get a free demo
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default BottomSection
