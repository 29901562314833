import { EmojiEmotionsOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import AddPicker from "./addEmoji";

const CaptionEmojiAndOptions = ({
  setAiModalOpen,
  mediaType,
  caption,
  setCaption,
  loading,
}) => {
  const textRef = useRef(null);

  const handleEmojiClick = (emojiObject) => {
    console.log("emojiObject", emojiObject.emoji);
    if (emojiObject && emojiObject.emoji) {
      const cursor = textRef.current.selectionStart;
      const text =
        caption.slice(0, cursor) + emojiObject.emoji + caption.slice(cursor);
      setCaption(text);
      // Update cursor position
      const newCursor = cursor + emojiObject.emoji.length;
      setTimeout(
        () => textRef.current.setSelectionRange(newCursor, newCursor),
        0
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        background: "",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "20px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Libre Franklin",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        What do you want to write about?
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "200px",
          boxSizing: "border-box",
          padding: "16px",
          mt: "4px",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E5E7EB",
          borderRadius: "10px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            height: "fit-content",
            width: "fit-content",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Libre Franklin",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "20px",
              color: "#C2C2C2",
            }}
          >
            Write a caption...
          </Typography>
          <Button
            disabled={loading}
            onClick={() => setAiModalOpen(true)}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M5.97897 8.49967C5.51977 8.32481 5.51977 7.67519 5.97897 7.50033C8.82993 6.41474 11.0815 4.16318 12.1671 1.31223C12.3419 0.85302 12.9916 0.85302 13.1664 1.31223C14.252 4.16318 16.5036 6.41474 19.3545 7.50033C19.8137 7.67519 19.8137 8.32481 19.3545 8.49967C16.5036 9.58526 14.252 11.8368 13.1664 14.6878C12.9916 15.147 12.3419 15.147 12.1671 14.6878C11.0815 11.8368 8.82993 9.58526 5.97897 8.49967Z"
                  fill="#4950FF"
                />
                <path
                  d="M3.10416 3.49989C2.95109 3.4416 2.95109 3.22506 3.10416 3.16678C4.05447 2.80491 4.805 2.05439 5.16686 1.10408C5.22514 0.951007 5.44169 0.951007 5.49997 1.10407C5.86183 2.05439 6.61235 2.80491 7.56267 3.16678C7.71574 3.22506 7.71574 3.4416 7.56267 3.49989C6.61235 3.86175 5.86183 4.61227 5.49997 5.56259C5.44169 5.71566 5.22514 5.71566 5.16686 5.56259C4.805 4.61227 4.05447 3.86175 3.10416 3.49989Z"
                  fill="#4950FF"
                />
                <path
                  d="M0.492085 11.8539C0.319883 11.7883 0.319883 11.5447 0.492085 11.4791C1.56119 11.072 2.40553 10.2277 2.81262 9.15859C2.8782 8.98639 3.1218 8.98639 3.18738 9.15859C3.59447 10.2277 4.43881 11.072 5.50792 11.4791C5.68012 11.5447 5.68012 11.7883 5.50792 11.8539C4.43881 12.261 3.59447 13.1053 3.18738 14.1744C3.1218 14.3466 2.8782 14.3466 2.81262 14.1744C2.40553 13.1053 1.56119 12.261 0.492085 11.8539Z"
                  fill="#4950FF"
                />
              </svg>
            }
            sx={{
              boxSizing: "border-box",
              padding: "8px 12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #C2C2C2",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "18px",
              borderRadius: "8px",
              color: "#C2C2C2",
            }}
          >
            Generate
          </Button>
        </Box>
        <TextField
          disabled={mediaType === "story" || loading}
          value={caption}
          inputRef={textRef}
          onChange={(e) => setCaption(e.target.value)}
          variant="standard"
          fullWidth
          sx={{
            background: "",
            width: "100%",
            overflowY: "auto",
            "& textarea": {
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
          }}
          InputProps={{
            rows: 4,
            multiline: true,
            inputComponent: "textarea",
            disableUnderline: true,
          }}
          // {...register('aiPrompt', aiPromptValidator)}
        />
        <AddPicker
          caption={caption}
          handleEmojiClick={handleEmojiClick}
          mediaType={mediaType}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default CaptionEmojiAndOptions;
