import { WarningAmberRounded } from '@mui/icons-material'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Slide,
  Box,
} from '@mui/material'
import React from 'react'
import {
  archive_Workspaces_Text,
  button_style,
  dialogStyle,
  dialog_Parent,
  paragraoh_Text,
} from './dashboardMainSectionStyle'
import { useTheme } from '@emotion/react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CustomDialog = ({ open, close, archiveSpace, id }) => {
  const confirmArchive = () => {
    archiveSpace('Archive', id, { isDeleted: true })
  }
  const theme = useTheme()
  return (
    <Dialog
      PaperProps={{
        sx: dialog_Parent(theme),
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
      open={open}
      onClose={() => close(!open)}
      TransitionComponent={Transition}
    >
      <Box sx={dialogStyle.Icon_Box}>
        <WarningAmberRounded sx={dialogStyle.warningAmberRoundedStyle} />
      </Box>
      <DialogTitle sx={archive_Workspaces_Text(theme)}>
        Archive Workspace
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={paragraoh_Text(theme)}>
          Are you sure you want to archive this workspace?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogStyle.dialogActions}>
        <Button onClick={() => close(!open)} sx={button_style(theme)}>
          Cancel
        </Button>
        <Button onClick={confirmArchive} sx={button_style(theme)}>
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog
