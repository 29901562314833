import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import welcomePageStyle, {
  buttonStyle,
  latics_Text_Style,
  signOut_Text,
} from './welcomePageStyle'
import appLogo from '../../Assets/Images/aiLatics-Logo.png'
import image from './../../Assets/Images/signupImage.png'
import logout from './../../Assets/Images/logout.png'
import 'swiper/swiper-bundle.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import engments from './../../Assets/Images/engagments.png'
import totalSales from './../../Assets/Images/totalsales.png'
import growth from './../../Assets/Images/growth.png'
import testemonial from './../../Assets/Images/testemonial.png'
import { useTheme } from '@emotion/react'
import UserContext from '../../Context/userContext'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import WelcomeForm from './welcomeForm'
import { Loader } from '@infostacks/ui-components'

const WelcomePage = () => {
  const images = [engments, totalSales, growth, testemonial]
  const navigate = useNavigate()
  const {
    currentUser,
    userAuth,
    sendEmailLinkContext,
    getLatestRoute,
    signOutContext,
  } = useContext(UserContext)
  const [api, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)

  const openNotification = (
    placement,
    type,
    message,
    description,
    duration
  ) => {
    api.open({
      message: message,
      description: description,
      placement: placement,
      type: type,
      duration: duration,
    })
  }

  const handleResend = async () => {
    if (userAuth && userAuth.email) {
      if (userAuth?.emailVerified) {
        openNotification(
          'top',
          'success',
          'Congrats',
          'Email already verified please click on continue',
          4
        )
      } else {
        await sendEmailLinkContext()
      }
    }
  }
  const signOut = async () => {
    try {
      setLoading(true)
      const signOutResponse = await signOutContext()
      console.log('signout', signOutResponse)
      setLoading(false)
    } catch (error) {
      console.log('error of signout', error)
    }
  }

  const handleContinue = () => {
    console.log('OOOOOOOOOOOooop')
    if (userAuth && userAuth.email) {
      if (userAuth?.emailVerified && currentUser?.accountType === 'user') {
        const rr = getLatestRoute()
        console.log('cnfr', rr)

        navigate('/choose-plan')
      } else if (
        userAuth?.emailVerified &&
        currentUser?.accountType === 'member'
      ) {
        navigate('/dashboard')
      } else {
        openNotification(
          'top',
          'info',
          'Email Verification',
          'Please verify your email first to continue',
          6
        )
      }
    }
  }

  const theme = useTheme()

  useEffect(() => {
    if (!userAuth) {
      navigate('/signup')
    } else if (userAuth && userAuth.email && userAuth.emailVerified) {
      navigate('/choose-plan')
    }
  }, [userAuth])
  return (
    <Box sx={welcomePageStyle.parent_Box}>
      <Box sx={welcomePageStyle.main_Box}>
        {loading ? (
          <Box sx={welcomePageStyle.circularProgressBox}>
            <Loader sx={{ color: 'blue' }} />
          </Box>
        ) : (
          <Box sx={welcomePageStyle.formMainBox}>
            <Box sx={welcomePageStyle.logo_MainBox}>
              <img
                src={appLogo}
                alt='img'
                style={{ height: '33px', width: '33px' }}
              />
              <Typography sx={latics_Text_Style(theme)}>Latics</Typography>
              <Button onClick={signOut} sx={buttonStyle(theme)}>
                <img src={logout} alt='' width={22} height={22} />
                <Typography sx={signOut_Text(theme)}>Sign Out</Typography>
              </Button>
            </Box>
            {contextHolder}
            {userAuth && currentUser ? (
              <WelcomeForm handleContinue={handleContinue} />
            ) : (
              <Loader />
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '14px',
          display: 'flex',
          justifyContent: 'center',
          objectFit: 'cover',
        }}
      >
        <Swiper
          direction='vertical'
          loop
          spaceBetween={400}
          speed={1500}
          autoplay={{ delay: 3500, disableOnInteraction: false }}
          modules={[Pagination, Navigation, Autoplay]}
          breakpoints={{
            768: {
              slidesPerView: 2.5,
              centeredSlides: true,
              slideToClickedSlide: true,
            },
          }}
          className='mySwiper'
          style={welcomePageStyle.swiper_Style}
        >
          {images.map((value, index) => (
            <SwiperSlide key={index} style={welcomePageStyle.swiper_SlideStyle}>
              <img
                src={value}
                alt=''
                style={welcomePageStyle.swiper_Img_Style}
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default WelcomePage
