import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import workSocialPageStyle, {
  descriptionText,
  headingText,
  headingTypography,
  paragraphTypography,
  parentBox,
  socialPageButton,
} from './workSocialPageStyle'
import { useNavigate } from 'react-router-dom'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import objectsArray from './objectsArray'
import UserContext from '../../../Context/userContext'
import { CheckCircleRounded } from '@mui/icons-material'
import { getSpecificSpacesAPI } from '../../../API'
import { Loader } from '@infostacks/ui-components'
import { notification } from 'antd'
import { FacebookSvg, InstagramSvg } from './svgImageWOrkSPace'
import makeHeader from '../../../API/Headers'
import { useTheme } from '@emotion/react'

const WorkSpaceSocialPage = () => {
  const {
    userAuth,
    currentUser,
    facebookAuthorizationContext,
    instagramAuthorizationContext,
  } = useContext(UserContext)
  const [target, setTarget] = useState()
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [slideClass, setSlideClass] = useState('slide-out')
  const called = useRef(false)
  const navigate = useNavigate()
  const backNavigate = () => navigate('/dashboard')
  const spaceId = window.sessionStorage.getItem('spaceId')
  // console.log('id in social', spaceId)

  const openNotification = (
    placement,
    type,
    message,
    icon,
    description,
    duration
  ) => {
    api.open({
      message: message,
      description: description,
      placement: placement,
      icon: icon,
      type: type,
      duration: duration,
    })
  }

  const instagramAuthorization = async (matchedPlatform) => {
    window.sessionStorage.setItem('platformForIntegrate', 'instagram')
    if (matchedPlatform) {
      console.log('google')
      openNotification(
        'top',
        'info',
        'Platform Integration',
        <InstagramSvg width={25} height={25} />,
        'Instagram already integrated.',
        4
      )
    } else {
      await instagramAuthorizationContext()
    }
  }

  const FacebookAuthorization = async (matchedPlatform) => {
    window.sessionStorage.setItem('platformForIntegrate', 'facebook')
    if (matchedPlatform) {
      openNotification(
        'top',
        'info',
        'Platform Integration',
        <FacebookSvg width={25} height={25} />,
        'Facebook already integrated.',
        4
      )
    } else {
      await facebookAuthorizationContext()
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')
    const fun = async () => {
      const token = await userAuth?.getIdToken(true)
      console.log('token', token)
      return token
    }
    if (code)
      (async () => {
        const temp = await fun()
        console.log('temp', temp)
        if (temp) {
          try {
            if (called.current) return
            called.current = true
            const platformForIntegrate = window.sessionStorage.getItem(
              'platformForIntegrate'
            )
            const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/add/platform/${platformForIntegrate}?code=${code}`
            const resp = await fetch(
              apiUrl,
              makeHeader('POST', temp, {
                workspaceId: window.sessionStorage.getItem('spaceId'),
                platform: platformForIntegrate,
              })
            )
            if (resp.status === 200)
              window.location.assign(
                `${process.env.REACT_APP_CLIENT_URL}/social-connections`
              )
          } catch (err) {
            console.error(err)
          }
        }
      })()
  }, [])

  const click = async (index, matchedPlatform) => {
    index === 0
      ? FacebookAuthorization(matchedPlatform)
      : index === 1
      ? instagramAuthorization(matchedPlatform)
      : index === 2
      ? console.log('LinkedIn')
      : index === 3
      ? console.log('Instagram')
      : index === 4
      ? console.log('Twitter')
      : index === 5
      ? console.log('Youtube')
      : index === 6
      ? console.log('Pinterest')
      : console.log('TikTok')
  }
  const getSpecificSpaces = async () => {
    setLoading(true)
    try {
      if (userAuth && currentUser) {
        const targetSpace = await getSpecificSpacesAPI(
          userAuth,
          currentUser,
          spaceId
        )
        setTarget(targetSpace)
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    setSlideClass('slide-in')
    const fetchSpace = async () => {
      if (userAuth) {
        await getSpecificSpaces()
      }
    }
    fetchSpace()
  }, [userAuth])
  const theme = useTheme()
  return (
    <div className={`transition-container ${slideClass}`}>
      {loading ? (
        <Box sx={workSocialPageStyle.circularProgressBox}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <>
          <Box sx={parentBox(theme)}>
            <Box sx={workSocialPageStyle.mainContainerBox}>
              <Box sx={workSocialPageStyle.crossBtnBox}>
                <IconButton
                  onClick={backNavigate}
                  sx={workSocialPageStyle.crossButton}
                >
                  <CloseRoundedIcon sx={{ background: '' }} />
                </IconButton>
              </Box>
              <Box sx={workSocialPageStyle.typographyMainBox}>
                <Typography sx={headingTypography(theme)}>
                  Connect your pages
                </Typography>
                <Typography sx={paragraphTypography(theme)}>
                  Add all your brands content channels. You can always add more
                  later.
                </Typography>
              </Box>
              <Box sx={workSocialPageStyle.socialMediaMainBox}>
                {objectsArray.map((item, index) => {
                  const matchedPlatform = target?.platforms?.find(
                    (platform) => platform.name === item.heading.toLowerCase()
                  )
                  return (
                    <Box
                      onClick={() => click(index, matchedPlatform)}
                      key={index}
                      sx={workSocialPageStyle.mapingDataBox(item, target)}
                    >
                      {contextHolder}
                      {matchedPlatform && (
                        <CheckCircleRounded
                          sx={workSocialPageStyle.checkCircleRoundedStyle}
                        />
                      )}
                      {item.image}
                      <Box sx={workSocialPageStyle.TypographyMainBox}>
                        <Typography sx={headingText(theme)}>
                          {item.heading}
                        </Typography>
                        <Typography sx={descriptionText(theme)}>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
              <Box sx={workSocialPageStyle.btnBox}>
                <Button
                  onClick={() => navigate('/dashboard')}
                  variant='outlined'
                  sx={socialPageButton(theme)}
                >
                  Go Back
                </Button>
                <Button
                  variant='outlined'
                  sx={socialPageButton(theme)}
                  onClick={backNavigate}
                >
                  Connect Later
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </div>
  )
}

export default WorkSpaceSocialPage
