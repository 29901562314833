import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { nextButton } from "../../../instagramStyle";
import { useTheme } from "@emotion/react";
import { ArrowDownward, KeyboardArrowDownOutlined } from "@mui/icons-material";
import CustomMenu from "../../../../../../ReusableComponents/customMenu";
import { useParams } from "react-router-dom";
import { Loader } from "@infostacks/ui-components";
import { formatDate } from "../../../../../../../API";

const ManualPostFooter = ({
  loading,
  handleOptionSelect,
  selectedOption,
  anchorEl,
  handleClosebtn,
  handleClick,
  handleForm,
  ScheduleTime,
  setTimeModal,
}) => {
  const menuItems = [
    {
      label: "Publish",
      onClick: () => handleOptionSelect("Publish"),
    },
    {
      label: "Schedule",
      onClick: () => handleOptionSelect("Schedule"),
    },
    {
      label: "Draft",
      onClick: () => handleOptionSelect("Draft"),
    },
  ];

  // const handleOptionSelect = (option) => {
  //   // console.log('agya 123123123', option)
  //   setSelectedOption(option)
  //   handleClosebtn()
  // }

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          background: "",
          display: "flex",
          justifyContent: "end",
          gap: "8px",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
          paddingX: "20px",
          mt: "auto",
        }}
      >
        <Box
          sx={{
            display:
              ScheduleTime && selectedOption === "Schedule" ? "flex" : "none",
            gap: "20px",
            background: "",
            alignItems: "center",
            mr: "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "18px",
              fontFamily: "Libre Franklin",
            }}
          >
            Schedule date: <b>{formatDate(ScheduleTime)}</b>
          </Typography>
          <Button
            onClick={() => setTimeModal(true)}
            sx={{
              textTransform: "capitalize",
              // border: "1px solid #000464",
              // borderRadius: "8px",
              boxSizing: "border-box",
              padding: "9px 12px",
              width: loading ? "64px" : "fit-content",
              height: "38px",
              fontSize: "14px",
              fontFamily: "Libre Franklin",
              fontWeight: 600,
              lineHeight: "20px",
              color: "#000464",
            }}
          >
            Edit
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0px",
            height: loading ? "38px" : "fit-content",
            width: loading ? "112.2px" : "fit-content",
            background: "#000464",
            borderRadius: "8px",
            alignItems: "center",
            boxSizing: "border-box",
            paddingRight: "7px",
            justifyContent: loading ? "center" : "normal",
          }}
        >
          {loading ? (
            <Loader thickness={7} size={30} color="white" />
          ) : (
            <>
              <Button
                onClick={() => handleForm()}
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  borderRadius: "8px",
                  boxSizing: "border-box",
                  padding: "10px 12px",
                  width: "fit-content",
                  height: "38px",
                  fontSize: "14px",
                  fontFamily: "Libre Franklin",
                  fontWeight: 600,
                  lineHeight: "20px",
                }}
              >
                {selectedOption}
              </Button>
              <Divider
                variant="middle"
                orientation="vertical"
                sx={{ height: "18px", bgcolor: "white" }}
              />
              <IconButton
                onClick={handleClick}
                sx={{ boxSizing: "border-box", padding: "4px" }}
              >
                <KeyboardArrowDownOutlined
                  sx={{
                    color: "white",
                    height: "18px",
                    width: "18px",
                    fontWeight: 700,
                    background: "",
                    ml: "2px",
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <CustomMenu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClosebtn}
        menuItems={menuItems}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </>
  );
};

export default ManualPostFooter;
