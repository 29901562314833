import { HomeOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { HomeLogo, homeTypography, sidebarHome } from "../sidebarStyle";
import { useTheme } from "@emotion/react";
import UserContext from "../../../Context/userContext";
import { useNavigate } from "react-router-dom";

const SideBarHome = ({ drawer, navigation }) => {
  const { activeSidebarOption, setActiveSidebarOption } =
    useContext(UserContext);
  const handleHome = (e) => {
    setActiveSidebarOption(e);

    navigation(e);
  };
  const theme = useTheme();
  return (
    <Box
      onClick={() => handleHome("/dashboard/home")}
      sx={sidebarHome(theme, activeSidebarOption)}
    >
      <Box sx={HomeLogo(drawer)}>
        <HomeOutlined sx={{ height: "26px", width: "26px" }} />
      </Box>
      <Typography sx={homeTypography(drawer, theme)}>Home</Typography>
    </Box>
  );
};

export default SideBarHome;
