import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import UserContext from '../../../Context/userContext'
import CustomMenu from '../../ReusableComponents/customMenu'
function MenuOptions({ anchorEl, selectMenu, open, id, allSpaces }) {
  const { spacesArray, setSpacesArray, setStateCheck, stateCheck } =
    useContext(UserContext)
  console.log('menu allSpaces', spacesArray)
  const navigate = useNavigate()
  const moveEditWorkspacePage = () => {
    window.sessionStorage.setItem('spaceId', id)
    // setStateCheck(!stateCheck)
    navigate(`/dashboard/workspace`)
    // navigate(`/dashboard/edit-role/${paramRoleId}`)
  }
  const theme = useTheme()
  const menuItems = [
    {
      label: 'View',
      onClick: moveEditWorkspacePage,
    },
    {
      label: 'Archive',
      onClick: () => selectMenu('archive'),
    },
  ]
  return (
    <CustomMenu
      anchorEl={anchorEl}
      open={open}
      onClose={selectMenu}
      menuItems={menuItems}
    />
  )
}

export default MenuOptions
