import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import createMemberFormStyle from '../../InviteLandingPage/CreateMemberForm/createMemberFormStyle'
import { useForm } from 'react-hook-form'
import {
  companyResolver,
  companyValidator,
} from '../../../Validators/authValidator'
import { Loader } from '@infostacks/ui-components'
import {
  ContinueButton,
  labelTextfield,
} from '../Workspace/NewEditWorkspaceForm/newEditWorkspaceStyle'
import { commonTextFieldStyle } from '../User/UserEditProfile/userEditProfileStyle'
import { useTheme } from '@emotion/react'
import { Close } from '@mui/icons-material'
import UserContext from '../../../Context/userContext'

const UpdateCompanyAndAccountTypeForm = ({
  open,
  setUpdateCompanyAndAccountTypeModal,
}) => {
  const theme = useTheme()
  const [btnLoader, setBtnLoader] = useState()
  const { updateMemberToUserContext, userAuth } = useContext(UserContext)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = async (data) => {
    console.log('data', data)
    const res = await updateMemberToUserContext(userAuth, data)
    console.log('res of member to user', res)
  }

  return (
    <Modal open={open} sx={createMemberFormStyle.model_Style}>
      <Box sx={createMemberFormStyle.parent_Box}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={createMemberFormStyle.form_Style}
        >
          <Box sx={createMemberFormStyle.title_Box}>
            <Box sx={createMemberFormStyle.close_Box}>
              <Typography sx={createMemberFormStyle.heading_Text}>
                Add your company
              </Typography>
              <IconButton
                onClick={() => setUpdateCompanyAndAccountTypeModal(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Typography sx={createMemberFormStyle.desc_Text}>
              Please provide your company
            </Typography>
          </Box>
          <Box sx={createMemberFormStyle.allFieldsWrapper}>
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Company
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.company)}
                  fullWidth
                  variant='outlined'
                  placeholder='Company Name'
                  autoComplete='off'
                  {...register('company', companyValidator)}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.company && touchedFields.company
                    ? companyResolver[errors.company?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
          </Box>
          <Button type='submit' variant='contained' sx={ContinueButton(theme)}>
            {btnLoader ? <Loader thickness={7} size={30} /> : 'Continue'}
          </Button>
        </form>
      </Box>
    </Modal>
  )
}

export default UpdateCompanyAndAccountTypeForm
