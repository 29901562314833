import { ExpandMoreOutlined, WorkspacesOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  spaceExpandLogo,
  spaceLogoBox,
  spaceName,
  spacesMainBox,
} from '../sidebarStyle'
import { useTheme } from '@emotion/react'

const ActiveSpaceSection = ({ spaceMenuOpen, drawer, activeSpace }) => {
  console.log('activeSpace in sidebar', activeSpace)
  const theme = useTheme()
  return (
    <>
      <Box onClick={spaceMenuOpen} sx={spacesMainBox(theme)}>
        {activeSpace[0]?.imageUrl ? (
          <img
            src={activeSpace[0]?.imageUrl}
            style={spaceLogoBox(drawer)}
            alt='active-space'
          />
        ) : (
          <WorkspacesOutlined sx={{ height: '26px', width: '26px' }} />
        )}
        <Typography sx={spaceName(drawer, theme)}>
          {activeSpace[0]?.name || 'Space'}
        </Typography>
        <ExpandMoreOutlined sx={spaceExpandLogo(drawer)} />
      </Box>
    </>
  )
}

export default ActiveSpaceSection
