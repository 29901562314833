const emptyScreenStyle = {
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '',
    width: 'fit-content',
    my: 'auto',
  },
  fbImage_Style: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
  },
  boostEngagement: (theme) => ({
    fontSize: theme.typography.extraLarge.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: 'normal',
    letterSpacing: '-1.52px',
    textAlign: 'center',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    mt: '32px',
  }),
  descText: (theme) => ({
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '27px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.light,
    mt: '8px',
    textAlign: 'center',
  }),
  connectFacebook_Button: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    lineHeight: '20px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.customWhite.main,
    width: 'fit-content',
    mt: '16px',
    textTransform: 'capitalize',
    display: 'flex',
    boxSizing: 'border-box',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    background: theme.palette.primary.dark,
    ':hover': { background: theme.palette.primary.dark },
  }),
  footer_Box: {
    width: '100%',
    background: '',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    boxSizing: 'border-box',
    padding: '0px',
    overflow: 'hidden',
  },
  engagement_Text: (theme) => ({
    fontSize: theme.typography.mediumText.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    lineHeight: '30px',
    textAlign: 'center',
    width: 'fit-content',
  }),
  swiper_Style: {
    width: '73%',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '',
  },
  swiperSlide_Style: (theme) => ({
    background: theme.palette.gray.main,
    boxSizing: 'border-box',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.sky.light}`,
  }),
  image_Style: {
    borderRadius: '50%',
    height: '30px',
    width: '30px',
  },
  swiperTitle_Text: (theme) => ({
    fontSize: theme.typography.mediumText.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    lineHeight: '30px',
  }),
  swiperDesc_Text: (theme) => ({
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    lineHeight: '24px',
  }),
}
export default emptyScreenStyle
