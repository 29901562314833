import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import {
  completedTaskStyle,
  textStyle,
  valueTextStyle,
} from './styleUserProfile'
import { useTheme } from '@emotion/react'

function CompletedTask() {
  const value = 6
  const total = 8
  const percentage = (value / total) * 100
  const theme = useTheme()
  return (
    <Box sx={completedTaskStyle.parent_Box(theme)}>
      <Box sx={completedTaskStyle.main_Box}>
        <Typography sx={textStyle(theme)}>Completed Tasks</Typography>
        <Typography sx={valueTextStyle(theme)}>{value}</Typography>
      </Box>
      <Box sx={completedTaskStyle.circularProgress_Box}>
        <CircularProgress
          variant='determinate'
          value={100}
          thickness={7}
          sx={completedTaskStyle.circularProgress_Style(theme)}
        />
        <CircularProgress
          variant='determinate'
          value={percentage}
          thickness={7}
          sx={completedTaskStyle.percentage_Progress(theme)}
        />
        <Typography sx={completedTaskStyle.percentage_Text(theme)}>
          {`${value}/${total}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default CompletedTask
