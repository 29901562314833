import makeHeader from '../Headers'

const signupAPI = async (UserCredentials, bodyObject) => {
  // console.log('object',UserCredentials,bodyObject);
  let response = null
  await UserCredentials.user
    .getIdToken(true)
    .then(async function (idToken) {
      // Send token to your backend via HTTPS
      // ...
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/auth/signup`
      // console.log("API URL:", apiUrl);

      await fetch(apiUrl, makeHeader('POST', idToken, bodyObject))
        .then((response) => response.json())
        .then((data) => {
          response = data
        })
        .catch((error) => {
          throw new Error(error)
        })
    })
    .catch(function (error) {
      throw new Error(error)
    })
  return response
}

// const {setCurrentUser}=useContext(UserContext)

const loginAPI = async (UserCredentials) => {
  console.log('UserCredentials', UserCredentials)
  let response = null
  await UserCredentials.user
    .getIdToken(true)
    .then(async function (idToken) {
      // Send token to your backend via HTTPS
      // ...
      await fetch(
        `${process.env.REACT_APP_ANALYTICS_API}/auth/login`,
        makeHeader('GET', idToken)
      )
        .then((response) => response.json())
        .then((data) => {
          response = data
        })
        .catch((error) => {
          throw new Error(error)
        })
    })
    .catch(function (error) {
      throw new Error(error)
    })
  return response
}

const forgotPasswordAPI = async (email) => {
  let myResponse = { error: 1, message: '' }
  await fetch(
    `${process.env.REACT_APP_ANALYTICS_API}/auth/forgot-password/${email}`,
    makeHeader('POST', null)
  )
    .then((response) => response.text())
    .then((data) => {
      myResponse.error = 0
      myResponse.message = data
    })
    .catch((error) => {
      console.log(error, 'API')
    })

  return myResponse
}

const emailVerificationAPI = async (token) => {
  let myResponse = { error: 1, message: '' }
  await fetch(
    `${process.env.REACT_APP_ANALYTICS_API}/auth/email-verification`,
    makeHeader('POST', token)
  )
    .then((response) => response.text())
    .then((data) => {
      myResponse.error = 0
      myResponse.message = data
    })
    .catch((error) => {
      console.log(error, 'API')
    })

  return myResponse
}

const signOutAPI = async (token) => {
  let myResponse = { error: 1, message: '' }
  await fetch(
    `${process.env.REACT_APP_ANALYTICS_API}/auth/sign-out`,
    makeHeader('GET', token)
  )
    .then((response) => response.text())
    .then((data) => {
      myResponse.error = 0
      myResponse.message = data
    })
    .catch((error) => {
      myResponse.message = error.message
    })

  return myResponse
}

const updateUserProfile = async (token, body, Id) => {
  console.log('body', body, 'id', Id)
  const idToken = await token?.getIdToken(true)

  console.log('auth', token)
  let myResponse = { error: 1, message: '', user: null }
  await fetch(
    `${process.env.REACT_APP_ANALYTICS_API}/auth/update-user/${Id}`,
    makeHeader('PUT', idToken, body)
  )
    .then((response) => response.json())
    .then((user) => {
      console.log('data', user)
      // setCurrentUser(user.user)
      myResponse.error = 0
      myResponse.message = user.message
      myResponse.user = user.user
    })
    .catch((error) => {
      throw new Error(error)
    })
  return myResponse
}

const getUserProfileAPI = async (token, Id) => {
  const idToken = await token?.getIdToken(true)

  // console.log('auth', token)
  let myResponse = { error: 1, user: null }
  await fetch(
    `${process.env.REACT_APP_ANALYTICS_API}/auth/user/${Id}`,
    makeHeader('GET', idToken)
  )
    .then((response) => response.json())
    .then((user) => {
      // console.log('data', user)

      myResponse.error = 0
      myResponse.user = user
    })
    .catch((error) => {
      throw new Error(error)
    })
  return myResponse
}

export {
  signupAPI,
  emailVerificationAPI,
  loginAPI,
  forgotPasswordAPI,
  signOutAPI,
  updateUserProfile,
  getUserProfileAPI,
}
