import { AutorenewRounded } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import React from 'react'

const CustomToolTip = ({ onClick, disabled, icon, title, bgColor }) => {
  return (
    <Tooltip
      title={title}
      placement='top'
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
        tooltip: {
          sx: {
            backgroundColor: bgColor || 'default',
          },
        },
        arrow: {
          sx: {
            color: bgColor || 'default',
          },
        },
      }}
    >
      <Box>
        <IconButton disabled={disabled} onClick={onClick}>
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  )
}

export default CustomToolTip
