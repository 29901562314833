import { Box, Typography } from '@mui/material'
import React from 'react'
import vision1 from '../../Assets/Images/vision.png'
import vision2 from '../../Assets/Images/vision2.png'
import vision3 from '../../Assets/Images/vision3.png'
import BottomSection from './bottomSection'
import { ReusableButton } from '../ReusableComponents/customButtons'
import Vector from '../../Assets/Images/vecto.png'
import { SocialMediaContentStyle } from './landingPageStyle'

const features = [
  {
    image: vision1,
    title: 'Tell us your vision',
    description:
      'Choose a plan and share your design project details with us: we’re here to listen',
  },
  {
    image: vision2,
    title: 'Receive the magic',
    description:
      'Sit back and relax: our expert designers will turn your vision into reality.',
  },
  {
    image: vision3,
    title: 'Get ongoing support',
    description:
      'Your subscription ensures you have continuous access to our design team.',
  },
]

function SocialMediaContent() {
  return (
    <>
      <Box sx={SocialMediaContentStyle.container}>
        <Box sx={SocialMediaContentStyle.contentSection}>
          <Box sx={SocialMediaContentStyle.buttonSection}>
            <ReusableButton
              icon={
                <img
                  src={Vector}
                  alt='Add'
                  style={{ width: '24px', height: '24px' }}
                />
              }
              buttonText={'How it works'}
            />
            <Typography sx={SocialMediaContentStyle.titleText}>
              Enhanced social media content, powered by{' '}
              <span
                style={{
                  background:
                    'linear-gradient(270deg, #62FDEA 33.86%, #3354FF 59.39%)',
                  backgroundClip: 'text',
                  webkitBackgroundClip: 'text',
                  webkitTextFillColor: 'transparent',
                  fontFamily: 'Libre Franklin',
                  fontSize: '48px',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  textDecorationLine: 'underline',
                }}
              >
                AI.
              </span>
            </Typography>
          </Box>

          <Box sx={SocialMediaContentStyle.featuresContainer}>
            {features.map((feature, index) => (
              <Box key={index} sx={SocialMediaContentStyle.featureBox}>
                <Box sx={SocialMediaContentStyle.iconContainer}>
                  <img
                    src={feature.image}
                    width='60px'
                    alt={`${feature.title} Icon`}
                  />
                </Box>
                <Box
                  sx={{
                    paddingRight: '28px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Typography sx={SocialMediaContentStyle.featureTitle}>
                    {feature.title}
                  </Typography>
                  <Typography sx={SocialMediaContentStyle.featureDescription}>
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <BottomSection />
    </>
  )
}

export default SocialMediaContent
