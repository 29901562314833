import { useTheme } from '@emotion/react'
import {
  BorderColorOutlined,
  CheckCircleRounded,
  GridView,
  HelpRounded,
  WarningAmberRounded,
} from '@mui/icons-material'
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import CustomAccordion from '../../../../ReusableComponents/customAccordion'
import CustomDialog from '../../../../ReusableComponents/customDialoge'
import UserContext from '../../../../../Context/userContext'
import {
  addNewRoleAPI,
  getAllPermissionAPI,
  getAllRolesAPI,
  specificRolePermissionAPI,
  updateSpecificRoleAPI,
} from '../../../../../API'
import {
  actionStyle,
  btn_Box,
  createRolePageStyle,
  discard_ButtonStyle,
  paragraph_Text,
  save_ButtonStyle,
} from './workspaceRoleAndAdminStyle'
import { Loader } from '@infostacks/ui-components'

const CreateRolePage = () => {
  const { userAuth, currentUser, buttonState, setButtonState, stateCheck } =
    useContext(UserContext)
  const theme = useTheme()
  const [workSpacePermissions, setWorkSpacePermissions] = useState([])
  const [platformPermissions, setPlatformPermissions] = useState([])
  const spaceId = window.sessionStorage.getItem('spaceId')
  console.log('spaceId', spaceId)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedWorkspacePermissions, setSelectedWorkspacePermissions] =
    useState([])
  const [selectedPlatformPermissions, setSelectedPlatformPermissions] =
    useState([])
  const [neastedPlatformPermissions, setNeastedPlatformPermissions] = useState(
    []
  )
  console.log('neastedPlatformPermissions', neastedPlatformPermissions)
  console.log(
    'selectedWorkspacePermissions',
    selectedWorkspacePermissions,
    'selectedPlatformPermissions',
    selectedPlatformPermissions
  )
  const [roles, setRoles] = useState([])
  const [newRole, setNewRole] = useState('')
  const [originalRole, setOriginalRole] = useState('')
  const [originalRoleId, setOriginalRoleId] = useState()
  const [tick, setTick] = useState(false)
  const { register, handleSubmit } = useForm()
  const [loading, setLoading] = useState()
  const handleInputChange = () => {
    const value = event.target.value.replace(/\s+/g, '')
    setNewRole(value)
    if (value.length >= 3) {
      const exists = roles.some(
        (role) => role.roleName !== originalRole && role.roleName === value
      )
      setTick(exists)
    } else {
      setTick(false)
    }
  }
  const navigate = useNavigate()

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleConfirmAction = () => {
    buttonState === 'create'
      ? navigate('/dashboard/workspace')
      : buttonState === 'edit'
      ? navigate('/dashboard/manage-role')
      : buttonState === 'addRole'
      ? navigate('/dashboard/roles')
      : navigate('/dashboard/workspace')
  }

  const handlePermissionChange = (permission, type) => {
    console.log('Permission:', permission)
    if (type === 'workspace') {
      setSelectedWorkspacePermissions((prev) => {
        if (permission === 'all') {
          return prev.includes('all-workspace')
            ? prev.filter((item) => item !== 'all-workspace')
            : [...workSpacePermissions, 'all-workspace']
        }
        return prev.includes(permission)
          ? prev.filter((item) => item !== permission)
          : [...prev, permission]
      })
    } else {
      setSelectedPlatformPermissions((prev) => {
        const platformPermissionsList = neastedPlatformPermissions[type] || []
        if (permission === 'all') {
          return prev.includes(`all-${type}`)
            ? prev.filter(
                (item) =>
                  item !== `all-${type}` &&
                  !platformPermissionsList.includes(item)
              )
            : [...platformPermissionsList, `all-${type}`]
        }
        return prev.includes(permission)
          ? prev.filter((item) => item !== permission)
          : [...prev, permission]
      })
    }
  }

  const getAPIs = async (id, button) => {
    setLoading(true)
    try {
      const allRoles = await getAllRolesAPI(userAuth, spaceId)
      console.log('allRoles in create new role page', allRoles)
      setRoles(allRoles)

      if (userAuth && currentUser) {
        const respGetAllPermissionAPI = await getAllPermissionAPI(
          userAuth,
          'ailytics'
        )
        console.log('resp from APIIIII', respGetAllPermissionAPI)
        console.log(
          'resp from API',
          Object.keys(respGetAllPermissionAPI.permissions)
        )
        setWorkSpacePermissions(respGetAllPermissionAPI?.permissions?.workspace)
        setPlatformPermissions(
          respGetAllPermissionAPI?.permissions?.platform?.facebook
        )
        setNeastedPlatformPermissions(
          respGetAllPermissionAPI?.permissions?.platform
        )
        if (button === 'edit') {
          const specificRoleCreatePage = await specificRolePermissionAPI(
            userAuth,
            id
          )
          console.log('specificRoleCreatePage', specificRoleCreatePage)
          console.log('state bol', buttonState)
          setSelectedPlatformPermissions(
            specificRoleCreatePage?.permissions.platform
          )
          setSelectedWorkspacePermissions(
            specificRoleCreatePage?.permissions.workSpace
          )
        }
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onSubmit = async (data) => {
    console.log('Form Data:', data)
    setLoading(true)
    try {
      const finalWorkspacePermissions = workSpacePermissions.reduce(
        (acc, value) => {
          acc[value] = selectedWorkspacePermissions.includes(value)
          return acc
        },
        {}
      )
      const finalPlatformPermissions = platformPermissions.reduce(
        (acc, value) => {
          acc[value] = selectedPlatformPermissions.includes(value)
          return acc
        },
        {}
      )

      finalWorkspacePermissions.Read = true
      finalPlatformPermissions.Read = true

      const spaceAccessStatus = Object.values(finalWorkspacePermissions).some(
        (value) => value === false
      )
      const platformAccessStatus = Object.values(finalPlatformPermissions).some(
        (value) => value === false
      )
      const formData = {
        ...data,
        permissions: {
          workSpace: finalWorkspacePermissions,
          platform: finalPlatformPermissions,
        },
        access:
          spaceAccessStatus === false && platformAccessStatus === false
            ? 'All'
            : 'Limited',
        roleName: newRole,
        workspaceId: spaceId,
      }

      console.log('Form Data:', formData)
      if (buttonState === 'edit') {
        delete formData.workspaceId
        const updateRoleResponse = await updateSpecificRoleAPI(
          userAuth,
          originalRoleId,
          formData
        )
        console.log('updateRoleResponse', updateRoleResponse)
        navigate('/dashboard/roles')
      } else if (buttonState === 'create') {
        const addNewRoleApiResponse = await addNewRoleAPI(userAuth, formData)
        console.log('addNewRoleApiResponse', addNewRoleApiResponse)
        navigate('/dashboard/workspace')
      } else if (buttonState === 'addRole') {
        const addNewRoleApiResponse = await addNewRoleAPI(userAuth, formData)
        console.log('addNewRoleApiResponse', addNewRoleApiResponse)
        navigate('/dashboard/roles')
      }
      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setButtonState(window.sessionStorage.getItem('buttonState'))
    const storedRoleData = JSON.parse(
      window.sessionStorage.getItem('manageRole')
    )
    console.log('storedRoleData', storedRoleData)
    setNewRole(storedRoleData?.roleName || '')
    setOriginalRole(storedRoleData?.roleName || '')
    setOriginalRoleId(storedRoleData?._id)

    if (userAuth) {
      getAPIs(storedRoleData?._id, window.sessionStorage.getItem('buttonState'))
    }
  }, [userAuth, stateCheck])

  return (
    <Box sx={createRolePageStyle.parent_Box}>
      {loading ? (
        <Box sx={createRolePageStyle.loader_Box}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={createRolePageStyle.formStyle}
        >
          <Box sx={createRolePageStyle.textField_BoxMain}>
            <TextField
              fullWidth
              variant='standard'
              autoComplete='off'
              placeholder='Role Name'
              value={newRole}
              onChange={handleInputChange}
              // {...register('roleName')}
              sx={createRolePageStyle.textFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <BorderColorOutlined sx={{ color: '#494949' }} />
                  </InputAdornment>
                ),
              }}
            />
            <CheckCircleRounded
              sx={{
                color: 'green',
                display: tick === true || newRole.length < 3 ? 'none' : 'flex',
              }}
            />
            <Tooltip
              title='This role is already in use'
              placement='top'
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <HelpRounded
                sx={{
                  color: 'red',
                  display: tick === true ? 'flex' : 'none',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>

            <Box sx={btn_Box(tick, newRole)}>
              <Button
                variant='outlined'
                sx={discard_ButtonStyle(theme)}
                onClick={handleOpenDialog}
              >
                Discard
              </Button>
              <Button
                type='submit'
                variant='outlined'
                sx={save_ButtonStyle(theme)}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Box sx={createRolePageStyle.text_Box}>
            <span style={createRolePageStyle.span_Style}>
              <Typography sx={actionStyle(theme)}>Actions</Typography>
              <Typography sx={paragraph_Text(theme)}>
                Define the permissions for the new role to manage access and
                responsibilities within your workspace. Select the appropriate
                permissions from the list below.
              </Typography>
            </span>
            <Box sx={createRolePageStyle.customAccordion_Box}>
              <CustomAccordion
                title='Workspace Permissions'
                data={workSpacePermissions}
                selectedPermissions={selectedWorkspacePermissions}
                icon={<WorkspacesIcon />}
                type='workspace'
                showCheckboxes={true}
                totalLength={workSpacePermissions.length}
                selectedLength={selectedWorkspacePermissions.length}
                handlePermissionChange={(permission) =>
                  handlePermissionChange(permission, 'workspace')
                }
              />
              {/* <CustomAccordion
                title='Platforms Permissions'
                data={platformPermissions}
                selectedPermissions={selectedPlatformPermissions}
                icon={<GridView />}
                type='platform'
                showCheckboxes={true}
                totalLength={platformPermissions.length}
                selectedLength={selectedPlatformPermissions.length}
                handlePermissionChange={(permission) =>
                  handlePermissionChange(permission, 'platform')
                }
              /> */}
              <CustomAccordion
                title='Platforms Permissions'
                icon={<GridView />}
                showCheckboxes={true}
              >
                {Object.keys(neastedPlatformPermissions).map(
                  (platformName, ind) => (
                    <CustomAccordion
                      key={ind}
                      title={platformName}
                      data={neastedPlatformPermissions[platformName]}
                      icon={<GridView />}
                      type={platformName}
                      showCheckboxes={true}
                      handlePermissionChange={(permission) =>
                        handlePermissionChange(permission, platformName)
                      }
                      selectedPermissions={selectedPlatformPermissions}
                      totalLength={
                        neastedPlatformPermissions[platformName].length
                      }
                      selectedLength={
                        selectedPlatformPermissions.filter((permission) =>
                          neastedPlatformPermissions[platformName].includes(
                            permission
                          )
                        ).length
                      }
                    />
                  )
                )}
              </CustomAccordion>
            </Box>
          </Box>
        </form>
      )}
      <CustomDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
        dialogTitle='Discard Role'
        dialogDescription='You have unsaved changes. Are you sure you want to discard them?'
        confirmButtonText='Discard'
        icon={
          <WarningAmberRounded
            sx={{ color: 'red', height: '30px', width: '30px' }}
          />
        }
      />
    </Box>
  )
}

export default CreateRolePage
