import { useTheme } from '@emotion/react'
import { Box, Button } from '@mui/material'
import React from 'react'

const RepostFooter = ({ inputStr, images }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        width: '95%',
        background: '',
        alignItems: 'center',
        justifyContent: 'end',
        height: '50px',
        gap: '20px',
        visibility: inputStr || images.length > 0 ? 'visible' : 'hidden',
      }}
    >
      <Button
        type='submit'
        sx={{
          textTransform: 'capitalize',
          color: 'white',
          boxSizing: 'border-box',
          padding: '0px',
          height: '35px',
          paddingX: '12px',
          borderRadius: '8px',
          mr: '0px',
          fontFamily: theme.typography.h2.fontFamily,
          fontWeight: theme.typography.h3.fontWeight,
          background: theme.palette.primary.dark,
          fontSize: '15px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '0.075px',
          ':hover': {
            background: theme.palette.primary.dark,
          },
        }}
      >
        {'Publish'}
      </Button>
    </Box>
  )
}

export default RepostFooter
