import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReusableButton } from '../../ReusableComponents/customButtons'
import Vector from '../../../Assets/Images/vecto.png'
import Growth from '../../../Assets/Images/landingpagegrowth.png'
import Chart from '../../../Assets/Images/landingpageg.png'
import Cost from '../../../Assets/Images/landingpagecost.png'
import HorizontalFeatures from './horizontalFeatures'
import { featureSectionStyles } from '../landingPageStyle'

function FeatureSection() {
  return (
    <Box sx={featureSectionStyles.container}>
      <Box sx={featureSectionStyles.innerContainer}>
        <ReusableButton
          icon={
            <img
              src={Vector}
              alt='Add'
              style={{ width: '24px', height: '24px' }}
            />
          }
          buttonText={'What you’ll get'}
        />
        <Typography sx={featureSectionStyles.titleText}>
          <span style={featureSectionStyles.highlightedText}>AI</span> -assisted
          creativity for optimized social media engagement.
        </Typography>
      </Box>

      <Box sx={featureSectionStyles.featureCardContainer}>
        <Box sx={featureSectionStyles.cardBox}>
          <img
            src={Growth}
            alt='Growth'
            width='100%'
            height='100%'
            style={{
              borderRadius: '24px',
              objectFit: 'cover',
            }}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={featureSectionStyles.cardText}>
              Cost effective solution
            </Typography>
            <Typography sx={featureSectionStyles.cardDescription}>
              Get high-quality design work at a fraction of the cost.
            </Typography>
          </Box>
        </Box>

        <Box sx={featureSectionStyles.cardBox}>
          <img src={Cost} alt='Cost' width='100%' height='100%' />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={featureSectionStyles.cardText}>
              Cost effective solution
            </Typography>
            <Typography sx={featureSectionStyles.cardDescription}>
              Get high-quality design work at a fraction of the cost.
            </Typography>
          </Box>
        </Box>

        <Box sx={featureSectionStyles.cardBox}>
          <img src={Chart} alt='Cost' width='100%' height='100%' />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography sx={featureSectionStyles.cardText}>
              Cost effective solution
            </Typography>
            <Typography sx={featureSectionStyles.cardDescription}>
              Get high-quality design work at a fraction of the cost.
            </Typography>
          </Box>
        </Box>
      </Box>

      <HorizontalFeatures />
    </Box>
  )
}

export default FeatureSection
