import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles, { pricingHeading, pricingSubHeading } from './pricingStyle'
import UserContext from '../../Context/userContext'
import { useTheme } from '@emotion/react'
const PricingHeader = ({
  setBillingCycle,
  billingCycle,
  handleBillingCycleChange,
}) => {
  const { userAuth, currentUser } = useContext(UserContext)
  const theme = useTheme()
  return (
    <Box sx={styles.pricingHeadingBox}>
      <Typography sx={pricingHeading(theme)}>Supercharge with AI.</Typography>
      <Typography sx={pricingSubHeading(theme)}>
        Choose the plan that works for you.
      </Typography>

      <Box sx={styles.btnBox}>
        <Box sx={styles.bubble(billingCycle, theme)} />
        <Button
          disabled={currentUser?.Subscription?.billingInterval === 'yearly'}
          sx={styles.quarterlyBtn(billingCycle, theme)}
          onClick={() => handleBillingCycleChange('quarterly')}
        >
          Quarterly
        </Button>
        <Button
          disabled={currentUser?.Subscription?.billingInterval === 'quarterly'}
          sx={styles.yearlyBtn(billingCycle, theme)}
          onClick={() => handleBillingCycleChange('yearly')}
        >
          Yearly
        </Button>
      </Box>
    </Box>
  )
}

export default PricingHeader
