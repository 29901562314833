import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import aiImage from '../../../../../../Assets/Images/rectangleAi.png'
import { CloseRounded } from '@mui/icons-material'
import { BackIcon, RetryIcon } from '../../../../../PasswordStartAdornment'
import { Loader } from '@infostacks/ui-components'
const ResponseAiMedia = ({
  setIsGenerated,
  initialPrompt,
  handleInsert,
  handleSubmit,
  onSubmit,
  setTextPrompt,
  generatedContent,
  generatedImage,
}) => {
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(generatedContent)

  const backNavigate = () => {
    setIsGenerated(false)
  }
  const removeText = () => {
    setTextPrompt('')
  }
  const retryGenrateMedia = () => {
    handleSubmit(onSubmit)()
  }
  const onInsert = () => {
    setText(text)
    const dataSend = {
      text: text,
      // image: generatedImage ? ` data:image/png;base64,${generatedImage}` : null,
    }
    if (generatedImage) {
      dataSend.image = ` data:image/png;base64,${generatedImage}`
    }
    handleInsert(dataSend)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '95%',
        background: 'white',
        borderRadius: ' var(--Numbers-Number-12, 24px)',
        gap: '16px',
        mt: '16px',
        height: '645px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: '#FAFBFC',
          boxSizing: 'border-box',
          padding: '8px',
          borderRadius: '8px',
          gap: '8px',
          alignItems: 'center',
          height: '62px',
          width: '100%',
          border: '1px solid #D1D5DB',
        }}
      >
        <TextField
          variant='standard'
          value={initialPrompt}
          onChange={(e) => setTextPrompt(e.target.value)}
          fullWidth
          sx={{
            background: '#FAFBFC',
            width: '100%',
            color: '#6B7280',
            textAlign: 'start',
            fontFamily: 'Libre Franklin',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            '& textarea': {
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            },
          }}
          InputProps={{
            rows: 2,
            multiline: true,
            inputComponent: 'textarea',
            disableUnderline: true,
          }}
        />
        <IconButton onClick={removeText} sx={{ height: '16px', width: '16px' }}>
          <CloseRounded sx={{ color: 'gray', height: '20px', width: '20px' }} />
        </IconButton>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '516px',
            width: '100%',
          }}
        >
          <Loader thickness={7} size={30} />
        </Box>
      ) : (
        <>
          <TextField
            variant='standard'
            fullWidth
            value={text}
            sx={{
              boxSizing: 'border-box',
              paddingX: '8px',
              borderRadius: '8px',
              '& .MuiOutlinedInput-input': {
                color: '#000',
                fontFamily: 'Libre Franklin',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              },
              background: '#F9FAFB',
              width: '100%',
              border: '1px solid #D1D5DB',
              height: '178px',
              mx: 'auto',
              overflowY: 'auto',
              '& textarea': {
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              },
            }}
            InputProps={{
              rows: 7,
              multiline: true,
              inputComponent: 'textarea',
              disableUnderline: true,
              readOnly: true,
            }}
          />

          <img
            src={`data:image/png;base64,${generatedImage}`}
            width={'100%'}
            height={'320px'}
            style={{
              objectFit: 'cover',
              backgroundPosition: 'center',
              borderRadius: '8px',
              border: '1px solid #D1D5DB',
              visibility: generatedImage ? 'visible' : 'hidden',
            }}
          />
        </>
      )}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={backNavigate}
          variant='outlined'
          sx={{
            boxSizing: 'border-box',
            padding:
              'var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 'var(--Utilities-Spacing-2, 8px)',
            backgroundColor: '#DBEAFE',
            color: '#374151',
            fontFamily: 'Libre Franklin',
            fontSize: '16px',
            fontStyle: 'normal',
            textTransform: 'capitalize',
            fontWeight: '400',
            height: '35px',
            lineHeight: '24px',
            border: 'none',
            borderRadius: '8px',
            ':hover': { backgroundColor: '#DBEAFE', border: 'none' },
          }}
        >
          <BackIcon />
          Back
        </Button>
        <span style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={retryGenrateMedia}
            variant='outlined'
            sx={{
              boxSizing: 'border-box',
              padding:
                'var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 'var(--Utilities-Spacing-2, 8px)',
              color: '#374151',
              fontFamily: 'Libre Franklin',
              height: '35px',
              fontSize: '16px',
              fontStyle: 'normal',
              borderRadius: '8px',
              fontWeight: '400',
              textTransform: 'capitalize',
              lineHeight: '24px',
              border: 'none',
              ':hover': {
                backgroundColor: '#DBEAFE',
                border: 'none',
              },
            }}
          >
            <RetryIcon />
            Retry
          </Button>

          <Button
            onClick={onInsert}
            variant='contained'
            sx={{
              boxSizing: 'border-box',
              padding:
                ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
              backgroundColor: '#000464',
              color: 'white',
              fontFamily: 'Libre Franklin',
              fontSize: '16px',
              fontStyle: 'normal',
              height: '35px',
              fontWeight: '600',
              lineHeight: 'normal',
              letterSpacing: ' 0.075px',
              textTransform: 'capitalize',
              borderRadius: '8px',
              ':hover': { backgroundColor: '#000464' },
            }}
          >
            Insert
          </Button>
        </span>
      </Box>
    </Box>
  )
}

export default ResponseAiMedia
