import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SendRoundedIcon from '../../../../Assets/Images/sendIcon.png'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import HomePlatformSelector from './HomePlatformSelector/index'
import TrendingAiSearch from './TrendingAiSearch/index'
import { aiSearch } from '../homePageStyle'

function AiSearch() {
  return (
    <Box sx={aiSearch.main}>
      <Box sx={aiSearch.searchBarMain}>
        <TextField
          sx={aiSearch.textField}
          variant='outlined'
          placeholder='Type your post idea or trending topic...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Box sx={aiSearch.searchIconMain}>
                  <SearchIcon sx={aiSearch.searchIcon} />
                  <AutoAwesomeOutlinedIcon sx={aiSearch.outlineIcon} />
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <Box paddingLeft='10px'>
                <InputAdornment sx={aiSearch.searchInput}>
                  <IconButton aria-label='Send message'>
                    <img
                      src={SendRoundedIcon}
                      alt='Send'
                      style={aiSearch.sendicon}
                    />
                  </IconButton>
                </InputAdornment>
              </Box>
            ),
            sx: {
              display: 'flex',
              alignItems: 'center',
              height: '48px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#000',
                fontFamily: 'Libre Franklin',
                fontSize: '14px',
                fontWeight: '400',
              },
            },
          }}
        />
      </Box>
      <Box sx={aiSearch.bottom}>
        <HomePlatformSelector />
        {/* <TrendingAiSearch /> */}
      </Box>
    </Box>
  )
}

export default AiSearch
