import React, { useContext, useState } from 'react'
import PricingPlanCard from './PricingPlanCard'
import { Box, Button } from '@mui/material'
import PricingHeader from './pricingHeader'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import UserContext from '../../Context/userContext'
import {
  handleBuyAdsOnAPI,
  handleStripeAPI,
  handleUpdateSubscriptionAPI,
} from '../../API/Stripe'
import EnterpriseDialog from './enterpriseDialog'
import { Loader } from '@infostacks/ui-components'
import { useTheme } from '@emotion/react'
import { countineButton, foldingBoxStyles } from './pricingStyle'

const Pricing = () => {
  const navigate = useNavigate()
  const { userAuth, currentUser } = useContext(UserContext)
  const [isFolding, setIsFolding] = useState(false)
  const [credits, setCredits] = React.useState(10)
  const [teamUsers, setTeamUsers] = React.useState(2)
  const [billingCycle, setBillingCycle] = React.useState(
    currentUser?.Subscription?.billingInterval || 'quarterly'
  )
  const [activeCard, setActiveCard] = React.useState('Team Plan')
  const [enterpriseDialog, setEnterpriseDialog] = useState(false)
  const [loading, setloading] = useState(false)
  console.log('activeCard', activeCard)
  const planType = currentUser?.Subscription?.planType
  const interval = currentUser?.Subscription?.billingInterval

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY)

  const handleBillingCycleChange = (state) => {
    setBillingCycle(state)
    setCredits(10)
    setTeamUsers(2)
  }

  let paymentObject = {}
  const theme = useTheme()
  const handleContinue = async () => {
    setloading(true)
    try {
      if (activeCard === 'Basic Plan' && billingCycle) {
        paymentObject = {
          ...paymentObject,
          // credits: 10,
          members: 1,
          billingInterval: billingCycle,
          planType: 'basic',
        }
      } else if (activeCard === 'Individual' && billingCycle) {
        paymentObject = {
          ...paymentObject,
          // credits:credits,
          members: 1,
          billingInterval: billingCycle,
          planType: 'individual',
        }
      } else if (activeCard === 'Team Plan' && billingCycle) {
        paymentObject = {
          ...paymentObject,
          // credits:credits,
          members: teamUsers,
          billingInterval: billingCycle,
          planType: 'team',
        }
      } else if (activeCard === 'Enterprise' && billingCycle) {
        setloading(false)
        setEnterpriseDialog(true)
      }

      if (!planType) {
        console.log('first plan', paymentObject)
        const fetchSessionId = await handleStripeAPI(userAuth, paymentObject)
        if (fetchSessionId) {
          const stripe = await stripePromise
          const res = await stripe.redirectToCheckout({
            sessionId: fetchSessionId.sessionId,
          })
        }
      } else {
        delete paymentObject.billingInterval
        console.log('upgrade plan', paymentObject)
        const upgradeResponse = await handleUpdateSubscriptionAPI(
          userAuth,
          paymentObject
        )
        console.log('up res', upgradeResponse)
        if (upgradeResponse.message === 'Subscription Updated Successfully!') {
          navigate('/subscription-successfull')
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  console.log('billingCycle', billingCycle)
  return (
    <Box sx={foldingBoxStyles(isFolding, theme)}>
      <PricingHeader
        setBillingCycle={setBillingCycle}
        billingCycle={billingCycle}
        handleBillingCycleChange={handleBillingCycleChange}
      />
      <PricingPlanCard
        billingCycle={billingCycle}
        setCredits={setCredits}
        credits={credits}
        teamUsers={teamUsers}
        setTeamUsers={setTeamUsers}
        activeCard={activeCard}
        setActiveCard={setActiveCard}
      />
      <Button onClick={handleContinue} sx={countineButton(theme)}>
        {loading ? <Loader thickness={7} size={30} /> : 'Continue'}
      </Button>
      {enterpriseDialog ? (
        <EnterpriseDialog
          open={enterpriseDialog}
          onClose={setEnterpriseDialog}
        />
      ) : null}
    </Box>
  )
}

export default Pricing
