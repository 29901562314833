const chooseWorkspaceStyle = {
  parent_Box: (theme) => ({
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    background: 'white',
    boxSizing: 'border-box',
    paddingTop: '113px',
    paddingX: '0px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.customWhite.main,
  }),
  container_Box: {
    width: '926px',
    background: '',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  firstBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  workspace_Box: { width: '100%', display: 'flex' },
  welcome_Text_Style: (theme) => ({
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    lineHeight: 'normal',
    letterSpacing: '-1.6px',
  }),
  workspaceName_Text_Style: (theme) => ({
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.light,
    lineHeight: '24px',
    width: 'fit-content',
  }),
  pendingInvitation_Button: (option, pending, theme) => ({
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    ml: 'auto',
    cursor: 'pointer',
    padding: '0px',
    textTransform: 'capitalize',
    display: option === 'workspace' && pending.length >= 1 ? 'flex' : 'none',
    ':hover': { background: 'transparent' },
  }),
  viewWorkspaces_Button: (option, pending, theme) => ({
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    ml: 'auto',
    cursor: 'pointer',
    padding: '0px',
    textTransform: 'capitalize',
    display: option === 'workspace' && pending.length >= 1 ? 'flex' : 'none',
    ':hover': { background: 'transparent' },
  }),
  loader_Box: {
    position: 'fixed',
    color: '',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  optionWorkspace_Box: {
    width: '100%',
    height: 'fit-content',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    background: '',
  },
  map_Containor: (theme) => ({
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px 24px',
    display: 'flex',
    gap: '43px',
    background: theme.palette.gray.main,
    alignItems: 'center',
    border: `1px solid ${theme.palette.sky.light}`,
    borderRadius: '16px',
  }),
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    gap: '10px',
    height: 'fit-content',
  },
  workspaceName: (theme) => ({
    fontSize: theme.typography.mediumText.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    lineHeight: '30px',
  }),
  avatr_Box: {
    width: 'fit-content',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  roleName_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    boxSizing: 'border-box',
    padding: '4px 10px',
    borderRadius: '10px',
    background: theme.palette.sky.dark,
    lineHeight: '18px',
  }),

  memberName_Text: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.light,
    lineHeight: '20px',
  }),
  pendindWorkspace_Box: {
    width: '100%',
    height: 'fit-content',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    background: '',
  },
  desc_Text_Style: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.light,
    lineHeight: '20px',
  }),
  button_Box: {
    display: 'flex',
    height: 'fit-content',
    width: 'fit-content',
    gap: '8px',
    marginLeft: 'auto',
  },
  rejactButton_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingX: '20px',
    paddingY: '10px',
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.customWhite.main,
    borderRadius: '8px',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'capitalize',
    fontWeight: theme.typography.h2.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '20px',
    height: 'fit-content',
    ':hover': { background: theme.palette.error.dark },
  }),
  acceptButton_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingX: '20px',
    paddingY: '10px',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.customWhite.main,
    borderRadius: '8px',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'capitalize',
    fontWeight: theme.typography.h2.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '20px',
    height: 'fit-content',
    ':hover': { background: theme.palette.primary.dark },
  }),
  countine_Button: (theme) => ({
    boxSizing: 'border-box',
    paddingX: '20px',
    paddingY: '10px',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.customWhite.main,
    borderRadius: '8px',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'capitalize',
    fontWeight: theme.typography.h2.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    height: 'fit-content',
    ':hover': { background: theme.palette.primary.dark },
    lineHeight: 'normal',
    marginLeft: 'auto',
  }),
}
export default chooseWorkspaceStyle
