import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import {
  postModel,
  postModelButton2,
  uploadMoreBtn,
} from "../../../instagramStyle";
import ImageSwiperSection from "./imageSwiperSection";
import { useTheme } from "@emotion/react";

const ImageSection = ({
  getRootProps,
  media,
  setMedia,
  setImageSrc,
  setEditimageIndex,
  setEditModal,
  mediaType,
  setMediaType,
  loading,
}) => {
  const theme = useTheme();
  const handleChange = (event) => {
    setMediaType(event.target.value);
  };

  return (
    <Box sx={postModel.mediaBox}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "fit-content",
          justifyContent: "space-between",
          alignItems: "center",
          background: "",
        }}
      >
        <RadioGroup row value={mediaType} onChange={handleChange}>
          <FormControlLabel
            disabled={loading}
            value="post"
            control={<Radio sx={{ "&.Mui-checked": { color: "#000464" } }} />}
            label="Post"
            sx={{ color: "#000464", fontFamily: "Libre Franklin" }}
          />
          <FormControlLabel
            disabled={loading}
            value="story"
            control={<Radio sx={{ "&.Mui-checked": { color: "#000464" } }} />}
            label="Story"
            sx={{ color: "#000464", fontFamily: "Libre Franklin" }}
          />
        </RadioGroup>
        <Button
          disabled={media.length === 8 || loading}
          {...getRootProps()}
          sx={uploadMoreBtn(media, theme)}
        >
          Upload Max 8
        </Button>
      </Box>
      <ImageSwiperSection
        media={media}
        setMedia={setMedia}
        setImageSrc={setImageSrc}
        setEditimageIndex={setEditimageIndex}
        setEditModal={setEditModal}
      />
    </Box>
  );
};

export default ImageSection;
