import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import engments from './../../Assets/Images/engagments.png'
import totalSales from './../../Assets/Images/totalsales.png'
import growth from './../../Assets/Images/growth.png'
import testemonial from './../../Assets/Images/testemonial.png'
import 'swiper/swiper-bundle.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import image from './../../Assets/Images/signupImage.png'
import appLogo from '../../Assets/Images/aiLatics-Logo.png'
import signupPageStyles, {
  latics_Text_Style,
  parent_Box,
} from './signupPageStyles'
import { useForm } from 'react-hook-form'
import { useTheme } from '@emotion/react'
import CustomStepper from '../ReusableComponents/customStepper'
import SignupForms from './SignupForms/signupForms'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../Context/userContext'

const SignupPage = () => {
  const images = [engments, totalSales, growth, testemonial]
  const [step, setStep] = useState(1)
  const { userAuth, getLatestRoute, checkEmailExistContext, signUpContext } =
    useContext(UserContext)
  const navigate = useNavigate()
  const [selectedValue, setSelectedValue] = useState('Pakistan')
  const [apiError, setApiError] = useState()
  const [loading, setLoading] = useState(false)
  const [formArray, setFormArray] = useState([])
  // console.log('userAuth',userAuth);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm()

  const handleContinue = () => {
    setStep((prevStep) => Math.min(prevStep + 1, 3))
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const onSubmit = async (formData) => {
    const { firstname, lastname, email, password, phoneNumber, company } =
      formData
    let username = `${firstname} ${lastname}`

    console.log('Form Data:', formData)
    if (step === 1 && formData.email) {
      setApiError()
      setLoading(true)
      const checkEmail = await checkEmailExistContext({ email: formData.email })
      console.log('checkEmail', checkEmail)
      if (checkEmail === 'User Already have an Account') {
        setApiError(checkEmail)
      } else {
        handleContinue()
      }
      setLoading(false)
    }
    if (step === 2) {
      setLoading(true)
      formData.country = selectedValue
      handleContinue()
      setLoading(false)
    }
    if (step === 3) {
      setLoading(true)
      formData.country = selectedValue
      try {
        const response = await signUpContext(
          username,
          email,
          password,
          phoneNumber,
          company,
          selectedValue
        )
        console.log('response', response)
      } catch (error) {
        console.log('error', error)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (userAuth && userAuth.email) {
      if (userAuth.emailVerified) navigate(getLatestRoute())
      else navigate('/welcome')
    }
  }, [userAuth])

  const theme = useTheme()
  return (
    <Box sx={parent_Box(theme)}>
      <Box sx={signupPageStyles.main_Box}>
        <Box sx={signupPageStyles.formMainBox}>
          <Box onClick={() => navigate('/')} sx={signupPageStyles.logo_MainBox}>
            <img
              src={appLogo}
              alt='img'
              style={{ height: '33px', width: '33px' }}
            />
            <Typography sx={latics_Text_Style(theme)}>Latics</Typography>
          </Box>
          <SignupForms
            step={step}
            theme={theme}
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            errors={errors}
            touchedFields={touchedFields}
            handleChange={handleChange}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            watch={watch}
            loading={loading}
            setLoading={setLoading}
            apiError={apiError}
          />
        </Box>
        <CustomStepper activeStep={step} />
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '14px',
          justifyContent: 'center',
          objectFit: 'cover',
          display: {
            xs: 'none',
            lg: 'flex',
          },
        }}
      >
        <Swiper
          direction='vertical'
          loop
          spaceBetween={400}
          speed={1500}
          autoplay={{ delay: 3500, disableOnInteraction: false }}
          modules={[Pagination, Navigation, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
              centeredSlides: true,
              slideToClickedSlide: true,
            },
            768: {
              slidesPerView: 2.5,
              centeredSlides: true,
              slideToClickedSlide: true,
            },
          }}
          className='mySwiper'
          style={signupPageStyles.swiper_Style}
        >
          {images.map((value, index) => (
            <SwiperSlide key={index} style={signupPageStyles.swiperSlide_Style}>
              <img
                src={value}
                alt=''
                style={signupPageStyles.swiperSlide_Img}
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default SignupPage
