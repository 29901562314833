export const homePage = {
  main: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    width: '100%',
    height: 'fit-content',
    padding: '24px  var(--Numbers-Number-10, 20px)',
    backgroundColor: theme.palette.customWhite.main,
  }),
}

export const aiSearch = {
  main: {
    boxSizing: 'border-box',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    //  background: 'red',
    width: '100%',
  },

  searchBarMain: {
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    maxWidth: '100%',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    maxWidth: '633px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    border: '2.5px solid transparent',
    backgroundImage:
      'linear-gradient(#fff, #fff), linear-gradient(to right, #AF9BFF, #7215CF)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
  },
  searchIconMain: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    top: 2,
  },
  searchIcon: {
    color: '#000',
    width: '30px',
    height: '30px',
  },
  outlineIcon: {
    position: 'absolute',
    color: '#000',
    top: '-1px',
    right: '0px',
    width: '12px',
    height: '12px',
  },

  searchInput: {
    position: 'end',
    border: '1px solid white',
    boxSizing: 'border-box',
    width: '34px',
    height: '34px',
    borderRadius: '10px',
    background: 'linear-gradient(90deg, #AF9BFF -13.57%, #7215CF 115.08%)',
    justifyContent: 'center',
  },
  sendicon: {
    boxSizing: 'border-box',
    width: '24px',
    height: '24px',
    filter: 'invert(100%)', // Makes it white
  },
  bottom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '15px',
  },
}

export const platformtext = (theme) => ({
  lineHeight: '20px',
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontWeight: theme?.typography?.menuItems?.fontWeight,
  color: theme?.palette?.black?.main,
  fontFamily: theme?.typography?.menuItems?.fontFamily,
  marginLeft: '8px',
})

export const socialPlatforms = {
  plateformChips: {
    display: 'flex',
    height: '32px',
    marginLeft: '8px',
    boxSizing: 'border-box',
    padding: '4px 14px 4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid var(--Secondary, #241BFF)',
    background: 'var(--Light, #F9F9FF)',
    '&:hover': {
      boxSizing: 'border-box',
      paddingRight: '2px',
    },
    '&:hover .close-icon': {
      display: 'flex',
    },
  },
  iconButton: {
    color: '#000',
    display: 'none',
    ':hover': {
      backgroundColor: 'transparent',
    },
  },

  iconImg: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
  plateformName: {
    fontFamily: 'Libre Franklin',
    fontSize: '14px',
    fontWeight: '600',
    color: '#000',
  },

  text: {
    color: '#0C0C0C',
    fontFamily: 'Libre Franklin',
    fontSize: '18px',
    fontWeight: '600',
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'auto',
    maxWidth: '100%',
    gap: '12px',
    background: '',
  },

  selectPlatforms_Text: (theme) => ({
    fontSize: theme?.typography?.h3?.fontSize,
    fontWeight: theme?.typography?.h4?.fontWeight,
    color: theme?.palette?.black?.main,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
    fontStyle: 'normal',
    lineHeight: '27px',
  }),
  form: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  formControl: {
    width: '100%',
    maxWidth: '126px',
    boxSizing: 'border-box',
    maxHeight: '32px',
    borderRadius: 'var(--Numbers-Number-4, 8px)',
    border: '1px solid #DADAFF',
    background: '#F9F9FF',
  },

  selectStyle: {
    boxSizing: 'border-box',
    width: '120.4px',
    padding: '4px ',
    backgroundColor: 'white',
    color: 'var(--Text-Text, #000)',
    fontFamily: 'Libre Franklin',
    borderRadius: 'var(--Numbers-Number-4, 8px)',
    border: '1px solid #DADAFF',
    boxShadow:
      ' 0px 10px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 3px 0px rgba(0, 0, 0, 0.10)',
  },

  plateformChipsMain: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '6px',
    borderRadius: '42px',
  },
}

export const selectPlateform = (theme) => ({
  lineHeight: '20px',
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontWeight: theme?.typography?.menuItems?.fontWeight,
  color: theme?.palette?.black?.main,
  fontFamily: theme?.typography?.menuItems?.fontFamily,
})

export const menuItemsPlatform = (theme) => ({
  width: '100%',
  boxSizing: 'border-box',
  lineHeight: '20px',
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontWeight: theme?.typography?.menuItems?.fontWeight,
  color: theme?.palette?.black?.main,
  fontFamily: theme?.typography?.menuItems?.fontFamily,
  '&:hover': {
    backgroundColor: theme?.palette?.gray?.hover,
    borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
  },
})
export const mainPropsPlatform = (theme) => ({
  width: '100%',
  maxWidth: '126px',
  maxHeight: '32px',
  '&:before': { border: 'none' },
  '&:after': { border: 'none' },
  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
  boxSizing: 'border-box',
  padding: '8px',
  lineHeight: '20px',
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontWeight: theme?.typography?.menuItems?.fontWeight,
  color: theme?.palette?.black?.main,
  fontFamily: theme?.typography?.menuItems?.fontFamily,
})

export const trendingSearch = {
  main: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
  },

  heading: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '27px',
    fontSize: theme?.typography?.h3?.fontSize,
    fontWeight: theme?.typography?.h4?.fontWeight,
    color: theme?.palette?.black?.main,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),

  swiperMian: {
    display: 'flex',
    background: '',
    boxSizing: 'border-box',
    gap: '12px',
    width: '1590px',
    paddingX: '0px',
    justifyContent: 'start',
  },

  slide: {
    display: 'flex',
    justifyContent: 'start',
    width: 'auto',
    backgroundColor: '',
  },
  trendingText: (theme) => ({
    width: 'fit-content',
    maxHeight: '32px',
    boxSizing: 'border-box',
    padding: '6px 14px 6px 10px',
    textTransform: 'capitalize',
    lineHeight: '14px',
    borderRadius: '30px',
    border: '1px solid #7215CF',
    background: '#EBE5FF',
    whiteSpace: 'nowrap',
    WebkitBoxOrient: 'horizontal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': { backgroundColor: '#EBE5FF' },
    fontSize: theme?.typography?.h4?.fontSize,
    fontWeight: theme?.typography?.h4?.fontWeight,
    color: theme?.palette?.black?.main,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),
}

export const trendingPostStyle = {
  divider_Style: {
    boxSizing: 'border-box',
    paddingTop: '18px',
  },
  main: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'flex-start',
    width: '100%',
    gap: '16px var(--Numbers-Number-8, 16px)',
    flexWrap: 'wrap',
    flexGrow: 1,
    paddingTop: '24px',
  },

  postCard: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '26px',
    width: '266px',
    border: '1px solid #DADAFF',
    height: '266px',
    backgroundColor: '#F8F9FF',
  },
  post: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '26px',
    backgroundColor: 'lightgray',
    width: '266px',
    height: '266px',
    position: 'relative',
  },

  postContent: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    borderRadius: '16px',
  },
  socialIcons: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '8px',
    borderRadius: '42px',
    background: 'rgba(255, 255, 255, 0.50)',
    backdropFilter: 'blur(28px)',
    gap: '8px',
  },

  state: {
    width: '50px',
    boxSizing: 'border-box',
    padding: '4px 8px',
    borderRadius: '38px',
    background: 'rgba(255, 255, 255, 0.50)',
    backdropFilter: 'blur(28px)',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  followerData: (theme) => ({
    lineHeight: '20px',
    boxSizing: 'border-box',
    paddingLeft: '2px',
    cursor: 'pointer',
    color: theme?.palette?.border?.extralight,
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),

  menuStyle: {
    display: 'flex',
    marginTop: '40px',
    padding: '8px',
    boxSizing: 'border-box',
    width: '175px',
    borderRadius: '16px',
    border: '1px solid var(--Text-Text-5, #F2F2F2)',
    background: 'rgba(255, 255, 255, 0.4)',
    boxShadow: '0px 10px 40px 10px rgba(0, 0, 0, 0.08)',
    backdropFilter: 'blur(28px)',
  },
  menuItemsImg: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
  menuitems: (theme) => ({
    color: theme?.palette?.customWhite?.hover,
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
    lineHeight: '20px',
  }),
  detail: (theme) => ({
    lineHeight: '18px',
    alignSelf: 'flex-end',
    color: theme?.palette?.border?.main,
    fontSize: theme?.typography?.paragraoh?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),
  description: (theme) => ({
    background:
      'linear-gradient(273deg, #4D91FF -2.79%, #B14BF4 63.06%, #FA5560 83.15%)',
    lineHeight: '20px',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h1?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),
  vertIcon: {
    fill: '#F9F9FF',
    position: 'absolute',
    right: '8px',
    bottom: '28px',
  },
}
