export const instagramStyle = {
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    background: "",
    paddingX: "20px ",
    height: "100%",
    overflow: "hidden",
  },
  instagramHeader: {
    display: { xs: "flex" },
    justifyContent: "space-between",
    alignItems: "center",
    background: "",
    boxSizing: "border-box",
    width: { xs: "100%" },
    paddingY: { xs: "8px", sm: "10px" },
  },

  circularProgressBox: {
    position: "fixed",
    color: "red",
    top: "55%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  postCardsParent: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
    mt: "8px",
    overflowY: "scroll",
    background: "",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  noDataImage: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "100px",
    height: "400px",
    width: "400px",
  },
};

export const instagramContentBox = (IgFound) => ({
  background: "",
  display: "flex",
  flexDirection: "column",
  overflowY: IgFound ? "scroll" : "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  width: "100%",
  // height: '100%',
  gap: "8px",
});

export const instgramHeaderTypography = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.mediumText.fontSize,
  },
  fontWeight: {
    xs: theme.typography.h2.fontWeight,
    sm: theme?.typography.menualText.fontWeight,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: "",
  background: "",
});

export const headerstyle = {
  main: {
    color: " var(--Text-Text, #0C0C0C)",
    fontFamily: "Libre Franklin",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHght: "normal",
    letterSpacing: "-1.2px",
  },
  headerButtons: {
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
  },
  searchbar: {
    flex: 1,
    border: "1px solid #E5E7EB",
    height: "34px",

    borderRadius: "8px",
    background: "var(--Basic-Forms-Input-Background-Bg-Color, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#f5f5f5",
    "& .MuiOutlinedInput-root": {
      boxSizing: "border-box",
      padding: "0px",
      height: "34px",
      borderRadius: "8px",
      background: "var(--Basic-Forms-Input-Background-Bg-Color, #FFF)",
      "& fieldset": {
        border: "none",
      },
      "& input::placeholder": {
        color: "var(--Basic-Forms-Input-Placeholder-Color, #6B7280)",
        fontFamily: "Libre Franklin",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "0.075px",
      },
    },
  },
};

export const searchButton = (theme) => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  boxSizing: "border-box",
  padding: "0px 12px",
  height: "36px",
  background: theme.palette.primary.dark,
  borderRadius:
    "var(--Basic-Forms-Input-Border-Radius, 0px) var(--Numbers-Number, 8px) var(--Number, 8px) var(--Basic-Forms-Input-Border-Radius, 0px)",
  // position: 'relative',
  boxShadow: "none",
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  textTransform: "none",
});

export const instagramPost = {
  main: {
    height: "330px",
    width: "320px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "16px",
    padding: "4px",
    boxSizing: "border-box",
    background: "",
  },
  cardDetail: {
    display: "flex",
    padding: "8px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "12px",
    border: "1px solid rgba(255, 255, 255, 0.50)",
    background: "rgba(255, 255, 255, 0.12)",
    backdropFilter: "blur(16px)",
  },
  detailBox: {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    background: "",
  },

  postCaption: {
    fontSize: "14px",
    fontWeight: 600,
    color: "white",
    lineHeight: "20px",
    fontFamily: "Libre Franklin",
    fontStyle: "normal",
    background: "",
    width: "100%",
    height: "fit-content",
    overflow: "hidden", // Hide overflowed text
    whiteSpace: "nowrap", // Prevent wrapping of text
    textOverflow: "ellipsis", // Show ellipsis when text overflows
  },
  postDetail: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#FFF",
  },
  postTypo: { fontSize: "12px", fontWeight: 400 },
};

export const postModel = {
  instagramModalsParentBox: {
    height: "731px",
    width: "fit-content",
    display: "flex",
    boxSizing: "border-box",
    padding: "0px",
    background: "",
    gap: "30px",
  },

  postForm: {
    boxSizing: "border-box",
    paddingBottom: "20px",
    paddingTop: "20px",
    height: "731px",
    width: "680px",
    background: "white",
    display: "flex",
    flexDirection: "column",
    borderRadius: "24px",
    justifyContent: "",
  },
  modelHeader: {
    display: "flex",
    boxSizing: "border-box",
    paddingX: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "",
    // padding: '16px 20px',
  },
  mediaBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "294px",
    alignItems: "center",
    background: "",
    boxSizing: "border-box",
    padding: "20px",
    gap: "16px",
  },
  uploadBox: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    background: "",
  },
};
export const postModelButton1 = (theme) => ({
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "9px var(--Components-Button-Spacing-X-SM, 12px)",
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme?.palette.primary.dark,
  fontStyle: "normal",
  lineHeight: "20px",
  width: "fit-content",
  textTransform: "capitalize",
  height: "38px",
  color: "white",
  ":hover": {
    background: theme.palette.primary.dark,
  },
});

export const mediaBtn = (theme) => ({
  height: "38px",
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "9px var(--Components-Button-Spacing-X-SM, 12px)",
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme?.palette.primary.dark,
  fontStyle: "normal",
  lineHeight: "20px",
  width: "fit-content",
  textTransform: "capitalize",
  color: "white",
  "&.Mui-disabled": {
    color: "white", // Text color for disabled state
  },
  ":hover": {
    background: theme.palette.primary.dark,
  },
});

export const generateBtn = (theme) => ({
  height: "38px",
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "9px var(--Components-Button-Spacing-X-SM, 12px)",
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: "linear-gradient(90deg, #4950FF 0%, #6268FD 52%)",
  fontStyle: "normal",
  lineHeight: "20px",
  width: "fit-content",
  textTransform: "capitalize",
  color: "white",
  "&.Mui-disabled": {
    color: "white", // Text color for disabled state
  },
  ":hover": {
    background: "linear-gradient(90deg, #4950FF 0%, #6268FD 52%)",
  },
});

export const uploadMoreBtn = (media, theme) => ({
  visibility: media.length === 8 || media.length === 0 ? "hidden" : "visible",
  height: "38px",
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "9px var(--Components-Button-Spacing-X-SM, 12px)",
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme?.palette.primary.dark,
  fontStyle: "normal",
  lineHeight: "20px",
  width: "fit-content",
  textTransform: "capitalize",
  color: "white",
  "&.Mui-disabled": {
    color: "white", // Text color for disabled state
  },
  ":hover": {
    background: theme.palette.primary.dark,
  },
});

export const nextButton = (theme) => ({
  borderRadius: "8px",
  boxSizing: "border-box",
  padding: "9px var(--Components-Button-Spacing-X-SM, 12px)",
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme?.palette.primary.dark,
  fontStyle: "normal",
  lineHeight: "20px",
  width: "fit-content",
  textTransform: "capitalize",
  color: "white",
  ":hover": {
    background: theme.palette.primary.dark,
  },
});
