import { Box, Button } from '@mui/material'
import React from 'react'
import { facebookServiceStyle } from '../facebookServicesStyle'
import { useTheme } from '@emotion/react'

const EditPostedFooter = ({ handleOptionSelect, inputStr, images }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        width: '95%',
        background: '',
        height: '50px',
        alignItems: 'center',
        visibility: inputStr || images.length > 0 ? 'visible' : 'hidden',
      }}
    >
      <Box sx={{ ml: 'auto', display: 'flex', gap: '20px', background: '' }}>
        <Button
          type='submit'
          onClick={() => handleOptionSelect('Edit')}
          variant='contained'
          sx={{
            textTransform: 'capitalize',
            color: '',
            boxSizing: 'border-box',
            padding: '0px',
            height: '35px',
            paddingX: '12px',
            borderRadius: '8px',
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h2.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            background: theme.palette.primary.dark,
            lineHeight: 'normal',
          }}
        >
          Save edit
        </Button>
      </Box>
    </Box>
  )
}

export default EditPostedFooter
