import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'
import { auth } from './firebaseConfig'

const signUpAuth = async (email, password) => {
  console.log(auth, 'auth')
  let user = await createUserWithEmailAndPassword(auth, email, password)
  return user
}

const loginInAuth = async (email, password) => {
  let user = await signInWithEmailAndPassword(auth, email, password)
  return user
}

const forgotPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email)
}

const emailVerification = async (user, getLatestRoute) => {
  const newRoute = getLatestRoute()
  console.log('new route', newRoute)
  return await sendEmailVerification(user, {
    url: `${process.env.REACT_APP_CLIENT_URL}${'/choose-plan'}`,
    handleCodeInApp: true,
  })
}

const handleGoogleLogin = async () => {
  const provider = new GoogleAuthProvider()
  try {
    const result = await signInWithPopup(auth, provider)
    const user = result.user
    return result
  } catch (error) {
    console.error('Error during Google login: ', error)
    return error
  }
}

const handleFacebookLogin = async () => {
  const provider = new FacebookAuthProvider()
  try {
    const result = await signInWithPopup(auth, provider)
    const user = result.user
    console.log('User Info: ', result)
  } catch (error) {
    console.error('Error during Facebook login: ', error)
  }
}

const logOut = async () => {
  return auth.signOut()
}

export {
  signUpAuth,
  loginInAuth,
  forgotPassword,
  emailVerification,
  logOut,
  handleGoogleLogin,
  handleFacebookLogin,
}
// , {
//   url: `${'http://localhost:3000'}/dashboard`,
//   handleCodeInApp: true,
// }
